import $ from 'jquery'
// import TabFocusUtil from './xrx9x-tab-focus-util'

const Switch = (($) => {
  /**
   * ------------------------------------------------------------------------
   * Constants
   * ------------------------------------------------------------------------
   */

  const NAME = 'switch'
  const DATA_KEY = 'xrx.switch'
  const EVENT_KEY = `.${DATA_KEY}`
  const JQUERY_NO_CONFLICT = $.fn[NAME]

  const Event = {
    CLICK: `click${EVENT_KEY}`,
    CHANGE: `change${EVENT_KEY}`,
  }

  const ClassName = {
    CHECKED: 'checked',
    XRX_SWITCH: 'xrx-switch',
    SWITCH_SM: 'switch-sm',
    NOTRANSITION: 'notransition',
  }

  const Selector = {
    XRX_SWITCH: '.xrx-switch',
    LABEL: 'label',
    TYPE_SWITCH: '[type="checkbox"]',
    SVG: 'svg',
    BODY: 'body',
    DEFS_SWITCH: '#defs-switch',
    DEFS_SWITCH_SM: '#defs-switch-sm',
  }

  const InputProp = {
    DISABLED: 'disabled',
    CHECKED: 'checked',
  }

  const switchSvgDefaultDef = `
      <svg id="defs-switch" class="svg-defs">
        <defs>

          <filter x="-23.3%" y="-17.9%" width="146.7%" height="150.0%" filterUnits="objectBoundingBox" id="filter-2">
            <feOffset dx="0" dy="1" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
            <feGaussianBlur stdDeviation="1.5" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.24 0" type="matrix" in="shadowBlurOuter1" result="shadowMatrixOuter1"></feColorMatrix>
            <feOffset dx="0" dy="1" in="SourceAlpha" result="shadowOffsetOuter2"></feOffset>
            <feGaussianBlur stdDeviation="1.5" in="shadowOffsetOuter2" result="shadowBlurOuter2"></feGaussianBlur>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.24 0" type="matrix" in="shadowBlurOuter2" result="shadowMatrixOuter2"></feColorMatrix>
            <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter3"></feOffset>
            <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter3" result="shadowBlurOuter3"></feGaussianBlur>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.24 0" type="matrix" in="shadowBlurOuter3" result="shadowMatrixOuter3"></feColorMatrix>
            <feMerge>
              <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
              <feMergeNode in="shadowMatrixOuter2"></feMergeNode>
              <feMergeNode in="shadowMatrixOuter3"></feMergeNode>
            </feMerge>
          </filter>

          <filter x="-23.3%" y="-17.9%" width="146.7%" height="150.0%" filterUnits="objectBoundingBox" id="filter-4">
            <feOffset dx="0" dy="1" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
            <feGaussianBlur stdDeviation="1.5" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.32 0" type="matrix" in="shadowBlurOuter1" result="shadowMatrixOuter1"></feColorMatrix>
            <feOffset dx="0" dy="1" in="SourceAlpha" result="shadowOffsetOuter2"></feOffset>
            <feGaussianBlur stdDeviation="1.5" in="shadowOffsetOuter2" result="shadowBlurOuter2"></feGaussianBlur>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.32 0" type="matrix" in="shadowBlurOuter2" result="shadowMatrixOuter2"></feColorMatrix>
            <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter3"></feOffset>
            <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter3" result="shadowBlurOuter3"></feGaussianBlur>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.32 0" type="matrix" in="shadowBlurOuter3" result="shadowMatrixOuter3"></feColorMatrix>
            <feMerge>
              <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
              <feMergeNode in="shadowMatrixOuter2"></feMergeNode>
              <feMergeNode in="shadowMatrixOuter3"></feMergeNode>
            </feMerge>
          </filter>

          <filter x="-23.3%" y="-17.9%" width="146.7%" height="150.0%" filterUnits="objectBoundingBox" id="filter-6">
            <feOffset dx="0" dy="1" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
            <feGaussianBlur stdDeviation="1.5" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.16 0" type="matrix" in="shadowBlurOuter1" result="shadowMatrixOuter1"></feColorMatrix>
            <feOffset dx="0" dy="1" in="SourceAlpha" result="shadowOffsetOuter2"></feOffset>
            <feGaussianBlur stdDeviation="1.5" in="shadowOffsetOuter2" result="shadowBlurOuter2"></feGaussianBlur>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.16 0" type="matrix" in="shadowBlurOuter2" result="shadowMatrixOuter2"></feColorMatrix>
            <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter3"></feOffset>
            <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter3" result="shadowBlurOuter3"></feGaussianBlur>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.16 0" type="matrix" in="shadowBlurOuter3" result="shadowMatrixOuter3"></feColorMatrix>
            <feMerge>
              <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
              <feMergeNode in="shadowMatrixOuter2"></feMergeNode>
              <feMergeNode in="shadowMatrixOuter3"></feMergeNode>
            </feMerge>
          </filter>

          <symbol id="def-switch-thumb-shadow-theme">
            <path d="M3,0 L27,0 C28.6568542,0 30,1.34314575 30,3 L30,25 C30,26.6568542 28.6568542,28 27,28 L3,28 C1.34314575,28 0,26.6568542 0,25 L0,3 C0,1.34314575 1.34314575,0 3,0 Z"></path>
          </symbol>

          <symbol id="def-switch-thumb-shadow-dark">
            <path d="M3,0 L27,0 C28.6568542,0 30,1.34314575 30,3 L30,25 C30,26.6568542 28.6568542,28 27,28 L3,28 C1.34314575,28 0,26.6568542 0,25 L0,3 C0,1.34314575 1.34314575,0 3,0 Z"></path>
          </symbol>

          <symbol id="def-switch-thumb-shadow-light">
            <path d="M3,0 L27,0 C28.6568542,0 30,1.34314575 30,3 L30,25 C30,26.6568542 28.6568542,28 27,28 L3,28 C1.34314575,28 0,26.6568542 0,25 L0,3 C0,1.34314575 1.34314575,0 3,0 Z"></path>
          </symbol>

          <symbol id="def-switch-outer-focus">
            <path d="M65,0 C71.0751322,0 76,4.92486775 76,11 L76,33 C76,39.0751322 71.0751322,44 65,44 L11,44 C4.92486775,44 0,39.0751322 0,33 L0,11 C0,4.92486775 4.92486775,0 11,0 L65,0 Z M65,6 L11,6 C8.3112453,6 6.11818189,8.12230671 6.00461951,10.7831104 L6,11 L6,33 C6,35.6887547 8.12230671,37.8818181 10.7831104,37.9953805 L11,38 L65,38 C67.6887547,38 69.8818181,35.8776933 69.9953805,33.2168896 L70,33 L70,11 C70,8.3112453 67.8776933,6.11818189 65.2168896,6.00461951 L65,6 Z"></path>
          </symbol>

          <symbol id="def-switch-inner-focus">
            <path d="M65,3 C69.418278,3 73,6.581722 73,11 L73,33 C73,37.418278 69.418278,41 65,41 L11,41 C6.581722,41 3,37.418278 3,33 L3,11 C3,6.581722 6.581722,3 11,3 L65,3 Z M65,6 L11,6 C8.23857625,6 6,8.23857625 6,11 L6,11 L6,33 C6,35.7614237 8.23857625,38 11,38 L11,38 L65,38 C67.7614237,38 70,35.7614237 70,33 L70,33 L70,11 C70,8.23857625 67.7614237,6 65,6 L65,6 Z"></path>
          </symbol>

          <symbol id="def-switch-channel">
            <rect x="0" y="0" width="64" height="32" rx="5"></rect>
          </symbol>

          <symbol id="def-switch-checkmark">
            <polygon points="3.775 8.58 -2.57571742e-14 4.79 1.065 3.735 3.775 6.445 10.25 -3.55271368e-14 11.305 1.065"></polygon>
          </symbol>

          <symbol id="def-switch-thumb-background">
            <rect x="0" y="0" width="30" height="28" rx="3"></rect>
          </symbol>

          <symbol id="def-switch-thumb-shadow-inset">
            <path d="M27,0 C28.6568542,-3.04359188e-16 30,1.34314575 30,3 L30,4 C30,2.34314575 28.6568542,1 27,1 L27,1 L3,1 C1.34314575,1 -2.02906125e-16,2.34314575 0,4 L0,4 L0,3 C-2.02906125e-16,1.34314575 1.34314575,3.04359188e-16 3,0 L27,0 Z"></path>
          </symbol>

        </defs>
      </svg>`

  const switchSvgDefault = `
      <svg width="80px" height="48px" viewBox="0 0 80 48">
        <g class="svg-switch-focus" transform="translate(2.000000, 2.000000)">
          <use class="svg-switch-outer-focus" xlink:href="#def-switch-outer-focus"></use>
          <use xlink:href="#def-switch-inner-focus"></use>
        </g>
        <use class="svg-switch-channel" transform="translate(8.000000, 8.000000)" xlink:href="#def-switch-channel"></use>
        <use class="svg-switch-checkmark" transform="translate(18.350000, 19.710000)" xlink:href="#def-switch-checkmark"></use>
        <g class="svg-switch-thumb">
          <use class="svg-switch-thumb-shadow-theme" filter="url(#filter-2)" xlink:href="#def-switch-thumb-shadow-theme"></use>
          <use class="svg-switch-thumb-shadow-dark" filter="url(#filter-4)" xlink:href="#def-switch-thumb-shadow-dark"></use>
          <use class="svg-switch-thumb-shadow-light" filter="url(#filter-6)" xlink:href="#def-switch-thumb-shadow-light"></use>
          <use class="svg-switch-thumb-background" xlink:href="#def-switch-thumb-background"></use>
          <use class="svg-switch-thumb-shadow-inset" xlink:href="#def-switch-thumb-shadow-inset"></use>
        </g>
      </svg>`

  const switchSvgSmallDef = `
      <svg id="defs-switch-sm" class="svg-defs">
        <defs>

          <filter x="-26.3%" y="-22.2%" width="152.6%" height="155.6%" filterUnits="objectBoundingBox" id="filter-2-sm">
            <feOffset dx="0" dy="1" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
            <feGaussianBlur stdDeviation="1" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.24 0" type="matrix" in="shadowBlurOuter1" result="shadowMatrixOuter1"></feColorMatrix>
            <feOffset dx="0" dy="1" in="SourceAlpha" result="shadowOffsetOuter2"></feOffset>
            <feGaussianBlur stdDeviation="1" in="shadowOffsetOuter2" result="shadowBlurOuter2"></feGaussianBlur>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.24 0" type="matrix" in="shadowBlurOuter2" result="shadowMatrixOuter2"></feColorMatrix>
            <feOffset dx="0" dy="1" in="SourceAlpha" result="shadowOffsetOuter3"></feOffset>
            <feGaussianBlur stdDeviation="1.5" in="shadowOffsetOuter3" result="shadowBlurOuter3"></feGaussianBlur>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.24 0" type="matrix" in="shadowBlurOuter3" result="shadowMatrixOuter3"></feColorMatrix>
            <feMerge>
              <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
              <feMergeNode in="shadowMatrixOuter2"></feMergeNode>
              <feMergeNode in="shadowMatrixOuter3"></feMergeNode>
            </feMerge>
          </filter>

          <filter x="-26.3%" y="-22.2%" width="152.6%" height="155.6%" filterUnits="objectBoundingBox" id="filter-4-sm">
            <feOffset dx="0" dy="1" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
            <feGaussianBlur stdDeviation="1" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.32 0" type="matrix" in="shadowBlurOuter1" result="shadowMatrixOuter1"></feColorMatrix>
            <feOffset dx="0" dy="1" in="SourceAlpha" result="shadowOffsetOuter2"></feOffset>
            <feGaussianBlur stdDeviation="1" in="shadowOffsetOuter2" result="shadowBlurOuter2"></feGaussianBlur>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.32 0" type="matrix" in="shadowBlurOuter2" result="shadowMatrixOuter2"></feColorMatrix>
            <feOffset dx="0" dy="1" in="SourceAlpha" result="shadowOffsetOuter3"></feOffset>
            <feGaussianBlur stdDeviation="1.5" in="shadowOffsetOuter3" result="shadowBlurOuter3"></feGaussianBlur>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.32 0" type="matrix" in="shadowBlurOuter3" result="shadowMatrixOuter3"></feColorMatrix>
            <feMerge>
              <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
              <feMergeNode in="shadowMatrixOuter2"></feMergeNode>
              <feMergeNode in="shadowMatrixOuter3"></feMergeNode>
            </feMerge>
          </filter>

          <filter x="-26.3%" y="-22.2%" width="152.6%" height="155.6%" filterUnits="objectBoundingBox" id="filter-6-sm">
            <feOffset dx="0" dy="1" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
            <feGaussianBlur stdDeviation="1" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.16 0" type="matrix" in="shadowBlurOuter1" result="shadowMatrixOuter1"></feColorMatrix>
            <feOffset dx="0" dy="1" in="SourceAlpha" result="shadowOffsetOuter2"></feOffset>
            <feGaussianBlur stdDeviation="1" in="shadowOffsetOuter2" result="shadowBlurOuter2"></feGaussianBlur>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.16 0" type="matrix" in="shadowBlurOuter2" result="shadowMatrixOuter2"></feColorMatrix>
            <feOffset dx="0" dy="1" in="SourceAlpha" result="shadowOffsetOuter3"></feOffset>
            <feGaussianBlur stdDeviation="1.5" in="shadowOffsetOuter3" result="shadowBlurOuter3"></feGaussianBlur>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.16 0" type="matrix" in="shadowBlurOuter3" result="shadowMatrixOuter3"></feColorMatrix>
            <feMerge>
              <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
              <feMergeNode in="shadowMatrixOuter2"></feMergeNode>
              <feMergeNode in="shadowMatrixOuter3"></feMergeNode>
            </feMerge>
          </filter>

          <symbol id="def-switch-thumb-shadow-theme-sm">
            <rect x="0" y="0" width="19" height="18" rx="2"></rect>
          </symbol>

          <symbol id="def-switch-thumb-shadow-dark-sm">
            <rect x="0" y="0" width="19" height="18" rx="2"></rect>
          </symbol>

          <symbol id="def-switch-thumb-shadow-light-sm">
            <rect x="0" y="0" width="19" height="18" rx="2"></rect>
          </symbol>

          <symbol id="def-switch-outer-focus-sm">
            <path d="M43,0 C47.9705627,-9.13077564e-16 52,4.02943725 52,9 L52,23 C52,27.9705627 47.9705627,32 43,32 L9,32 C4.02943725,32 6.08718376e-16,27.9705627 0,23 L0,9 C-6.08718376e-16,4.02943725 4.02943725,9.13077564e-16 9,0 L43,0 Z M43,6 L9,6 C7.34314575,6 6,7.34314575 6,9 L6,9 L6,23 C6,24.6568542 7.34314575,26 9,26 L9,26 L43,26 C44.6568542,26 46,24.6568542 46,23 L46,23 L46,9 C46,7.34314575 44.6568542,6 43,6 L43,6 Z"></path>
          </symbol>

          <symbol id="def-switch-inner-focus-sm">
            <path d="M43,3 C46.3137085,3 49,5.6862915 49,9 L49,23 C49,26.3137085 46.3137085,29 43,29 L9,29 C5.6862915,29 3,26.3137085 3,23 L3,9 C3,5.6862915 5.6862915,3 9,3 L43,3 Z M43,6 L9,6 C7.34314575,6 6,7.34314575 6,9 L6,9 L6,23 C6,24.6568542 7.34314575,26 9,26 L9,26 L43,26 C44.6568542,26 46,24.6568542 46,23 L46,23 L46,9 C46,7.34314575 44.6568542,6 43,6 L43,6 Z"></path>
          </symbol>

          <symbol id="def-switch-channel-sm">
            <rect x="0" y="0" width="40" height="20" rx="3"></rect>
          </symbol>

          <symbol id="def-switch-checkmark-sm">
            <polygon points="2.51666667 5.72 -1.687539e-14 3.19333333 0.71 2.49 2.51666667 4.29666667 6.83333333 1.37667655e-14 7.53666667 0.71"></polygon>
          </symbol>

          <symbol id="def-switch-thumb-background-sm">
            <rect x="0" y="0" width="19" height="18" rx="2"></rect>
          </symbol>

          <symbol id="def-switch-thumb-shadow-inset-sm">
            <path d="M0,2 C0,0.8954305 0.8954305,0 2,0 L17,0 C18.1045695,0 19,0.8954305 19,2 L19,3 C19,1.9456382 18.1841222,1.08183488 17.1492623,1.00548574 L17,1 L2,1 C0.945638205,1 0.0818348781,1.81587779 0.00548573643,2.85073766 L0,3 L0,2 Z"></path>
          </symbol>

        </defs>
      </svg>`

  const switchSvgSmall = `
      <svg width="52px" height="32px" viewBox="0 0 52 32">
      <g class="svg-switch-focus">
        <use class="svg-switch-outer-focus" xlink:href="#def-switch-outer-focus-sm"></use>
        <use xlink:href="#def-switch-inner-focus-sm"></use>
      </g>
      <use class="svg-switch-channel" transform="translate(6.000000, 6.000000)" xlink:href="#def-switch-channel-sm"></use>
      <use class="svg-switch-checkmark" transform="translate(12.233333, 13.140000)" xlink:href="#def-switch-checkmark-sm"></use>
      <g class="svg-switch-thumb">
        <use class="svg-switch-thumb-shadow-theme" filter="url(#filter-2-sm)" xlink:href="#def-switch-thumb-shadow-theme-sm"></use>
        <use class="svg-switch-thumb-shadow-dark" filter="url(#filter-4-sm)" xlink:href="#def-switch-thumb-shadow-dark-sm"></use>
        <use class="svg-switch-thumb-shadow-light" filter="url(#filter-6-sm)" xlink:href="#def-switch-thumb-shadow-light-sm"></use>
        <use class="svg-switch-thumb-background" xlink:href="#def-switch-thumb-background-sm"></use>
        <use class="svg-switch-thumb-shadow-inset" xlink:href="#def-switch-thumb-shadow-inset-sm"></use>
      </g>
    </svg>`

  /**
   * ------------------------------------------------------------------------
   * Class Definition
   * ------------------------------------------------------------------------
   */

  class Switch {
    constructor(element, config) {
      this._element = element
      this._switch = $(element).find(Selector.TYPE_SWITCH)[0]
      this._createSwitch()
      this._svg = $(element).find(Selector.SVG)[0]

      this._addEventListeners()
      this._initState()
    }

    // Public

    setChecked() {
      this.setEnabled()
      $(this._switch).prop(InputProp.CHECKED, true)
      this._setViewChecked()
    }

    setCheckedChange() {
      this.setChecked()
      $(this._switch).change()
    }

    setUnchecked() {
      this.setEnabled()
      $(this._switch).prop(InputProp.CHECKED, false)
      this._setViewUnchecked()
    }

    setUncheckedChange() {
      this.setUnchecked()
      $(this._switch).change()
    }

    setDisabled() {
      $(this._switch).prop(InputProp.DISABLED, true)
    }

    setEnabled() {
      $(this._switch).prop(InputProp.DISABLED, false)
    }

    getDisabled() {
      return $(this._switch).prop(InputProp.DISABLED)
    }

    getChecked() {
      return $(this._switch).prop(InputProp.CHECKED)
    }

    dispose() {
      $.removeData(this._element, DATA_KEY)
      $(this._element).off(EVENT_KEY)
      $(this._switch).off(EVENT_KEY)
      $(this._element).find(Selector.LABEL).off(EVENT_KEY)
      $(this._svg).remove()
      this._element = null
      this._switch = null
      this._svg = null
    }

    // Private

    _createSwitch() {
      const $xrxSwitch = $(this._element)
      if ($xrxSwitch.hasClass(ClassName.SWITCH_SM)) {
        if ($(Selector.DEFS_SWITCH_SM).length === 0) {
          $(Selector.BODY).prepend(switchSvgSmallDef)
        }
        $xrxSwitch.append(switchSvgSmall)
      } else {
        if ($(Selector.DEFS_SWITCH).length === 0) {
          $(Selector.BODY).prepend(switchSvgDefaultDef)
        }
        $xrxSwitch.append(switchSvgDefault)
      }
    }

    _initState() {
      if (this.getChecked() === true) {
        this._setViewChecked()
      }
    }

    _addEventListeners() {
      const that = this

      $(that._element).on(Event.CLICK, function () {
        $(this).find(Selector.TYPE_SWITCH).click().focus()
      })

      $(that._element).find(Selector.LABEL).on(Event.CLICK, function (e) {
        e.stopPropagation()
      })

      $(that._switch).on(Event.CLICK, function (e) {
          e.stopPropagation()
        })
        .on(Event.CHANGE, function (e) {
          const $this = $(this);
          const $xrxSwitch = $this.closest(Selector.XRX_SWITCH);
          if (that.getDisabled() !== true) {
            if (that.getChecked() === true) {
              $xrxSwitch.addClass(ClassName.CHECKED)
            } else {
              $xrxSwitch.removeClass(ClassName.CHECKED)
            }
          }
        })
    }

    _setViewUnchecked() {
      $(this._element).removeClass(ClassName.CHECKED)
    }

    _setViewChecked() {
      $(this._element).addClass(ClassName.CHECKED)
    }

    _disableTransitions() {
      // enable/disable transitions
      var that = this;
      setTimeout(function () {
        $(that._element).removeClass(ClassName.NOTRANSITION)
      }, 160)
      $(this._element).addClass(ClassName.NOTRANSITION)
    }

    // Static

    static _jQueryInterface(config, animate) {
      // methods with return values
      if (typeof config === 'string' && config.substring(0, 3) === 'get') {
        let data = $(this).data(DATA_KEY)
        if (data) {
          return data[config]()
        }
      }
      return this.each(function () {
        let data = $(this).data(DATA_KEY)
        const _config = typeof config === 'object' ? config : null

        if (!data) {
          data = new Switch(this, _config)
          $(this).data(DATA_KEY, data)
        }

        if (typeof config === 'string') {
          if (typeof data[config] === 'undefined') {
            throw new TypeError(`No method named "${config}"`)
          }
          if (!animate) {
            data['_disableTransitions']()
          }
          data[config]()
        }
      })
    }

  }

  /**
   * ------------------------------------------------------------------------
   * jQuery
   * ------------------------------------------------------------------------
   */

  $.fn[NAME] = Switch._jQueryInterface
  $.fn[NAME].Constructor = Switch
  $.fn[NAME].noConflict = function () {
    $.fn[NAME] = JQUERY_NO_CONFLICT
    return Switch._jQueryInterface
  }

  return Switch
})($)

export default Switch
