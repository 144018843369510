import $ from 'jquery'
import { createPopper } from '@popperjs/core';
import XrxPxToRem from './xrx-px-to-rem';
import Util from './xrx-util'
// import TabFocusUtil from './xrx9x-tab-focus-util'

/**
 * --------------------------------------------------------------------------
 * Bootstrap (v4.1.1): dropdown.js
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 * --------------------------------------------------------------------------
 */

const Dropdown = (($) => {
  /**
   * ------------------------------------------------------------------------
   * Constants
   * ------------------------------------------------------------------------
   */

  const NAME = 'dropdown'
  const VERSION = '4.1.1'
  const DATA_KEY = 'xrx.dropdown'
  const EVENT_KEY = `.${DATA_KEY}`
  const DATA_API_KEY = '.data-api'
  const JQUERY_NO_CONFLICT = $.fn[NAME]
  const ESCAPE_KEYCODE = 27 // KeyboardEvent.which value for Escape (Esc) key
  const SPACE_KEYCODE = 32 // KeyboardEvent.which value for space key
  const TAB_KEYCODE = 9 // KeyboardEvent.which value for tab key
  const ARROW_UP_KEYCODE = 38 // KeyboardEvent.which value for up arrow key
  const ARROW_DOWN_KEYCODE = 40 // KeyboardEvent.which value for down arrow key
  const RIGHT_MOUSE_BUTTON_WHICH = 3 // MouseEvent.which value for the right button (assuming a right-handed mouse)
  const REGEXP_KEYDOWN = new RegExp(`${ARROW_UP_KEYCODE}|${ARROW_DOWN_KEYCODE}|${ESCAPE_KEYCODE}`)

  const Event = {
    HIDE: `hide${EVENT_KEY}`,
    HIDDEN: `hidden${EVENT_KEY}`,
    SHOW: `show${EVENT_KEY}`,
    SHOWN: `shown${EVENT_KEY}`,
    CLICK: `click${EVENT_KEY}`,
    CLICK_DATA_API: `click${EVENT_KEY}${DATA_API_KEY}`,
    KEYDOWN_DATA_API: `keydown${EVENT_KEY}${DATA_API_KEY}`,
    KEYUP_DATA_API: `keyup${EVENT_KEY}${DATA_API_KEY}`
  }

  const ClassName = {
    DISABLED: 'disabled',
    SHOW: 'show',
    DROPUP: 'dropup',
    DROPRIGHT: 'dropright',
    DROPLEFT: 'dropleft',
    MENUTOP: 'dropdown-menu-top',
    MENUBOTTOM: 'dropdown-menu-bottom',
    MENURIGHT: 'dropdown-menu-right',
    MENULEFT: 'dropdown-menu-left',
    POSITION_STATIC: 'position-static',
    CARET_UP: 'xgl-sort_up',
    CARET_DOWN: 'xgl-sort_down',
    LISTGROUP_SM: 'list-group-sm',
    ACTIVE: 'active',

  }

  const Selector = {
    DATA_TOGGLE: '[data-toggle="dropdown"]',
    FORM_CHILD: '.xrx-dropdown form',
    MENU: '.dropdown-menu',
    NAVBAR_NAV: '.navbar-nav',
    VISIBLE_ITEMS: '.dropdown-menu .dropdown-item:not(.disabled):not(:disabled)',
    DROPDOWN_ITEMS: '.dropdown-item',
    CARET: '.caret',
    LEFTOBJ_GLYPH_24: '.left-object [class*="xgl-"]', // listgroup
    LEFTOBJ_CUSTOM_ICON: '.left-object .custom-icon', // listgroup
    LEFTLABEL: '.left-label', // listgroup
    RIGHTOBJ: '.right-object', // listgroup
    LISTGROUP: '.xrx-list-group', // listgroup
    LISTGROUP_ITEM: '.list-group-item', // listgroup
    MULTI_LISTGROUP: '.multi-list-group', // listgroup
    MENU_SCROLL_CONTAINER: '.menu-scroll-container', // listgroup
    ACTIVE_ITEM: '.active' // listgroup
  }

  const AttachmentMap = {
    AUTO: 'auto',
    AUTOSTART: 'auto-start',
    AUTOEND: 'auto-end',
    TOP: 'top',
    TOPSTART: 'top-start',
    TOPEND: 'top-end',
    BOTTOM: 'bottom',
    BOTTOMSTART: 'bottom-start',
    BOTTOMEND: 'bottom-end',
    RIGHT: 'right',
    RIGHTSTART: 'right-start',
    RIGHTEND: 'right-end',
    LEFT: 'left',
    LEFTSTART: 'left-start',
    LEFTEND: 'left-end'
  }

  const Default = {
    boundary: 'clippingParents',
    reference: 'toggle',
    display: 'dynamic',
    popperConfig: null
  }

  const DefaultType = {
    // offset: '(number|string|function)',
    // flip: 'boolean',
    boundary: '(string|element)',
    reference: '(string|element)',
    display: 'string',
    popperConfig: '(null|object)'
  }

  // 9x listgroup item padding right
  function ptr(pxVal) {
    const pxBase = 16;
    return XrxPxToRem.remIt(XrxPxToRem.calRem(pxVal, pxBase))
  }
  const Padding = {
    LEFTOBJ_GLYPH_24: ptr(24),
    LEFTOBJ_CUSTOM_ICON: ptr(16),
    LEFTLABEL: ptr(16),
  }

  /**
   * ------------------------------------------------------------------------
   * Class Definition
   * ------------------------------------------------------------------------
   */

  class Dropdown {
    constructor(element, config) {
      this._element = element
      this._popper = null
      this._config = this._getConfig(config)
      this._menu = this._getMenuElement()
      this._inNavbar = this._detectNavbar()

      this._setMenuScroll()

      this._setArrow()

      // this._setListGroupItemPadding()

      // this._setListGroupBorderRadius()

      this._setDisabledItem()

      this._addEventListeners()

      this._initPopper()

      this._modalDropdownFix() // update popper on modal open
    }

    // Getters

    static get VERSION() {
      return VERSION
    }

    static get Default() {
      return Default
    }

    static get DefaultType() {
      return DefaultType
    }

    // Public

    toggle() {
      if (this._element.disabled || $(this._element).hasClass(ClassName.DISABLED)) {
        return
      }

      const parent = Dropdown._getParentFromElement(this._element)
      const isActive = $(this._menu).hasClass(ClassName.SHOW)

      Dropdown._clearMenus()

      if (isActive) {
        return
      }

      const relatedTarget = {
        relatedTarget: this._element
      }
      const showEvent = $.Event(Event.SHOW, relatedTarget)

      $(parent).trigger(showEvent)

      if (showEvent.isDefaultPrevented()) {
        return
      }

      if (this._popper === null) {
        this._initPopper()
      }

      // If this is a touch-enabled device we add extra
      // empty mouseover listeners to the body's immediate children;
      // only needed because of broken event delegation on iOS
      // https://www.quirksmode.org/blog/archives/2014/02/mouse_event_bub.html
      if ('ontouchstart' in document.documentElement &&
        $(parent).closest(Selector.NAVBAR_NAV).length === 0) {
        $(document.body).children().on('mouseover', null, $.noop)
      }

      this._element.focus()
      this._element.setAttribute('aria-expanded', true)

      $(this._menu).toggleClass(ClassName.SHOW)
      $(parent)
        .find(Selector.CARET)
        .removeClass(ClassName.CARET_DOWN)
        .addClass(ClassName.CARET_UP)
      $(parent)
        .toggleClass(ClassName.SHOW)
        .trigger($.Event(Event.SHOWN, relatedTarget))
    }

    dispose() {
      $.removeData(this._element, DATA_KEY)
      $(this._element).closest('.xrx-modal').off('shown.xrx.modal.modal-dropdownfix')
      $(this._element).off(EVENT_KEY)
      this._element = null
      this._menu = null
      this._killPopperInstance()
    }

    update() {
      this._inNavbar = this._detectNavbar()
      if (this._popper !== null) {
        this._popper.forceUpdate()
      }
    }

    updateMenuScroll() {
      this._setMenuScroll()
    }

    // Private

    _modalDropdownFix() {
      const that = this
      const $modalAncestor = $(this._element).closest('.xrx-modal')
      if ($modalAncestor.length > 0) {
        $modalAncestor.on('shown.xrx.modal.modal-dropdownfix', function (e) {
          that.update()
        })
      }
    }

    _initPopper() {
      this._killPopperInstance()
      // Disable totally Popper.js for Dropdown in Navbar
      if (!this._inNavbar) {
        /**
         * Check for Popper dependency
         * Popper - https://popper.js.org
         */
        if (typeof Popper === 'undefined') {
          throw new TypeError('Bootstrap dropdown require Popper.js (https://popper.js.org)')
        }

        let referenceElement = this._element

        if (this._config.reference === 'parent') {
          referenceElement = parent
        } else if (Util.isElement(this._config.reference)) {
          referenceElement = this._config.reference

          // Check if it's jQuery element
          if (typeof this._config.reference.jquery !== 'undefined') {
            referenceElement = this._config.reference[0]
          }
        }

        // If boundary is not `scrollParent`, then set position to `static`
        // to allow the menu to "escape" the scroll parent's boundaries
        // https://github.com/twbs/bootstrap/issues/24251
        if (this._config.boundary !== 'scrollParent') {
          $(parent).addClass(ClassName.POSITION_STATIC)
        }

        if (this._config.display !== 'static') {
          this._popper = Popper.createPopper(referenceElement, this._menu, this._getPopperConfig()) // poper v2
        }

      }
    }

    _killPopperInstance() {
      if (this._popper !== null) {
        this._popper.destroy()
        this._popper = null
      }
    }

    _setMenuScroll() {
      const $menu = $(this._menu)
      let itemsToScroll = 4
      if ($menu.data('scroll')) {
        itemsToScroll = $menu.data('scroll')
      } else {
        if ($menu.closest('.xrx-modal').length > 0) {
          itemsToScroll = 3
        }
      }

      const $listGroupItem = $menu.find(Selector.LISTGROUP_ITEM)
      const $scrollItem = $listGroupItem.eq(itemsToScroll - 1)

      // insert/wrap list groups with scroll container
      const $multiListGroup = $menu.find(Selector.MULTI_LISTGROUP)
      const $listGroup = $menu.find(Selector.LISTGROUP)
      const scrollWrapper = '<div class="menu-scroll-container"></div>'

      if ($menu.find(Selector.MENU_SCROLL_CONTAINER).length === 0) {
        if ($multiListGroup.length > 0) {
          $multiListGroup.wrap(scrollWrapper)
        } else {
          $listGroup.wrap(scrollWrapper)
        }
      }

      // reset
      let peekabooHeight = null
      let menuScrollHeight = null
      $menu.find(Selector.MENU_SCROLL_CONTAINER).css({ 'height': 'auto' })
      $menu.css({ 'height': 'auto' })




  if ($listGroupItem.length <= itemsToScroll) {
    $menu.find(Selector.MENU_SCROLL_CONTAINER).css({ 'overflow': 'hidden' })
    return
  }

      // set the height of the scroll container and menu container
      peekabooHeight = $scrollItem.css('height').slice(0, -2) / 2
      menuScrollHeight = ($scrollItem.position().top + peekabooHeight) + 'px'
      $menu.find(Selector.MENU_SCROLL_CONTAINER).css({ 'height': menuScrollHeight, 'overflow-y': 'scroll' })
      $menu.css({ 'height': menuScrollHeight })

      function scrollItem() {

        const scrollEl = $menu.find(Selector.MENU_SCROLL_CONTAINER)[0]
        const $selectedItem = $menu.find(Selector.ACTIVE_ITEM)

        setTimeout(function () {
          if ($selectedItem.length > 0) {
            scrollEl.scrollTop = $selectedItem.position().top + (scrollEl.scrollTop - peekabooHeight)
          }
          // if .active is the first .list-group-item, scroll it the top
          // This must be done if there is a header in the list group
          if ($menu.find(Selector.LISTGROUP_ITEM).eq(0).hasClass(ClassName.ACTIVE) ||
            $selectedItem.length === 0) {
            scrollEl.scrollTop = 0
          }
        }, 300)

      }

      // scroll list to top on plugin init
      scrollItem()

      // scroll list to top on menu close
      const parent = Dropdown._getParentFromElement(this._element)
      $(parent).off('.scroll').on(`${Event.HIDDEN}.scroll`, function () {
        scrollItem()
      })

    }

    _setArrow() {
      $(this._menu).prepend('<div class="dropdown-menu-arrow" data-popper-arrow></div>')
    }

    _setListGroupItemPadding() {
      $(this._menu).find(Selector.DROPDOWN_ITEMS).each(function () {
        var $this = $(this);
        if ($this.find(Selector.LEFTOBJ_GLYPH_24).length > 0) {
          // set padding right
          $this.css('padding-right', Padding.LEFTOBJ_GLYPH_24)
        } else if ($this.find(Selector.LEFTOBJ_CUSTOM_ICON).length > 0) {
          // set padding right
          $this.css('padding-right', Padding.LEFTOBJ_CUSTOM_ICON)
        } else if ($this.find(Selector.LEFTLABEL).length > 0) {
          // set padding right
          $this.css('padding-right', Padding.LEFTLABEL)
        }
      })

    }

    _setListGroupBorderRadius() {
      const $menu = $(this._menu)
      const $thisListGroup = $menu.find(Selector.LISTGROUP)
      const lgLength = $thisListGroup.length

      if (lgLength === 0) {
        return
      }
      const isSmallListGroup = $thisListGroup.hasClass(ClassName.LISTGROUP_SM)
      const smRadius = '4px'
      const defaultRadius = '6px'
      const borderRadiusSm = { 'border-radius': smRadius }
      const borderRadiusDefault = { 'border-radius': defaultRadius }
      const borderRadiusSmTop = { 'border-top-left-radius': smRadius, 'border-top-right-radius': smRadius }
      const borderRadiusDefaultTop = { 'border-top-left-radius': defaultRadius, 'border-top-right-radius': defaultRadius }
      const borderRadiusSmBottom = { 'border-bottom-left-radius': smRadius, 'border-bottom-right-radius': smRadius }
      const borderRadiusDefaultBottom = { 'border-bottom-left-radius': defaultRadius, 'border-bottom-right-radius': defaultRadius }

      if (lgLength === 1) {
        if (isSmallListGroup) {
          $thisListGroup.css(borderRadiusSm)
        } else {
          $thisListGroup.css(borderRadiusDefault)
        }
      } else if (lgLength === 2) {
        if (isSmallListGroup) {
          $thisListGroup.eq(0).css(borderRadiusSmTop)
          $thisListGroup.eq(1).css(borderRadiusSmBottom)
        } else {
          $thisListGroup.eq(0).css(borderRadiusDefaultTop)
          $thisListGroup.eq(1).css(borderRadiusDefaultBottom)
        }
      } else {
        if (isSmallListGroup) {
          $thisListGroup.eq(0).css(borderRadiusSmTop)
          $thisListGroup.eq(lgLength - 1).css(borderRadiusSmBottom)
        } else {
          $thisListGroup.eq(0).css(borderRadiusDefaultTop)
          $thisListGroup.eq(lgLength - 1).css(borderRadiusDefaultBottom)
        }
      }

      // set dropdown menu border radius
      if (isSmallListGroup) {
        // override the default set in css
        $menu.css(borderRadiusSm)
      }

    }

    _setDisabledItem() {
      $(this._menu).find(Selector.DROPDOWN_ITEMS).each(function () {
        var $this = $(this);
        if ($this.hasClass('disabled')) {
          $this.removeAttr('href')
        }
      })
    }

    _addEventListeners() {
      $(this._element).on(Event.CLICK, (event) => {
        event.preventDefault()
        event.stopPropagation()
        this.toggle()
      })
    }

    _getConfig(config) {
      config = {
        ...this.constructor.Default,
        ...$(this._element).data(),
        ...config
      }

      Util.typeCheckConfig(
        NAME,
        config,
        this.constructor.DefaultType
      )

      return config
    }

    _getMenuElement() {
      if (!this._menu) {
        const parent = Dropdown._getParentFromElement(this._element)
        this._menu = $(parent).find(Selector.MENU)[0]
      }
      return this._menu
    }

    _getPlacement() {
      const $parentDropdown = $(this._element).parent()
      let placement = {
        position: AttachmentMap.BOTTOM,
        spaceBetween: 4
      }

      // Handle dropup
      if ($parentDropdown.hasClass(ClassName.DROPUP)) {
        placement.position = AttachmentMap.TOP
        placement.spaceBetween = 5

        if ($(this._menu).hasClass(ClassName.MENURIGHT)) {
          placement.position = AttachmentMap.TOPEND
        }

        if ($(this._menu).hasClass(ClassName.MENULEFT)) {
          placement.position = AttachmentMap.TOPSTART
        }

      } else if ($parentDropdown.hasClass(ClassName.DROPRIGHT)) {
        placement.position = AttachmentMap.RIGHT
        placement.spaceBetween = 5

        if ($(this._menu).hasClass(ClassName.MENUTOP)) {
          placement.position = AttachmentMap.RIGHTSTART
        }

        if ($(this._menu).hasClass(ClassName.MENUBOTTOM)) {
          placement.position = AttachmentMap.RIGHTEND
        }

      } else if ($parentDropdown.hasClass(ClassName.DROPLEFT)) {
        placement.position = AttachmentMap.LEFT
        placement.spaceBetween = 5

        if ($(this._menu).hasClass(ClassName.MENUTOP)) {
          placement.position = AttachmentMap.LEFTSTART
        }

        if ($(this._menu).hasClass(ClassName.MENUBOTTOM)) {
          placement.position = AttachmentMap.LEFTEND
        }

      } else if ($(this._menu).hasClass(ClassName.MENURIGHT)) {
        placement.position = AttachmentMap.BOTTOMEND

      } else if ($(this._menu).hasClass(ClassName.MENULEFT)) {
        placement.position = AttachmentMap.BOTTOMSTART
      }
      return placement
    }

    _detectNavbar() {
      return $(this._element).closest('.navbar').length > 0
    }

    _getPopperConfig() {
      const $parentDropdown = $(this._element).parent()

      const placement = this._getPlacement()

      const popperConfig = {
        placement: placement.position,
        modifiers: [{
          name: 'offset',
          options: {
            offset: [0, placement.spaceBetween],
          },
        }, {
          name: 'preventOverflow',
          options: {
            boundary: this._config.boundary,
            padding: 8,
          },
        }, {
          name: 'arrow',
          options: {
            padding: 8, // 5px from the edges of the popper
          },
        }, { // add/remove .dropup class to .xrx-dropdown element when popper updates
          name: 'topLogger',
          enabled: true,
          phase: 'main',
          fn({ state }) {
            if (state.placement === 'top') {
              $parentDropdown.addClass(ClassName.DROPUP)
            } else {
              $parentDropdown.removeClass(ClassName.DROPUP)
            }
          }
        }],
      }

      // Disable Popper.js if we have a static display
      // if (this._config.display === 'static') {
      //   popperConfig.modifiers.applyStyle = {
      //     enabled: false
      //   }
      // }

      return {
        ...popperConfig,
        ...this._config.popperConfig
      }
    }

    // Static

    static _jQueryInterface(config) {
      return this.each(function () {
        let data = $(this).data(DATA_KEY)
        const _config = typeof config === 'object' ? config : null

        if (!data) {
          data = new Dropdown(this, _config)
          $(this).data(DATA_KEY, data)
        }

        if (typeof config === 'string') {
          if (typeof data[config] === 'undefined') {
            throw new TypeError(`No method named "${config}"`)
          }
          data[config]()
        }
      })
    }

    static _clearMenus(event) {
      if (event && (event.which === RIGHT_MOUSE_BUTTON_WHICH ||
          event.type === 'keyup' && event.which !== TAB_KEYCODE)) {
        return
      }

      const toggles = $.makeArray($(Selector.DATA_TOGGLE))
      for (let i = 0; i < toggles.length; i++) {
        const parent = Dropdown._getParentFromElement(toggles[i])
        const context = $(toggles[i]).data(DATA_KEY)
        const relatedTarget = {
          relatedTarget: toggles[i]
        }

        if (!context) {
          continue
        }

        const dropdownMenu = context._menu
        if (!$(parent).hasClass(ClassName.SHOW)) {
          continue
        }

        if (event && (event.type === 'click' &&
            /input|textarea/i.test(event.target.tagName) || event.type === 'keyup' && event.which === TAB_KEYCODE) &&
          $.contains(parent, event.target)) {
          continue
        }

        const hideEvent = $.Event(Event.HIDE, relatedTarget)
        $(parent).trigger(hideEvent)
        if (hideEvent.isDefaultPrevented()) {
          continue
        }

        // If this is a touch-enabled device we remove the extra
        // empty mouseover listeners we added for iOS support
        if ('ontouchstart' in document.documentElement) {
          $(document.body).children().off('mouseover', null, $.noop)
        }

        toggles[i].setAttribute('aria-expanded', 'false')

        $(dropdownMenu).removeClass(ClassName.SHOW)
        $(parent)
          .find(Selector.CARET)
          .removeClass(ClassName.CARET_UP)
          .addClass(ClassName.CARET_DOWN)
        $(parent)
          .removeClass(ClassName.SHOW)
          .trigger($.Event(Event.HIDDEN, relatedTarget))
      }
    }

    static _getParentFromElement(element) {
      let parent
      const selector = Util.getSelectorFromElement(element)

      if (selector) {
        parent = $(selector)[0]
      }

      return parent || element.parentNode
    }

    // eslint-disable-next-line complexity
    static _dataApiKeydownHandler(event) {
      // If not input/textarea:
      //  - And not a key in REGEXP_KEYDOWN => not a dropdown command
      // If input/textarea:
      //  - If space key => not a dropdown command
      //  - If key is other than escape
      //    - If key is not up or down => not a dropdown command
      //    - If trigger inside the menu => not a dropdown command
      if (/input|textarea/i.test(event.target.tagName) ?
        event.which === SPACE_KEYCODE || event.which !== ESCAPE_KEYCODE &&
        (event.which !== ARROW_DOWN_KEYCODE && event.which !== ARROW_UP_KEYCODE ||
          $(event.target).closest(Selector.MENU).length) : !REGEXP_KEYDOWN.test(event.which)) {
        return
      }

      event.preventDefault()
      event.stopPropagation()

      if (this.disabled || $(this).hasClass(ClassName.DISABLED)) {
        return
      }

      const parent = Dropdown._getParentFromElement(this)
      const isActive = $(parent).hasClass(ClassName.SHOW)

      if (!isActive && (event.which !== ESCAPE_KEYCODE || event.which !== SPACE_KEYCODE) ||
        isActive && (event.which === ESCAPE_KEYCODE || event.which === SPACE_KEYCODE)) {
        if (event.which === ESCAPE_KEYCODE) {
          const toggle = $(parent).find(Selector.DATA_TOGGLE)[0]
          $(toggle).trigger('focus')
        }

        $(this).trigger('click')
        return
      }

      const items = $(parent).find(Selector.VISIBLE_ITEMS).get()

      if (items.length === 0) {
        return
      }

      // allow tab focus style while using arrow keys
      document.body.classList.remove('using-mouse');

      let index = items.indexOf(event.target)

      if (event.which === ARROW_UP_KEYCODE && index > 0) { // Up
        index--
      }

      if (event.which === ARROW_DOWN_KEYCODE && index < items.length - 1) { // Down
        index++
      }

      if (index < 0) {
        index = 0
      }

      items[index].focus()
    }
  }

  /**
   * ------------------------------------------------------------------------
   * Data Api implementation
   * ------------------------------------------------------------------------
   */

  $(document)
    .on(Event.KEYDOWN_DATA_API, Selector.DATA_TOGGLE, Dropdown._dataApiKeydownHandler)
    .on(Event.KEYDOWN_DATA_API, Selector.MENU, Dropdown._dataApiKeydownHandler)
    .on(`${Event.CLICK_DATA_API} ${Event.KEYUP_DATA_API}`, Dropdown._clearMenus)
    .on(Event.CLICK_DATA_API, Selector.DATA_TOGGLE, function (event) {
      event.preventDefault()
      event.stopPropagation()
      Dropdown._jQueryInterface.call($(this), 'toggle')
    })
    .on(Event.CLICK_DATA_API, Selector.FORM_CHILD, (e) => {
      e.stopPropagation()
    })

  /**
   * ------------------------------------------------------------------------
   * jQuery
   * ------------------------------------------------------------------------
   */

  $.fn[NAME] = Dropdown._jQueryInterface
  $.fn[NAME].Constructor = Dropdown
  $.fn[NAME].noConflict = function () {
    $.fn[NAME] = JQUERY_NO_CONFLICT
    return Dropdown._jQueryInterface
  }

  return Dropdown
})($, Popper)

export default Dropdown
