import $ from 'jquery'
import Util from './xrx-util'

/**
 * --------------------------------------------------------------------------
 * Bootstrap (v4.1.1)
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 * --------------------------------------------------------------------------
 */

const Input = (($) => {
  /**
   * ------------------------------------------------------------------------
   * Constants
   * ------------------------------------------------------------------------
   */

  const NAME = 'input'
  // const VERSION = '4.1.1'
  const DATA_KEY = 'xrx.input'
  const EVENT_KEY = `.${DATA_KEY}`
  const DATA_API_KEY = '.data-api'
  const JQUERY_NO_CONFLICT = $.fn[NAME]

  const Event = {
    FOCUS: `focus${EVENT_KEY}`,
    BLUR: `blur${EVENT_KEY}`,
    CLICK: `click${EVENT_KEY}`,
    MOUSEENTER: `mouseenter${EVENT_KEY}`,
    MOUSELEAVE: `mouseleave${EVENT_KEY}`,
    MOUSEDOWN: `mousedown${EVENT_KEY}`,
    MOUSEUP: `mouseup${EVENT_KEY}`
  }

  const ClassName = {
    INPUT_FOCUS: 'input-focus',
    INPUT_FOCUS_FIRST_CLICK: 'input-focus-first-click',
    INPUT_HOVER: 'input-hover',
    INPUT_ACTIVE: 'input-active',
    VALIDATION_CLASSES: 'input-validation-success input-validation-danger input-validation-warning form-helper-text',
    INPUT_VALIDATION_SUCCESS: 'input-validation-success',
    INPUT_VALIDATION_DANGER: 'input-validation-danger',
    INPUT_VALIDATION_WARNING: 'input-validation-warning'
  }

  const Selector = {
    XRX_INPUT: '.xrx-input',
    INPUT: '.xrx-input input',
    XRX_STACKED_ICON: '.xrx-stacked-icon',
    INPUT_GROUP: '.input-group',
    INPUT_GROUP_APPEND: '.input-group-append',
    INPUT_GROUP_DIVIDER: '.input-group-divider'
  }

  const Str = {
    FOCUS_ON: 'focus-on',
    FOCUS_OFF: 'focus-off',
    HOVER_ON: 'hover-on',
    HOVER_OFF: 'hover-off',
    ACTIVE_ON: 'active-on',
    SUCCESS: 'success',
    DANGER: 'danger',
    WARNING: 'warning'
  }

  const Html = {

    helperText: `
      <div class="form-text">
        <div class="message"></div>
      </div>`,

    successIcon16: `
      <div class="xrx-stacked-icon stacked-icon-size-16">
        <i class="xgl-success_stack_mdgd xglsize-16"></i>
        <i class="xgl-success_stack_frgd xglsize-16"></i>
      </div>`,

    dangerIcon16: `
      <div class="xrx-stacked-icon stacked-icon-size-16">
        <i class="xgl-danger_stack_mdgd xglsize-16"></i>
        <i class="xgl-danger_stack_frgd xglsize-16"></i>
      </div>`,

    warningIcon16: `
      <div class="xrx-stacked-icon stacked-icon-size-16">
        <i class="xgl-warning_stack_mdgd xglsize-16"></i>
        <i class="xgl-warning_stack_frgd xglsize-16"></i>
      </div>`,

    successIcon24: `
      <div class="xrx-stacked-icon stacked-icon-size-24">
        <i class="xgl-success_stack_mdgd xglsize-24"></i>
        <i class="xgl-success_stack_frgd xglsize-24"></i>
      </div>`,

    dangerIcon24: `
      <div class="xrx-stacked-icon stacked-icon-size-24">
        <i class="xgl-danger_stack_mdgd xglsize-24"></i>
        <i class="xgl-danger_stack_frgd xglsize-24"></i>
      </div>`,

    warningIcon24: `
      <div class="xrx-stacked-icon stacked-icon-size-24">
        <i class="xgl-warning_stack_mdgd xglsize-24"></i>
        <i class="xgl-warning_stack_frgd xglsize-24"></i>
      </div>`,

    inputGroupAppend: `
      <div class="input-group-append">
      </div>`,

    inputGroupDivider: `
      <div class="input-group-divider">
      </div>`

  }

  // Global Events
  $(document).on('mouseup.xrx-input-document-event', function () {
    $('.input-active').removeClass(ClassName.INPUT_ACTIVE)
  })

  /**
   * ------------------------------------------------------------------------
   * Class Definition
   * ------------------------------------------------------------------------
   */

  class Input {
    constructor(element, config) {
      // this._config = this._getConfig(config)
      this._$element = $(element)
      this._$htmlInput = this._$element.find('input')
      this._init()
    }

    // Getters

    // static get VERSION() {
    //   return VERSION
    // }

    // static get Default() {
    //   return Default
    // }

    // Public

    dispose() {
      const that = this
      $.removeData(that._$element, DATA_KEY)
      $(that._$htmlInput).off(EVENT_KEY)
      that._$element = null
      that._$htmlInput = null
    }

    setHelperValidationSuccess(message) {
      const that = this
      that.setValidationClear()

      // if message is an object, then do multiline validation messages
      that._insertValidation(Str.SUCCESS)
      if (Array.isArray(message)) {
        that._insertHelperMultilineValidation(message)
      } else {
        that._insertValidationMessage(message, Str.SUCCESS)
      }
    }

    setHelperValidationDanger(message) {
      const that = this
      that.setValidationClear()

      // if message is an object, then do multiline validation messages
      that._insertValidation(Str.DANGER)
      if (Array.isArray(message)) {
        that._insertHelperMultilineValidation(message)
      } else {
        that._insertValidationMessage(message, Str.DANGER)
      }
    }

    setHelperValidationWarning(message) {
      const that = this
      that.setValidationClear()

      // if message is an object, then do multiline validation messages
      that._insertValidation(Str.WARNING)
      if (Array.isArray(message)) {
        that._insertHelperMultilineValidation(message)
      } else {
        that._insertValidationMessage(message, Str.WARNING)
      }
    }

    setInlineValidationSuccess(message) {
      const that = this
      that.setValidationClear()
      that._insertInputGroupDividerHtml()
      that._$element
        .addClass(ClassName.INPUT_VALIDATION_SUCCESS)
        .find(Selector.INPUT_GROUP_APPEND)
        .append(Html.successIcon24)
      if (message) {
        that._insertValidation(Str.SUCCESS)
        that._insertValidationMessage(message)
      }
    }

    setInlineValidationDanger(message) {
      const that = this
      that.setValidationClear()
      that._insertInputGroupDividerHtml()
      that._$element
        .addClass(ClassName.INPUT_VALIDATION_DANGER)
        .find(Selector.INPUT_GROUP_APPEND)
        .append(Html.dangerIcon24)
      if (message) {
        that._insertValidation(Str.DANGER)
        that._insertValidationMessage(message)
      }
    }

    setInlineValidationWarning(message) {
      const that = this
      that.setValidationClear()
      that._insertInputGroupDividerHtml()
      that._$element
        .addClass(ClassName.INPUT_VALIDATION_WARNING)
        .find(Selector.INPUT_GROUP_APPEND)
        .append(Html.warningIcon24)
      if (message) {
        that._insertValidation(Str.WARNING)
        that._insertValidationMessage(message)
      }
    }

    setValidationClear() {
      const that = this
      that._$element
        .removeClass(ClassName.VALIDATION_CLASSES)

        // remove helper text validation
        .next('.form-text').remove()

      // remove inline validation
      that._$element
        .find(Selector.XRX_STACKED_ICON)
        .remove()
        .end()
        .find(Selector.INPUT_GROUP_DIVIDER)
        .remove()
    }

    setDisabled() {
      const that = this
      that._$htmlInput.prop('disabled', true)
      that._$element.addClass('input-disabled')

      // Disable the input label
      const id = that._$htmlInput.attr('id')
      $(`[for="${id}"]`).addClass('label-disabled')
      that._$element.next('.form-text').addClass('form-text-disabled')
    }

    setReadonly() {
      const that = this
      that._$htmlInput.prop('readonly', true)
      that._$element.addClass('input-readonly')
    }

    setEnabled() { // remove disabled and readonly attributes
      const that = this
      that._$htmlInput.prop('disabled', false)
      that._$htmlInput.prop('readonly', false)
      that._$element
        .removeClass('input-disabled')
        .removeClass('input-readonly')

      // enable the input label
      const id = that._$htmlInput.attr('id')
      $(`[for="${id}"]`).removeClass('label-disabled')
      that._$element.next('.form-text').removeClass('form-text-disabled')
    }

    setHelperText(message) {
      const that = this
      that.setHelperTextClear()
      that._$element
        .addClass('form-helper-text')
        .after(Html.helperText)

      const $formText = that._$element.next('.form-text')

      // maybe we need getDisabled method?
      if ($(this._$htmlInput).prop('disabled')) {
        $formText.addClass('form-text-disabled')
      }

      $formText.find('.message').text(message)
    }

    setHelperTextClear() {
      this._$element.next('.form-text').remove()
    }

    // Private

    _init() {
      const that = this

      if (that._$htmlInput.attr('disabled') ||
        that._$element.hasClass('input-disabled')) {
        that.setDisabled()
      }

      if (that._$htmlInput.attr('readonly') ||
        that._$element.hasClass('input-readonly')) {
        that.setReadonly()
      }

      that._insertInputGroupAppendHtml()

      that._addEventListeners()
    }

    _insertInputGroupAppendHtml() {
      const that = this
      if (that._$element.find(Selector.INPUT_GROUP_APPEND).length === 0) {
        that._$element.find(Selector.INPUT_GROUP).append(Html.inputGroupAppend)
      }
    }

    _insertInputGroupDividerHtml() {
      const that = this
      const $inputGroupAppend = that._$element.find(Selector.INPUT_GROUP_APPEND)
      if ($inputGroupAppend.children().length > 0) {
        $inputGroupAppend.append(Html.inputGroupDivider)
      }
    }

    _addEventListeners() {
      const that = this

      that._$htmlInput
        .on(Event.FOCUS, function () {
          that._focus(Str.FOCUS_ON)
        })
        .on(Event.BLUR, function () {
          that._focus(Str.FOCUS_OFF)
        })

      that._$htmlInput
        .on(Event.MOUSEENTER, function () {
          that._hover(Str.HOVER_ON)
        })
        .on(Event.MOUSELEAVE, function () {
          that._hover(Str.HOVER_OFF)
        })

      that._$htmlInput.on(Event.MOUSEDOWN, function () {
        that._active()
      })

    }

    _focus(param) {
      const that = this
      if (param === Str.FOCUS_ON) {
        if ($('body').hasClass('using-mouse') === false) {
          that._$element.addClass(ClassName.INPUT_FOCUS)
        }
      } else {
        that._$element.removeClass(ClassName.INPUT_FOCUS)
        that._$element.removeClass(ClassName.INPUT_FOCUS_FIRST_CLICK)
      }
    }

    _hover(param) {
      const that = this
      if (param === Str.HOVER_ON) {
        that._$element.addClass(ClassName.INPUT_HOVER)
      } else {
        that._$element.removeClass(ClassName.INPUT_HOVER)
      }
    }

    _active() {
      const that = this
      that._$element.addClass(ClassName.INPUT_ACTIVE)
      if (!that._$element.hasClass(ClassName.INPUT_FOCUS_FIRST_CLICK)) {
        that._$element.addClass(ClassName.INPUT_FOCUS_FIRST_CLICK)
      } else {
        that._$element.addClass(ClassName.INPUT_FOCUS)
      }
    }

    _insertHelperMultilineValidation(messageArray) {
      const that = this
      let html = ''

      const $formText = that._$element.next('.form-text')
      $formText.addClass('multiline')

      messageArray.forEach(function (obj) {

        html += '<div class="message-container">'

        if (obj.type === Str.SUCCESS) {
          html += `${Html.successIcon16}<div class="success-message message">${obj.message}</div>`
        } else if (obj.type === Str.DANGER) {
          html += `${Html.dangerIcon16}<div class="danger-message message">${obj.message}</div>`
        } else if (obj.type === Str.WARNING) {
          html += `${Html.warningIcon16}<div class="warning-message message">${obj.message}</div>`
        }

        html += '</div>'
      })

      $formText.append(html)

    }

    _insertValidation(type) {
      const that = this
      if (type === Str.SUCCESS) {
        that._$element.addClass(ClassName.INPUT_VALIDATION_SUCCESS)
      } else if (type === Str.DANGER) {
        that._$element.addClass(ClassName.INPUT_VALIDATION_DANGER)
      } else if (type === Str.WARNING) {
        that._$element.addClass(ClassName.INPUT_VALIDATION_WARNING)
      }
      that._$element
        .addClass('form-helper-text')
        .after(Html.helperText)

      // maybe we need getDisabled method?
      if ($(this._$htmlInput).prop('disabled')) {
        that._$element.next('.form-text').addClass('form-text-disabled')
      }
    }

    _insertValidationMessage(message, type) {
      const that = this
      const $formText = that._$element.next('.form-text')

      if (type === Str.SUCCESS) {
        $formText.prepend(Html.successIcon16)
      } else if (type === Str.DANGER) {
        $formText.prepend(Html.dangerIcon16)
      } else if (type === Str.WARNING) {
        $formText.prepend(Html.warningIcon16)
      }
      $formText.find('.message').text(message)
    }

    _getConfig(config) {
      config = {
        // ...Default,
        ...config
      }
      // Util.typeCheckConfig(NAME, config, DefaultType)
      return config
    }

    // Static

    static _jQueryInterface(config, param) {
      return this.each(function () {
        let data = $(this).data(DATA_KEY)
        const _config = {
          // ...Default,
          ...$(this).data(),
          ...typeof config === 'object' && config ? config : {}
        }

        if (!data) {
          data = new Input(this, _config)
          $(this).data(DATA_KEY, data)
        }

        if (typeof config === 'string') {
          if (typeof data[config] === 'undefined') {
            throw new TypeError(`No method named "${config}"`)
          }
        }

        if (typeof param === 'string' || Array.isArray(param)) {
          data[config](param)
        } else if (typeof config === 'string') {
          data[config]()
        }

        // if (typeof config === 'string') {
        //   if (typeof data[config] === 'undefined') {
        //     throw new TypeError(`No method named "${config}"`)
        //   }
        //   data[config]()
        // }

      })
    }
  }

  /**
   * ------------------------------------------------------------------------
   * Data Api implementation
   * ------------------------------------------------------------------------
   */

  // $(document).on(Event.MOUSEENTER, Selector.INPUT, function (event) {

  //   const $target = $(this).closest(Selector.XRX_INPUT)

  //   if (!$(this).data(DATA_KEY)) {
  //     $target.addClass(ClassName.INPUT_HOVER)
  //   }

  //   Input._jQueryInterface.call($target)

  // })

  // $(document).on(`${Event.CLICK} ${Event.FOCUS}`, Selector.INPUT, function (event) {

  //   const $target = $(this).closest(Selector.XRX_INPUT)

  //   if (!$(this).data(DATA_KEY)) {
  //     $target.addClass(ClassName.INPUT_FOCUS)
  //   }

  //   // Input._jQueryInterface.call($(target), config, this)
  //   Input._jQueryInterface.call($target)
  // })

  // on document load
  $('.xrx-input').each(function () {
    Input._jQueryInterface.call($(this))
  })

  /**
   * ------------------------------------------------------------------------
   * jQuery
   * ------------------------------------------------------------------------
   */

  $.fn[NAME] = Input._jQueryInterface
  $.fn[NAME].Constructor = Input
  $.fn[NAME].noConflict = function () {
    $.fn[NAME] = JQUERY_NO_CONFLICT
    return Input._jQueryInterface
  }

  return Input
})($)

export default Input
