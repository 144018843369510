import $ from 'jquery'
// import XrxAlertBox from './xrx-alert-box'
// import XrxButton from './xrx-button'
// import XrxPopover from './xrx-popover'
// import XrxTooltip from './xrx-tooltip'
import Xrx9xTooltip from './xrx9x-tooltip'
import Xrx9xTab from './xrx9x-tab'
import XrxUtil from './xrx-util'
import MatchMedia from './matchMedia'
import cssua from './cssua'
import JqueryBaThrottleDebounce from './jquery.ba-throttle-debounce'
import JqueryMenuAim from './jquery.menu-aim'
// import XrxNavbar from './xrx-navbar'
import Xrx9xNavbar from './xrx9x-navbar'
// import XrxFooter from './xrx-footer'
import XrxOnFontResize from './xrx-on-font-resize'
import XrxPxToRem from './xrx-px-to-rem'
import XrxDetectMobile from './xrx-detect-mobile'
// import XrxActivityIndicatorButton from './xrx-activity-indicator-button'
// import XrxAlertPopup from './xrx-alert-popup'
// import XrxCarousel from './xrx-carousel'
import Xrx9xDropdown from './xrx9x-dropdown'
// import XrxDropdown from './xrx-dropdown'
import XrxModal from './xrx9x-modal'
// import XrxCheckbox from './xrx-checkbox'
import Xrx9xCheckbox from './xrx9x-checkbox'
// import XrxRadio from './xrx-radio'
import Xrx9xRadio from './xrx9x-radio'
// import JqueryUICore from './jquery.ui-1.10.3.core'
// import JqueryUIWidget from './jquery.ui-1.10.3.widget'
// import JqueryUIDatepicker from './jquery.ui-1.10.3.datepicker'
// import JqueryUISpinner from './jquery.ui-1.10.3.spinner'
// import JqueryUIButton from './jquery.ui-1.10.3.button'
// import JqueryUIMouse from './jquery.ui-1.10.3.mouse'
// import JqueryUISlider from './jquery.ui-1.10.3.slider'
// import JqueryUITouchPunch from './jquery.ui.touch-punch'
// import XrxFileinput from './xrx-fileinput'
// import XrxSelect from './xrx-select'
import Xrx9xSelect from './xrx9x-select'
import Xrx9xInput from './xrx9x-input'
import Xrx9xTextarea from './xrx9x-textarea'
// import XrxSwitch from './xrx-switch'
import Xrx9xSwitch from './xrx9x-switch'
import Xrx9xAlphaIndex from './xrx9x-alpha-index.js'
// import XrxSpinner from './xrx-spinner'
// import XrxBootstrapProgressBar from './xrx-bootstrap-progressbar'
// import XrxLevelIndicator from './xrx-level-indicator'
// import XrxPagination from './xrx-pagination'
// import XrxSegmentedControl from './xrx-segmented-control'
// import XrxListGroup from './xrx-list-group-checkbox'
// import XrxTableSort from './xrx-table-sort'
// import XrxStepIndicator from './xrx-step-indicator'
import Xrx9xTabFocusUtil from './xrx9x-tab-focus-util'
import Xrx9xDelayVisibilityUtil from './xrx9x-delay-visibility-util'
import xrx9xScrollAnimateUtil from './xrx9x-scroll-animate-util'

// DEFAULT BOOTSTRAP
import Alert from './alert'
import Button from './button'
import Carousel from './carousel'
import Collapse from './collapse'
// import Dropdown from './dropdown'
// import Modal from './modal'
// import Popover from './popover'
import Scrollspy from './scrollspy'
// import Tab from './tab'
// import Tooltip from './tooltip'
// import Util from './util'

/**
 * --------------------------------------------------------------------------
 * Bootstrap (v4.1.1): index.js
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 * --------------------------------------------------------------------------
 */

(($) => {
  if (typeof $ === 'undefined') {
    throw new TypeError('Bootstrap\'s JavaScript requires jQuery. jQuery must be included before Bootstrap\'s JavaScript.')
  }

  const version = $.fn.jquery.split(' ')[0].split('.')
  const minMajor = 1
  const ltMajor = 2
  const minMinor = 9
  const minPatch = 1
  const maxMajor = 4

  if (version[0] < ltMajor && version[1] < minMinor || version[0] === minMajor && version[1] === minMinor && version[2] < minPatch || version[0] >= maxMajor) {
    throw new Error('Bootstrap\'s JavaScript requires at least jQuery v1.9.1 but less than v4.0.0')
  }
})($)

export {
  XrxUtil,
  // XrxAlertBox,
  // XrxButton,
  // XrxPopover,
  // XrxTooltip,
  MatchMedia,
  cssua,
  JqueryBaThrottleDebounce,
  Xrx9xTabFocusUtil,
  Xrx9xDelayVisibilityUtil,
  xrx9xScrollAnimateUtil,
  JqueryMenuAim,
  XrxOnFontResize,
  // XrxNavbar,
  Xrx9xNavbar,
  XrxPxToRem,
  XrxDetectMobile,
  Xrx9xTooltip,
  Xrx9xTab,
  // XrxActivityIndicatorButton,
  // XrxAlertPopup,
  // XrxCarousel,
  // XrxDropdown,
  Xrx9xDropdown,
  // XrxCheckbox,
  Xrx9xCheckbox,
  XrxModal,
  // XrxRadio,
  Xrx9xRadio,
  // JqueryUICore,
  // JqueryUIWidget,
  // JqueryUIDatepicker,
  // JqueryUISpinner,
  // JqueryUIButton,
  // JqueryUIMouse,
  // JqueryUISlider,
  // JqueryUITouchPunch,
  // XrxFileinput,
  // XrxSelect,
  Xrx9xSelect,
  Xrx9xInput,
  Xrx9xTextarea,
  // XrxSwitch,
  Xrx9xSwitch,
  Xrx9xAlphaIndex,
  // XrxSpinner,
  // XrxBootstrapProgressBar,
  // XrxLevelIndicator,
  // XrxPagination,
  // XrxSegmentedControl,
  // XrxListGroup,
  // XrxTableSort,
  // XrxStepIndicator,

  // DEFAULT BOOTSTRAP
  // Util,
  Alert,
  Button,
  Carousel,
  Collapse,
  // Dropdown,
  // Modal,
  // Popover,
  Scrollspy,
  // Tab,
  // Tooltip
}
