/**
* ------------------------------------------------------------------------
* Exmaple usage:
* $(document).on('fontresize', function(e, fsize) {
*   console.log(fsize);
* });
* ------------------------------------------------------------------------
*/

import $ from 'jquery'

const XrxOnFontResize = (($) => {
  $('body').append('<span id="ftrs" style="position: fixed; left: -2000px; transition: font-size .01s;">&nbsp;</span>')

  $('#ftrs').on('transitionend', () => {
    $(document).trigger('fontresize', [$('html').css('font-size')])
  })

  return XrxOnFontResize
})($)

export default XrxOnFontResize
