import $ from 'jquery'
// import XrxDropdown from './xrx-dropdown'
// import Collapse from './collapse'
// import XrxOnFontResize from './xrx-on-font-resize.js'
import XrxPxToRem from './xrx-px-to-rem.js'
// import JqueryBaThrottleDebounce from './jquery.ba-throttle-debounce.js'
// import JqueryMenuAim from './jquery.menu-aim'
// import MatchMedia from './matchMedia.js'
import Util from './xrx-util'
import ScrollAnimateUtil from './xrx9x-scroll-animate-util'

const XrxNavbar = (($) => {

  const MD_BREAKPOINT = 768;
  // const XL_BREAKPOINT = 1200;
  const DEBOUNCE_MS = 300;
  const TRANSITION_END_MS = 300;
  const $window = $(window);
  const $body = $('body');

  const ClassName = {
    INVISIBLE: 'invisible',
    NAVIGATE_UP: 'xgl-sort_up',
    NAVIGATE_DOWN: 'xgl-sort_down',
    MAINTAIN_HOVER: 'maintain-hover',
    D_NONE: 'd-none',
    OPEN_LEFT: 'dn-open-left',
    OPEN_RIGHT: 'dn-open-right',
    NAVBAR_FIXED: 'nav-opt-fixed',
    USING_BRAND_HEADER: 'nav-opt-brand-header',
    USING_LEFT_NAVBAR: 'nav-opt-left-navbar',
    USING_NAV_MINIMIZE: 'nav-opt-minimize',
    USING_NAV_SCROLL_MOBILE: 'nav-opt-scroll-mobile',
    USING_NAV_SCROLL_DESKTOP: 'nav-opt-scroll-desktop',
    USING_NAV_SCROLL_DESKTOP_BRAND: 'nav-opt-scroll-desktop-brand',
    USING_NAV_SINGLE_PAGE_APP: 'nav-opt-single-page-app',
    SHOW: 'show',
    HIDE: 'hide',
    FADE: 'fade',
    FIXED: 'fixed',
    DN_INDICATOR: 'dn-indicator',
    MN_INDICATOR: 'mn-indicator',
    MN_INDICATOR_SHOW: 'mn-indicator-show',
    SELECTED: 'selected',
    TRANSITION_OFF: 'transition-off',
    COLLAPSED: 'collapsed',
    COLLAPSING: 'collapsing',
    EXPANDING: 'expanding',
    XGL_ALIGN_LEFT: 'xgl-align_left',
    XGL_ALIGN_RIGHT: 'xgl-align_right',
    DN_SLIDING_INDICATOR: 'dn-sliding-indicator',
    MN_GLOBAL_SLIDING_INDICATOR: 'mn-global-sliding-indicator',
    MN_LEVEL2_SLIDING_INDICATOR: 'mn-level2-sliding-indicator',
    MN_LEVEL3_SLIDING_INDICATOR: 'mn-level3-sliding-indicator',
    XRX_FOOTER: 'xrx-footer',
    DS_GO: 'nav-opt-ds-go',
    NAV_COLLAPSE_BTN: 'nav-collapse-btn'
  }

  const Selector = {
    DN_NAVBAR_CONTAINER: '.dn-navbar-container',
    DN_NAVBAR_NAV: '.dn-navbar-nav',
    XRX_BRAND_HEADER: '.xrx-brand-header',
    DN_HEADER: '.dn-header',
    DN_MENU: '.dn-menu',
    DN_MENU_CONTAINER: '.dn-menu-container',
    DN_DROPDOWN: '.dn-dropdown',
    DN_DROPDOWN_BTN_CARET: '.dn-dropdown-btn-caret',
    DN_LEVEL1: '.dn-level1',
    DN_LEVEL2: '.dn-level2',
    DN_LEVEL3: '.dn-level3',
    DN_LEVEL1_ITEM_A: '.dn-level1-item > a',
    DN_LEVEL1_ITEM: '.dn-level1-item',
    DN_LEVEL2_ITEM: '.dn-level2-item',
    DN_LEVEL3_ITEM: '.dn-level3-item',
    MAINTAIN_HOVER: '[aria-haspopup].maintain-hover',
    DN_DROPDOWN_TOGGLE: '.dn-dropdown-toggle',
    MN_HEADER: '.mn-header',
    MN_MENU: '.mn-menu',
    MN_CLOSE_BTN: '.mn-close-btn',
    MN_HEADER_CLOSE_BTN: '.mn-header-close-btn',
    MN_OPEN_BTN: '.mn-open-btn',
    DN_OPEN_BTN: '.dn-open-btn',
    MN_BLANKET: '.mn-backdrop',
    MN_CONTAINER: '.mn-container',
    MN_NAVBAR_CONTAINER: '.mn-navbar-container',
    MINIMIZE_SHOW: '.minimize.show',
    NAV_DRAWER_MINIMIZE_ICON: '.nav-drawer-minimize-icon',
    NAV_DRAWER_MINIMIZE_INNER: '.nav-drawer-minimize-inner',
    MN_COLLAPSE_TARGET_CARET: '.mn-collapse-target-caret',
    MN_MENU_ITEM: '.mn-menu-item',
    MN_MENU_ITEM_LABEL: '.mn-menu-item-label',
    MN_LEVEL1: '.mn-level1',
    MN_LEVEL1_ITEM: '.mn-level1-item',
    MN_LEVEL2: '.mn-level2',
    MN_LEVEL2_ITEM: '.mn-level2-item',
    MN_LEVEL3: '.mn-level3',
    DATA_TOGGLE_COLLAPSE: '[data-toggle=collapse]',
    USING_LEFT_NAVBAR: '.nav-opt-left-navbar',
    OPEN: '.open',
    DN_INDICATOR: `.${ClassName.DN_INDICATOR}`,
    MN_INDICATOR: `.${ClassName.MN_INDICATOR}`,
    SELECTED: `.${ClassName.SELECTED}`,
    DN_SLIDING_INDICATOR: `.${ClassName.DN_SLIDING_INDICATOR}`,
    MN_GLOBAL_SLIDING_INDICATOR: `.${ClassName.MN_GLOBAL_SLIDING_INDICATOR}`,
    MN_LEVEL2_SLIDING_INDICATOR: `.${ClassName.MN_LEVEL2_SLIDING_INDICATOR}`,
    MN_LEVEL3_SLIDING_INDICATOR: `.${ClassName.MN_LEVEL3_SLIDING_INDICATOR}`,
    DN_LEVEL1_ITEM_GLYPH: '.dn-level1-item-glyph',
    MN_COLLAPSE: '.mn-collapse',
    XRX_FOOTER: `.${ClassName.XRX_FOOTER}`,
    NAV_COLLAPSE_BTN: `.${ClassName.NAV_COLLAPSE_BTN}`
  }

  // desktop elements
  const $dnMenu = $(Selector.DN_MENU)
  const $dn_dropdown = $(Selector.DN_DROPDOWN);

  // mobile elements
  const $mnMenu = $(Selector.MN_LEVEL1)
  const $close_elements = $(`${Selector.MN_CLOSE_BTN}, ${Selector.MN_BLANKET}`);
  const $open_elements = $(`${Selector.MN_OPEN_BTN}, ${Selector.DN_OPEN_BTN}`);
  const $mn_open_btn = $(Selector.MN_OPEN_BTN);
  const $dn_open_btn = $(Selector.DN_OPEN_BTN);
  const $mn_blanket = $(Selector.MN_BLANKET);
  const $mn_navbar_container = $(Selector.MN_NAVBAR_CONTAINER);

  // footer elements
  const $xrx_footer = $(Selector.XRX_FOOTER);

  // navbar options
  const usingLeftNavbar = $body.hasClass(ClassName.USING_LEFT_NAVBAR)
  const usingNavSinglePageApp = $body.hasClass(ClassName.USING_NAV_SINGLE_PAGE_APP)
  const usingNavScrollMobile = $body.hasClass(ClassName.USING_NAV_SCROLL_MOBILE)
  const usingNavScrollDesktop = $body.hasClass(ClassName.USING_NAV_SCROLL_DESKTOP)
  const usingNavScrollDesktopBrand = $body.hasClass(ClassName.USING_NAV_SCROLL_DESKTOP_BRAND)
  const usingNavMinimize = $body.hasClass(ClassName.USING_NAV_MINIMIZE)
  const usingDsGo = $body.hasClass(ClassName.DS_GO)

  /* ==============================================================================================
   ** SECTION Desktop Navbar
  ============================================================================================== */

  // ** caret up/down on dropdown toggle and collapse toggle
  $dn_dropdown
    .on('show.xrx.dropdown', function () {
      var $caret = $(this).find(Selector.DN_DROPDOWN_BTN_CARET);
      $caret
        .removeClass(ClassName.NAVIGATE_DOWN)
        .addClass(ClassName.NAVIGATE_UP);
    })
    .on('hide.xrx.dropdown', function () {
      var $caret = $(this).find(Selector.DN_DROPDOWN_BTN_CARET);
      $caret
        .removeClass(ClassName.NAVIGATE_UP)
        .addClass(ClassName.NAVIGATE_DOWN);
    });

  // ** don't allow focus on level2 items that open to level3 menu
  $('.dn-level2-item > a[aria-haspopup]').on('mousedown', function (e) {
    e.preventDefault()
  })

  // ** Blur level1 links on mouseleave
  // $(Selector.DN_LEVEL1_ITEM_A).on('mouseleave', function() {
  //   $(this).blur();
  // });

  // BEGIN menu aim
  var calRem = XrxPxToRem.calRem;
  var remIt = XrxPxToRem.remIt;
  var PX_BASE = 16;
  var DROPDOWN_WIDTH_PX_VAL = 256; // set dropdown width
  var DROPDOWN_WIDTH_REM_VAL = calRem(DROPDOWN_WIDTH_PX_VAL, PX_BASE);
  var DROPDOWN_WIDTH_CSS = remIt(DROPDOWN_WIDTH_REM_VAL);

  $(`${Selector.DN_LEVEL2_ITEM}, ${Selector.DN_LEVEL3}`).css('width', DROPDOWN_WIDTH_CSS);

  // ** return true if dropdown extends beyond the right side of the viewport
  // param: element (jQuery object) ... the dropdown
  // param: offset (number) ... the width of the dropdown-menu
  function is_dropdown_gt_window_width($el, offset, window_width) {
    return (Math.floor($el.offset().left + offset) > window_width);
  }

  // ** START menu aim plugin functions
  function activate_left(row) {
    var $this = $(row);
    var menu_item_height = $this.outerHeight();
    $this
      .find('a[aria-haspopup]')
      // .focus()
      .addClass(ClassName.MAINTAIN_HOVER)
      .attr('aria-expanded', 'true')
      .end()
      .find(Selector.DN_LEVEL3)
      .removeClass(ClassName.D_NONE)
      .end()
      .closest(Selector.DN_LEVEL2)
      .css('padding-left', DROPDOWN_WIDTH_CSS);

    // reset level2 height
    $this.closest(Selector.DN_LEVEL2).css('height', 'auto');

    // if row has no submenu
    if ($this.find(Selector.DN_LEVEL3).length > 0) {
      $this.closest(Selector.DN_LEVEL2).css('width', remIt(calRem(DROPDOWN_WIDTH_PX_VAL * 2, PX_BASE)));
    } else {
      $this.closest(Selector.DN_LEVEL2).css({ 'width': DROPDOWN_WIDTH_CSS, 'padding-left': '0' });
      return;
    }

    // if level3 list > level2 list adjust level2 height
    var
      level2_li_length = $this.closest(Selector.DN_LEVEL2).children('li').length,
      level3_li_length = $this.find(Selector.DN_LEVEL3_ITEM).length;

    if (level3_li_length > level2_li_length) { // level2
      $this.closest(Selector.DN_LEVEL2).css('height', level3_li_length * menu_item_height);
    }
  }

  function activate_right(row) {
    var $this = $(row);
    var menu_item_height = $this.outerHeight();
    $this
      .find('a[aria-haspopup]')
      .addClass(ClassName.MAINTAIN_HOVER)
      .attr('aria-expanded', 'true')
      .end()
      .find(Selector.DN_LEVEL3)
      .removeClass(ClassName.D_NONE);

    // reset level2 height
    $this.closest(Selector.DN_LEVEL2).css('height', 'auto');

    // if row has no submenu
    if ($this.find(Selector.DN_LEVEL3).length > 0) {
      $this.closest(Selector.DN_LEVEL2).css('width', remIt(calRem(DROPDOWN_WIDTH_PX_VAL * 2, PX_BASE)))
    } else {
      $this.closest(Selector.DN_LEVEL2).css('width', DROPDOWN_WIDTH_CSS)
      return;
    }

    // if level3 list > level2 list adjust level2 height
    var
      level2_li_length = $this.closest(Selector.DN_LEVEL2).children('li').length,
      level3_li_length = $this.find(Selector.DN_LEVEL3_ITEM).length;

    if (level3_li_length > level2_li_length) {
      $this.closest(Selector.DN_LEVEL2).css('height', level3_li_length * menu_item_height);
    }
  }

  function deactivate(row) {
    var $this = $(row);
    $this
      .closest(Selector.DN_LEVEL2)
      .find('a')
      .removeClass(ClassName.MAINTAIN_HOVER)
      .attr('aria-expanded', 'false')
      .end()
      .find(Selector.DN_LEVEL3)
      .addClass(ClassName.D_NONE)
  }

  function exitMenu_left(row) {
    var $this = $(row.activeRow);
    $this.closest(Selector.DN_LEVEL2).css({ 'width': DROPDOWN_WIDTH_CSS, 'padding-left': '0', 'height': 'auto' })
    $(Selector.DN_LEVEL1)
      .find(Selector.MAINTAIN_HOVER)
      .removeClass(ClassName.MAINTAIN_HOVER)
      .attr('aria-expanded', 'false')
      .closest(Selector.OPEN)
      .find(Selector.DN_DROPDOWN_TOGGLE)
      .focus();

    return true;
  }

  function exitMenu_right(row) {
    var $this = $(row.activeRow);
    $this.closest(Selector.DN_LEVEL2).css('width', DROPDOWN_WIDTH_CSS).css('height', 'auto');
    $(Selector.DN_LEVEL1)
      .find(Selector.MAINTAIN_HOVER)
      .removeClass(ClassName.MAINTAIN_HOVER)
      .attr('aria-expanded', 'false')
      .closest(Selector.OPEN)
      .find(Selector.DN_DROPDOWN_TOGGLE)
      .focus();

    return true;
  }
  // END menu aim plugin functions

  function reset_dropdown() {
    $(Selector.DN_LEVEL1)
      .find(Selector.DN_LEVEL2).css({ 'width': DROPDOWN_WIDTH_CSS, 'height': 'auto', 'padding-left': '0' })
      .end()
      .find(Selector.DN_LEVEL3).addClass(ClassName.D_NONE)
      .end()
      .find(Selector.MAINTAIN_HOVER)
      .removeClass(ClassName.MAINTAIN_HOVER)
      .attr('aria-expanded', 'false');
  }

  // ** do main functionality for dropdowns here -- init menuAim plugin
  function init_dropdown_and_set_dropdown_direction() {

    var window_width = $(window).width()

    $(`${Selector.DN_LEVEL2_ITEM}, ${Selector.DN_LEVEL3_ITEM}`).each(function () {

      var
        $this = $(this).closest(Selector.DN_DROPDOWN),
        $this_level2 = $this.find(Selector.DN_LEVEL2),
        $this_level2_a = $this_level2.children('li').children('a'),
        $this_level3 = $this.find(Selector.DN_LEVEL3),
        $this_level3_item = $this_level3.find(Selector.DN_LEVEL3_ITEM),
        direction = '',
        activate = null,
        exitMenu = null,
        multiplier = 1;

      if ($this_level3.length > 0) { // if level 3
        multiplier = 2
      }

      if (is_dropdown_gt_window_width($this, (DROPDOWN_WIDTH_PX_VAL * multiplier + 10), window_width)) { // 512px (width of flyout) plus 10px padding
        $this_level2
          .removeClass(ClassName.OPEN_RIGHT)
          .addClass(ClassName.OPEN_LEFT);

        // set options for jquery menu aim
        direction = 'left';
        activate = activate_left;
        exitMenu = exitMenu_left;
      } else {
        $this_level2
          .removeClass(ClassName.OPEN_LEFT)
          .addClass(ClassName.OPEN_RIGHT);

        // set options for jquery menu aim
        direction = 'right';
        activate = activate_right;
        exitMenu = exitMenu_right;
      }

      if (multiplier === 2) {

        // reset menuAim if previously initialized
        if ($this_level2.data('jquery.menu-aim')) {
          $this_level2.menuAim('destroy');
        }

        const TIMEOUT_MS = 300;
        setTimeout(function () { // timeout needed to allow menuAim('destroy') to complete

          reset_dropdown();

          // init menuAim
          $this_level2.menuAim({
            activate: activate,
            deactivate: deactivate,
            tolerance: 300,
            submenuDirection: direction,
            exitMenu: exitMenu,
            useXrxWorkaroundForExitMenuOption: true
          });

          // link tabbing for level3
          $this_level2_a
            .off('.link-tabbing')
            .on('focus.link-tabbing', function () {
              var $this = $(this);
              deactivate($this.closest('ul').find(Selector.MAINTAIN_HOVER));
              activate($this.closest('li'));
            });

          // when dropdown is closed reset the 2nd and 3rd level
          // BE SURE TO SET menuAim option "useXrxWorkaroundForExitMenuOption" TO "true" above
          $this
            .off('.reset-exitMenu')
            .on('hidden.xrx.dropdown.reset-exitMenu', function () {
              $(this).find(Selector.DN_LEVEL2).menuAim('mouseleaveMenu');
            });

          // if navbar has level3, stop level2 (dropdown) from closing on click
          $this_level3.closest(Selector.DN_LEVEL2_ITEM)
            .off('.stopPropagation.dn-level2')
            .on('click.stopPropagation.dn-level2', function (e) {
              e.stopPropagation();
            });

          // close dropdown on level3 item click
          $this_level3_item
            .off('.stopPropagation.dn-level3-item')
            .on('click.stopPropagation.dn-level3-item', function (e) {
              e.stopPropagation();
              $(document).trigger('click');
            });

        }, TIMEOUT_MS);

      }

    });
  }

  // on document load
  init_dropdown_and_set_dropdown_direction();

  // call on window resize
  $window.on('resize', $.debounce(DEBOUNCE_MS, function () {
    init_dropdown_and_set_dropdown_direction();
  }));
  // END menu aim

  // on resize - turn off animation for mobile navbar
  if (usingLeftNavbar) {
    $window
      .on('resize.mobile-menu-transition-off', function () {
        $mn_navbar_container.addClass(ClassName.TRANSITION_OFF);
      })
      .on('resize.mobile-menu-transition-on', $.debounce(DEBOUNCE_MS, function () {
        $mn_navbar_container.removeClass(ClassName.TRANSITION_OFF);
      }));
  }

  // ** nav-opt-scroll-desktop option - scroll/hide the navbar

  if (usingNavScrollDesktop ||
    usingNavScrollDesktopBrand) {

    const $dnHeader = $(Selector.DN_HEADER)
    let dn_header_height

    function getPixelsToScroll() {
      const dnHeaderHeight = $dnHeader.outerHeight()
      if (usingNavScrollDesktopBrand) {
        return dnHeaderHeight - $dnMenu.outerHeight()
      }
      return dnHeaderHeight
    }

    dn_header_height = getPixelsToScroll()

    // on fontsize resize
    $(document).on('fontresize', () => {
      dn_header_height = getPixelsToScroll()
    })

    // console.log(dn_header_height)

    $(Selector.MN_HEADER_CLOSE_BTN).css({ 'transition': 'height 100ms linear' })
    $dnHeader.css({ 'transition': `transform 100ms linear` })

    // helper
    function translate3d(y) {
      $dnHeader
        .css('-webkit-transform', `translate3d(0, ${y}px, 0)`)
        .css('transform', `translate3d(0, ${y}px, 0)`);
    }

    let scrollPosTemp = null
    let isNavbarHidden = false
    $window.on('scroll', $.debounce(30, function () {

      const scrollLeftNavbar = (usingLeftNavbar &&
        matchMedia(`(min-width: ${MD_BREAKPOINT+1}px)`).matches)

      const scrollPos = window.pageYOffset

      if (scrollPosTemp === null) {
        scrollPosTemp = scrollPos
      }

      if (scrollPos >= 0) {
        if (scrollPosTemp > scrollPos) {
          // console.log(`${scrollPosTemp} > ${scrollPos}`)
          translate3d(0)
          // console.log('navbar shown')
          isNavbarHidden = false
          if (scrollLeftNavbar) {
            $(Selector.MN_HEADER_CLOSE_BTN).css({ 'height': `${dn_header_height}px` })
          }
        } else if (scrollPosTemp < scrollPos && scrollPos > 150) {
          // console.log(`${scrollPosTemp} < ${scrollPos}`)
          translate3d(-dn_header_height)
          // console.log('navbar hidden')
          isNavbarHidden = true
          if (scrollLeftNavbar) {
            $(Selector.MN_HEADER_CLOSE_BTN).css({ 'height': '0px' })
          }
        }
        scrollPosTemp = scrollPos
      }
    }))

    $window.on('resize', $.debounce(DEBOUNCE_MS, function () {
      if (usingLeftNavbar) {
        if (isNavbarHidden && matchMedia(`(min-width: ${MD_BREAKPOINT+1}px)`).matches) {
          $(Selector.MN_HEADER_CLOSE_BTN).css({ 'height': '0px' })
        } else {
          $(Selector.MN_HEADER_CLOSE_BTN).css({ 'height': `${dn_header_height}px` })
        }
      }
    }))

  }

  // ** desktop slide indicator
  let dnInitializedCount = 0

  function dnSlideIndicator() {

    if ($dnMenu.length === 0) {
      return
    }

    function slide($target) {
      const ms = 10
      setTimeout(function () {
        $target.closest('.dn-menu').find(Selector.DN_SLIDING_INDICATOR).css({
          left: $target.position().left,
          width: $target.outerWidth()
        })
      }, ms)
    }

    function setDnIndicator($target) {
      const $previous = $dnMenu.find(Selector.DN_INDICATOR)
      const $current = $target
      $previous.removeClass(ClassName.DN_INDICATOR)
      $current.addClass(ClassName.DN_INDICATOR)
      // return { previous: $previous, current: $current }

      const showEvent = $.Event('dn-menu-item-clicked', {
        target: $current,
        relatedTarget: $previous
      })

      $dnMenu.trigger(showEvent)
    }

    function initDnIndicator($target) {
      if ($target.closest('.dn-level2-item').length > 0) {
        const $dropdownToggle = $target.closest('.dn-dropdown').find('.dn-dropdown-toggle')

        $dropdownToggle
          .addClass(ClassName.TRANSITION_OFF)
        // .addClass(ClassName.DN_INDICATOR)
        setDnIndicator($dropdownToggle)
        slide($dropdownToggle)
      } else {
        $target
          .addClass(ClassName.TRANSITION_OFF)
        // .addClass(ClassName.DN_INDICATOR)
        setDnIndicator($target)
        slide($target)
      }
    }

    const ms500 = 500

    // on document load
    if ($dnMenu.find(Selector.DN_SLIDING_INDICATOR).length === 0) {
      $dnMenu.append(`<li class="${ClassName.DN_SLIDING_INDICATOR}" aria-hidden="true"></li>`)
    }

    // slide($dnMenu.find(Selector.SELECTED))
    initDnIndicator($dnMenu.find(Selector.SELECTED))

    setTimeout(() => {
      if (dnInitializedCount === 0) {
        // slide($dnMenu.find(Selector.SELECTED))

        initDnIndicator($dnMenu.find(Selector.SELECTED))
        // console.log('init slide hack')
      }
      dnInitializedCount++
      // console.log('dnInitializedCount', dnInitializedCount)
    }, ms500)

    setTimeout(() => {
      if (usingNavSinglePageApp) {
        $dnMenu.find(Selector.DN_SLIDING_INDICATOR).addClass('animate')
      }
      $dnMenu.find(Selector.DN_INDICATOR).removeClass(ClassName.TRANSITION_OFF)
    }, ms500)

    $('.dn-menu-item > a:not([aria-haspopup])')
      .off('click.dn-slider')
      .on('click.dn-slider', function () {
        const $this = $(this)

        // set selected
        $dnMenu.find(Selector.SELECTED).removeClass(ClassName.SELECTED)
        $this.addClass(ClassName.SELECTED)

        if ($this.closest('.dn-level2-item').length > 0) {
          const $dropdownToggle = $this.closest('.dn-dropdown').find('.dn-dropdown-toggle')
          setDnIndicator($dropdownToggle)
          slide($dropdownToggle)
        } else {
          setDnIndicator($this)
          slide($this)
        }

      })
  }

  /* ==============================================================================================
   ** SECTION Mobile Navbar
  ============================================================================================== */

  $open_elements.on('click', function () {

    if (!$mn_blanket.hasClass(ClassName.FADE)) {

      // $('body').addClass('mobile-menu-open');

      $mn_navbar_container
        .addClass(ClassName.SHOW)
        .find(Selector.MN_CLOSE_BTN)
        .focus();

      $mn_blanket.addClass(ClassName.SHOW);

      $close_elements.one('click.close_mobile_menu', function () {

        setTimeout(function () {
          if (!$mn_navbar_container.hasClass(ClassName.SHOW)) {
            $mn_blanket.removeClass(ClassName.SHOW).removeClass(ClassName.FADE);
            // hide all collapsable tab panels
            $mn_navbar_container.find(Selector.MINIMIZE_SHOW).collapse(ClassName.HIDE);
          }
        }, TRANSITION_END_MS);

        // $('body').removeClass('mobile-menu-open');
        $mn_navbar_container.removeClass(ClassName.SHOW);
        $mn_blanket.addClass(ClassName.FADE);

        // reset focus
        if (matchMedia('(min-width: ' + MD_BREAKPOINT + 'px)').matches) {
          $dn_open_btn.focus();
        } else {
          $mn_open_btn.focus();
        }

      });

    }

  });

  // ** caret up/down on dropdown toggle and collapse toggle

  // helpler
  function caret($el) {
    return $el.closest(Selector.MN_MENU_ITEM).children(Selector.DATA_TOGGLE_COLLAPSE).children(Selector.MN_COLLAPSE_TARGET_CARET);
  }

  let stopBubbleNavigateShow = false
  let stopBubbleNavigateHide = false
  $(Selector.MN_LEVEL1)
    .on('show.bs.collapse', Selector.MN_COLLAPSE, function (e) {
      const $this = $(this)
      if (stopBubbleNavigateShow) {
        stopBubbleNavigateShow = false
        return
      }
      const $caret = caret($this)
      $caret
        .removeClass(ClassName.NAVIGATE_DOWN)
        .addClass(ClassName.NAVIGATE_UP);

      if ($this.closest('.mn-level2-item').length > 0) { // if level3
        stopBubbleNavigateShow = true
      }
    })
    .on('hide.bs.collapse', Selector.MN_COLLAPSE, function (e) {
      const $this = $(this)
      if (stopBubbleNavigateHide) {
        stopBubbleNavigateHide = false
        return
      }
      const $caret = caret($this)
      $caret
        .removeClass(ClassName.NAVIGATE_UP)
        .addClass(ClassName.NAVIGATE_DOWN);

      if ($this.closest('.mn-level2-item').length > 0) { // if level3
        stopBubbleNavigateHide = true
      }
    });

  // ** .nav-collapse option for default/basic drawer menu (9x feature)
  function navMinimize() {

    if (usingNavMinimize) {
      const COLLAPSE_WIDTH = 69
      const EXPAND_WIDTH = 300
      const COLLAPSE_MS = 150
      const EXPAND_MS = COLLAPSE_MS
      const LABEL_FADE_MS = COLLAPSE_MS
      const $collapse_icon = $(Selector.NAV_DRAWER_MINIMIZE_ICON)
      const $menu_containers = $(`${Selector.MN_NAVBAR_CONTAINER}, ${Selector.MN_CONTAINER}`)
      const $mn_menu_item_label = $(Selector.MN_MENU_ITEM_LABEL)

      function init() {
        // add transition css
        $menu_containers.css({ 'transition': `width ${COLLAPSE_MS}ms linear` })
        $mn_menu_item_label.css({ 'transition': `opacity ${LABEL_FADE_MS}ms linear` })
        $body.css({ 'transition': `margin-left ${COLLAPSE_MS}ms linear` })
        $xrx_footer.css({ 'transition': `margin-left ${COLLAPSE_MS}ms linear` })

        if (usingDsGo) {
          $(`.new-collection-btn .btn-label`).css({
            'transition': `width ${LABEL_FADE_MS}ms linear, opacity ${LABEL_FADE_MS}ms linear`
          })

          // fade out menu links labels
          if ($mn_navbar_container.hasClass(ClassName.COLLAPSED)) {
            $mn_menu_item_label.css({ 'opacity': '0' })
          }

          if ($mn_navbar_container.hasClass(ClassName.COLLAPSED) === false) {
            $(`.new-collection-btn .btn-label`).css({
              'width': `121px`
            })
          }

          $(`.new-collection-btn`).css({
            'transition': `
            color .2s linear,
            background-color .2s linear,
            border-color .2s linear,
            padding ${LABEL_FADE_MS}ms linear`
          })

          $(`.nav-drawer-minimize-inner`).css({
            'transition': `padding ${LABEL_FADE_MS}ms linear`
          })
        }

      }

      if (matchMedia(`(min-width: ${MD_BREAKPOINT+1}px)`).matches) {
        init()
      }

      function collapse() {
        const transitionDuration = COLLAPSE_MS
        $mn_navbar_container.addClass(ClassName.COLLAPSING)

        // resize containers
        $menu_containers.css({ 'width': `${COLLAPSE_WIDTH}px` })
        $body.css({ 'margin-left': `${COLLAPSE_WIDTH}px` })
        $xrx_footer.css({ 'margin-left': `${COLLAPSE_WIDTH}px` })

        // fade out menu links labels
        $mn_menu_item_label.css({ 'opacity': '0' })

        if (usingDsGo) {
          // fade out minimize button label
          $(`.new-collection-btn .btn-label`).css({
            'width': '0',
            'opacity': '0'
          })

          $(`.new-collection-btn`).css({
            'padding': `0 8px`
          })

          $(`.nav-drawer-minimize-inner`).css({
            'padding': `16px 14px`
          })
        }

        // on transition end
        $mn_navbar_container.one(Util.TRANSITION_END, function () {
            $mn_navbar_container
              .removeClass(ClassName.COLLAPSING)
              .addClass(ClassName.COLLAPSED)
            $collapse_icon
              .removeClass(ClassName.XGL_ALIGN_LEFT)
              .addClass(ClassName.XGL_ALIGN_RIGHT)
          })
          .emulateTransitionEnd(transitionDuration)
      }

      function expand() {
        const transitionDuration = EXPAND_MS
        $mn_navbar_container.addClass(ClassName.EXPANDING)
        $mn_navbar_container.removeClass(ClassName.COLLAPSED)

        // resize containers
        $menu_containers.css({ 'width': `${EXPAND_WIDTH}px` })
        $body.css({ 'margin-left': `${EXPAND_WIDTH}px` })
        $xrx_footer.css({ 'margin-left': `${EXPAND_WIDTH}px` })

        if (usingDsGo) {
          // fade out minimize button label
          $(`.new-collection-btn .btn-label`).css({
            'width': '121px'
          })

          $(`.nav-drawer-minimize-inner`).css({
            'padding': `16px 16px`
          })
        }

        // on transition end
        $mn_navbar_container.one(Util.TRANSITION_END, function () {
            $mn_navbar_container.removeClass(ClassName.EXPANDING)
            $collapse_icon
              .removeClass(ClassName.XGL_ALIGN_RIGHT)
              .addClass(ClassName.XGL_ALIGN_LEFT)

            // fade in menu links labels
            $mn_menu_item_label.css({ 'opacity': '1' })

            if (usingDsGo) {
              // fade out minimize button label
              $(`.new-collection-btn .btn-label`).css({
                'opacity': '1'
              })
            }

          })
          .emulateTransitionEnd(transitionDuration)
      }

      // on click - collapse button
      $(Selector.NAV_COLLAPSE_BTN)
        .off('click.collapse-button')
        .on('click.collapse-button', function () {
          if ($mn_navbar_container.hasClass(ClassName.COLLAPSING) ||
            $mn_navbar_container.hasClass(ClassName.EXPANDING)
          ) {
            return
          }

          if ($mn_navbar_container.hasClass(ClassName.COLLAPSED)) {
            expand()
          } else {
            collapse()
          }
        })

      // on resize - set mobile/desktop for collapse drawer
      let matchMediaLock = true
      $window
        .off('resize.drawer-collapse')
        .on('resize.drawer-collapse', function () {
          if (matchMedia(`(min-width: ${MD_BREAKPOINT+1}px)`).matches) {
            if (matchMediaLock) return
            if ($mn_navbar_container.hasClass(ClassName.COLLAPSED)) {
              collapse()
            }
            setTimeout(function () {
              init()
            }, COLLAPSE_MS)
            matchMediaLock = true
            // console.log('gt 768')
          } else {
            if (!matchMediaLock) return
            $menu_containers.removeAttr('style')
            $mn_menu_item_label.removeAttr('style')
            $body.removeAttr('style')
            matchMediaLock = false
            // console.log('lt 769')
          }
        })

    }

  }

  // ** nav-opt-scroll-mobile option - scroll/hide the navbar

  if (usingNavScrollMobile) {

    const $mnHeader = $(Selector.MN_HEADER)

    $mnHeader.css({ 'transition': `transform 100ms linear` })
    let mn_header_height = $mnHeader.outerHeight();

    // console.log(mn_header_height)

    $(document).on('fontresize', () => {
      mn_header_height = $mnHeader.outerHeight()
    })

    // helper
    function translate3d(y) {
      $mnHeader
        .css('-webkit-transform', `translate3d(0, ${y}px, 0)`)
        .css('transform', `translate3d(0, ${y}px, 0)`);
    }

    let scrollPosTemp = null
    $window.on('scroll', $.debounce(30, function () {

      const scrollPos = window.pageYOffset

      if (scrollPosTemp === null) {
        scrollPosTemp = scrollPos
      }

      if (scrollPos >= 0) {
        if (scrollPosTemp > scrollPos) {
          // console.log(`${scrollPosTemp} > ${scrollPos}`)
          translate3d(0)
        } else if (scrollPosTemp < scrollPos && scrollPos > 150) {
          // console.log(`${scrollPosTemp} < ${scrollPos}`)
          translate3d(-mn_header_height)
        }
        scrollPosTemp = scrollPos
      }
    }))
  }

  // ** desktop slide indicator
  function mnSlideIndicator() {

    const transitionDuration = 250

    const $mnLevel1 = $(Selector.MN_LEVEL1_ITEM)
    const $mnLevel2 = $(Selector.MN_LEVEL2_ITEM)

    // *****************   setup sliders start  ***************** //

    // global slider
    if ($mnMenu.find(Selector.MN_GLOBAL_SLIDING_INDICATOR).length === 0) {
      $mnMenu.append(`<li class="${ClassName.MN_GLOBAL_SLIDING_INDICATOR}" aria-hidden="true"></li>`)
    }

    // level 2 menu sliders
    $mnLevel1.each(function (index) {
      const $this = $(this)
      if ($this.children(Selector.MN_LEVEL2_SLIDING_INDICATOR).length === 0 &&
        $this.children('[data-toggle]').length > 0) {
        $this.append(`<div class="${ClassName.MN_LEVEL2_SLIDING_INDICATOR}" aria-hidden="true"></div>`)
      }
    })

    // level 3 menu sliders
    $mnLevel2.each(function (index) {
      const $this = $(this)
      if ($this.children(Selector.MN_LEVEL3_SLIDING_INDICATOR).length === 0 &&
        $this.children('[data-toggle]').length > 0) {
        $this.append(`<div class="${ClassName.MN_LEVEL3_SLIDING_INDICATOR}" aria-hidden="true"></div>`)
      }
    })

    // *****************   setup sliders end  ***************** //

    // // test debug colors

    // $('.xrx-checkbox')
    //   .checkbox()
    //   .find('input')
    //   .on('change', function () {
    //     if ($(this).closest('.xrx-checkbox').checkbox('getChecked')) {
    //       $(Selector.MN_GLOBAL_SLIDING_INDICATOR).css('background-color', 'red')
    //       $(Selector.MN_LEVEL2_SLIDING_INDICATOR).css('background-color', 'green')
    //       $(Selector.MN_LEVEL3_SLIDING_INDICATOR).css('background-color', 'orange')
    //     } else {
    //       $(Selector.MN_GLOBAL_SLIDING_INDICATOR).css('background-color', '#155fbb')
    //       $(Selector.MN_LEVEL2_SLIDING_INDICATOR).css('background-color', '#155fbb')
    //       $(Selector.MN_LEVEL3_SLIDING_INDICATOR).css('background-color', '#155fbb')
    //     }

    //   })

    const mnMenuItemHeight = $('.mn-level1-item').eq(0).outerHeight()
    const $mngsi = $mnMenu.find(Selector.MN_GLOBAL_SLIDING_INDICATOR)

    function synchronizeGlobalSlider() { // reset position of the global slider
      const $mnIndicator = $mnMenu.find(Selector.MN_INDICATOR)

      $mngsi
        .removeClass('animate')
        .css({
          top: mnMenuItemHeight * visibleItems($mnMenu).index($mnIndicator),
          height: mnMenuItemHeight
        })
    }

    function synchronizeL2Slider() { // reset position of level2

      const _1 = 1
      const $mnIndicator = $mnMenu.find(Selector.MN_INDICATOR)

      const $level2 = $mnIndicator.closest('.mn-level2')

      const $thisMnLevel1Item = $level2.closest('.mn-level1-item')
      const $thisLevel2Indicator = $thisMnLevel1Item.children(Selector.MN_LEVEL2_SLIDING_INDICATOR)
      const index = visibleItems($thisMnLevel1Item).index($mnIndicator) + _1

      $thisLevel2Indicator
        .removeClass('animate')
        .css({
          top: mnMenuItemHeight * index,
          height: mnMenuItemHeight
        })

      if (usingDsGo) {
        $thisLevel2Indicator.css({ 'top': '0' })
      }

    }

    function synchronizeL3Slider() { // reset position of level3 sliders

      const _1 = 1
      const $mnIndicator = $mnMenu.find(Selector.MN_INDICATOR)

      const $level3 = $mnIndicator.closest('.mn-level3')

      const $thisMnLevel2Item = $level3.closest('.mn-level2-item')
      const $thisLevel3Indicator = $thisMnLevel2Item.children(Selector.MN_LEVEL3_SLIDING_INDICATOR)
      const index = visibleItems($thisMnLevel2Item).index($mnIndicator) + _1

      $thisLevel3Indicator
        .removeClass('animate')
        .css({
          top: mnMenuItemHeight * index,
          height: mnMenuItemHeight
        })
    }

    function synchronizeL2L3Sliders() { // reset position of level2 and level3 sliders
      synchronizeL2Slider()
      synchronizeL3Slider()
    }

    function showMnItemIndicator() {
      $mnMenu.find(Selector.MN_INDICATOR).addClass(ClassName.MN_INDICATOR_SHOW)
    }

    function hideMnItemIndicator() {
      $mnMenu.find(Selector.MN_INDICATOR).removeClass(ClassName.MN_INDICATOR_SHOW)
    }

    function globalSlide(index) {

      hideMnItemIndicator()

      $mngsi.one(Util.TRANSITION_END, function () {
          // console.log('TRANSITION_END')
          showMnItemIndicator()
          synchronizeL2L3Sliders()
          $mngsi.removeClass('show')
        })
        .emulateTransitionEnd(transitionDuration)

      if (usingNavSinglePageApp) {
        $mngsi.addClass('animate')
      }

      $mngsi
        .addClass('show')
        .css({
          top: (mnMenuItemHeight * index),
          height: mnMenuItemHeight
        })

    }

    function secondLevelSlide(index, $target, $thisSlider) {

      hideMnItemIndicator()

      setMnIndicatorClass($target)

      $thisSlider.one(Util.TRANSITION_END, function () {
          // console.log('TRANSITION_END')
          showMnItemIndicator()
          synchronizeGlobalSlider()
          $thisSlider.removeClass('show')
        })
        .emulateTransitionEnd(transitionDuration)

      $thisSlider
        .addClass('animate')
        .addClass('show')
        .css({
          top: (mnMenuItemHeight * index),
          height: mnMenuItemHeight
        })

    }

    function thirdLevelSlide(index, $target, $thisSlider) {

      hideMnItemIndicator()

      setMnIndicatorClass($target)

      $thisSlider.one(Util.TRANSITION_END, function () {
          // console.log('TRANSITION_END')
          showMnItemIndicator()
          synchronizeGlobalSlider()
          $thisSlider.removeClass('show')
        })
        .emulateTransitionEnd(transitionDuration)

      $thisSlider
        .addClass('animate')
        .addClass('show')
        .css({
          top: (mnMenuItemHeight * index),
          height: mnMenuItemHeight
        })

    }

    function visibleItems($levelTarget) {
      return $levelTarget.find("li > a").not('[aria-expanded="false"] ~ .collapse a')
    }

    function setMnIndicatorClass($target) {
      const $previous = $mnMenu.find(Selector.MN_INDICATOR)
      const $current = $target

      $previous.removeClass(ClassName.MN_INDICATOR)
      $current.addClass(ClassName.MN_INDICATOR)

      const itemClickedEvent = $.Event('mn-menu-item-clicked', {
        target: $current,
        relatedTarget: $previous
      })
      $mnMenu.trigger(itemClickedEvent)
    }

    function initMnIndicatorClass($target) {

      setMnIndicatorClass($target)

      $target
        .addClass(ClassName.TRANSITION_OFF)
        // .addClass(ClassName.MN_INDICATOR)
        .addClass(ClassName.MN_INDICATOR_SHOW)

      function openCollapse($target) {
        // close all collapse first
        $mnMenu.find('.collapse')
          .removeClass('show')
          .siblings('[data-toggle="collapse"]')
          .attr('aria-expanded', 'false')
          .find('[class*="xgl-sort"]')
          .removeClass('xgl-sort_up')
          .addClass('xgl-sort_down')

        // then open the target collapse
        $target.closest('.collapse')
          .addClass('show')
          .siblings('[data-toggle="collapse"]')
          .attr('aria-expanded', 'true')
          .find('[class*="xgl-sort"]')
          .removeClass('xgl-sort_down')
          .addClass('xgl-sort_up')
      }

      openCollapse($target.parents('.collapse'))
    }

    // *****************   intialization start  ***************** //

    // on document load
    const ms = 500
    initMnIndicatorClass($mnMenu.find(Selector.SELECTED))
    setTimeout(() => {
      $mnMenu.find(Selector.MN_INDICATOR).removeClass(ClassName.TRANSITION_OFF)
      synchronizeGlobalSlider()
      synchronizeL2L3Sliders()
    }, ms)

    // *****************   intialization end  ***************** //
    let $mnSliderSelector

    if (usingDsGo) {
      $mnSliderSelector = $('.mn-menu-item > a')
    } else {
      $mnSliderSelector = $('.mn-menu-item > a:not([data-target])')
    }

    $mnSliderSelector
      .off('click.mn-slider')
      .on('click.mn-slider', function () {
        const $this = $(this)

        $mnMenu.find(Selector.SELECTED).removeClass(ClassName.SELECTED)
        $this.addClass(ClassName.SELECTED)

        // console.log($this)

        // set global slider
        if (usingDsGo) {

          if ($this.parent('.mn-level1-item').length > 0) {
            globalSlide($mnMenu.find('li > a').not('.mn-level2-item > a').index($mnMenu.find('.selected')))

            if (!$this.is('[data-toggle]')) {
              $('.mn-collapse.show').collapse('hide')
            }
          } else {
            globalSlide(visibleItems($mnMenu).index($mnMenu.find('.selected')))
          }

        } else {
          globalSlide(visibleItems($mnMenu).index($mnMenu.find('.selected')))
        }

        // set mn-indicator
        setMnIndicatorClass($this)
      })

    let stopBubbleHide = false
    $mnMenu
      .off('hide.bs.collapse.mn-indicator')
      .on('hide.bs.collapse.mn-indicator', Selector.MN_COLLAPSE, function (e) {
        const $this = $(this)

        // console.log('hide navbar')

        // synchronizeGlobalSlider()

        const $thisLevel = $this.closest('.mn-menu-item')
        const $thisDataToggle = $thisLevel.find(' > [data-toggle]')

        if ($this.closest('.mn-level2-item').length > 0) {
          // level 3 collapse
          const $thisLevelSlider = $thisLevel.children(Selector.MN_LEVEL3_SLIDING_INDICATOR)
          stopBubbleHide = true

          if ($thisLevel.find('ul > li > .selected').length > 0) {
            thirdLevelSlide(0, $thisDataToggle, $thisLevelSlider)
          }

        } else {
          // level 2 collapse
          if (stopBubbleHide) {
            stopBubbleHide = false
            return
          }

          const $thisLevelSlider = $thisLevel.children(Selector.MN_LEVEL2_SLIDING_INDICATOR)

          if ($thisLevel.find('ul > li > .selected').length > 0) {
            secondLevelSlide(0, $thisDataToggle, $thisLevelSlider)
          }

        }
      })

    let stopBubbleAfterShow = false
    $mnMenu
      .off('after-show.bs.collapse.mn-indicator')
      .on('after-show.bs.collapse.mn-indicator', Selector.MN_COLLAPSE, function (e) {
        const $this = $(this)
        const _1 = 1

        if (!usingDsGo) {
          synchronizeGlobalSlider()
        }

        const $thisLevel = $this.closest('.mn-menu-item')

        if ($this.closest('.mn-level2-item').length > 0) {
          // level 3 collapse
          const $thisLevelSlider = $thisLevel.children(Selector.MN_LEVEL3_SLIDING_INDICATOR)

          const $selected = $thisLevel.find('ul > li > .selected')

          stopBubbleAfterShow = true

          if ($selected.length > 0) {
            const index = visibleItems($thisLevel).index($selected) + _1
            thirdLevelSlide(index, $selected, $thisLevelSlider)
          }

        } else {
          // level 2 collapse
          if (stopBubbleAfterShow) {
            stopBubbleAfterShow = false
            return
          }

          const $thisLevelSlider = $thisLevel.children(Selector.MN_LEVEL2_SLIDING_INDICATOR)

          const $selected = $thisLevel.find('ul > li > .selected')

          if ($selected.length > 0) {

            const $this3rdLevel = $selected.closest('.mn-level2-item')
            const $this3rdLevelMnCollapse = $this3rdLevel.find('.mn-collapse')

            if ($this3rdLevelMnCollapse.length > 0 &&
              $this3rdLevelMnCollapse.hasClass('show') === false) {
              const $target = $this3rdLevel.find(' > [data-toggle]')
              const index = visibleItems($thisLevel).index($target) + _1
              secondLevelSlide(index, $target, $thisLevelSlider)

            } else {
              const index = visibleItems($thisLevel).index($selected) + _1
              secondLevelSlide(index, $selected, $thisLevelSlider)

            }

          }

        }
      })

    $mnMenu
      .off('hidden.bs.collapse.mn-indicator')
      .on('hidden.bs.collapse.mn-indicator', Selector.MN_COLLAPSE, function (e) {
        // console.log('hidden navbar')
        synchronizeGlobalSlider()
        synchronizeL2L3Sliders()
      })
  }

  /* ==============================================================================================
   ** SECTION Desktop and Mobile Navbar
  ============================================================================================== */

  // ** swap glyphs for desktop and mobile navbar menu items (two_tone and alt)
  function swapGlyphIconClass() {

    function getGlyphIconClass(classList) {
      let targetGlyphIconClass = ''
      classList.forEach((thisClass) => {
        if (thisClass.includes('xgl-')) {
          targetGlyphIconClass = thisClass
        }
      })
      return targetGlyphIconClass
    }

    function swap(target, relatedTarget) {
      const glyphSelector = '[class*="-item-glyph"]'
      const $targetIcon = $(target).find(glyphSelector)
      const $relatedTargetIcon = $(relatedTarget).find(glyphSelector)

      if ($targetIcon.length > 0) {
        const targetGlyphIconClass = getGlyphIconClass($targetIcon[0].classList)
        const newTargetGlyphIconClass = targetGlyphIconClass.replace('_two_tone', '_alt')
        $targetIcon.removeClass(targetGlyphIconClass).addClass(newTargetGlyphIconClass)
      }

      if ($relatedTargetIcon.length > 0) {
        const relatedTargetGlyphIconClass = getGlyphIconClass($relatedTargetIcon[0].classList)
        const newRelatedTargetGlyphIconClass = relatedTargetGlyphIconClass.replace('_alt', '_two_tone')
        $relatedTargetIcon.removeClass(relatedTargetGlyphIconClass).addClass(newRelatedTargetGlyphIconClass)
      }
    }

    // on document load
    swap($dnMenu.find(Selector.DN_INDICATOR))
    // // on tab show
    $dnMenu
      .off('dn-menu-item-clicked')
      .on('dn-menu-item-clicked', (e) => {

        const targetLabel = e.target.find('.dn-menu-item-label').text()
        const relatedTargetLabel = e.relatedTarget.find('.dn-menu-item-label').text()

        if (targetLabel !== relatedTargetLabel) {
          swap(e.target, e.relatedTarget)
        }
      })

    // on document load
    swap($mnMenu.find(Selector.MN_INDICATOR))
    // // on tab show
    $mnMenu
      .off('mn-menu-item-clicked')
      .on('mn-menu-item-clicked', (e) => {

        const $a = $mnMenu.find('a')
        const targetIndex = $a.index(e.target)
        const relatedTargetIndex = $a.index(e.relatedTarget)

        // console.log('targetIndex', targetIndex)
        // console.log(e.target)
        // console.log('relatedTargetIndex', relatedTargetIndex)
        // console.log(e.relatedTarget)

        if (targetIndex !== relatedTargetIndex) {
          swap(e.target, e.relatedTarget)
        }
      })
  }

  function syncDnSelected() { // sync the mobile and desktop selected states

    // get dn selected index
    const mnSelectedIndex = $mnMenu.find('a').index($mnMenu.find('.selected'))

    // update dn selected state
    $dnMenu.find('.selected')
      .removeClass('selected')

    $dnMenu.find('a').eq(mnSelectedIndex).addClass('selected')

    dnSlideIndicator()

  }

  function syncMnSelected() { // sync the mobile and desktop selected states

    // get dn selected index
    const dnSelectedIndex = $dnMenu.find('a').index($dnMenu.find('.selected'))

    // update mn selected state
    $mnMenu.find('.selected')
      .removeClass('selected')
      .removeClass(ClassName.MN_INDICATOR_SHOW)

    $mnMenu.find('a').eq(dnSelectedIndex).addClass('selected')

    mnSlideIndicator()

  }

  function setDnAria() {
    $(Selector.DN_HEADER).attr('aria-hidden', 'false')
    $(Selector.MN_HEADER).attr('aria-hidden', 'true')
    $(Selector.MN_NAVBAR_CONTAINER).attr('aria-hidden', 'true')
  }

  function setDnLeftNavbarAria() {
    $(Selector.DN_HEADER).attr('aria-hidden', 'false')
    $(Selector.DN_MENU_CONTAINER).attr('aria-hidden', 'true')
    $(Selector.MN_HEADER).attr('aria-hidden', 'true')
    $(Selector.MN_NAVBAR_CONTAINER).attr('aria-hidden', 'false')
  }

  function setMnAria() {
    $(Selector.DN_HEADER).attr('aria-hidden', 'true')
    $(Selector.MN_HEADER).attr('aria-hidden', 'false')
    $(Selector.MN_NAVBAR_CONTAINER).attr('aria-hidden', 'false')
  }

  function isDnView() {
    return matchMedia(`(min-width: ${MD_BREAKPOINT+1}px)`).matches
  }

  function navbarResize() {
    const DN = 'dn'
    const MN = 'mn'
    let activeView

    if (isDnView()) {
      activeView = DN
    } else {
      activeView = MN
    }

    $window.on('resize', $.debounce(DEBOUNCE_MS, function () {
      if (isDnView() && activeView !== DN) {
        if (usingLeftNavbar === false) {
          syncDnSelected()
          setDnAria()
        }
        if (usingLeftNavbar === true) {
          setDnLeftNavbarAria()
        }
        activeView = DN
      }

      if (!isDnView() && activeView !== MN) {
        if (usingLeftNavbar === false) {
          syncMnSelected()
          setMnAria()
        }
        if (usingLeftNavbar === true) {
          setMnAria()
        }
        activeView = MN
      }

    }))
  }

  // on document load
  if (usingLeftNavbar === false) {
    if (isDnView()) {
      setDnAria()
    } else {
      setDnAria()
    }
  }

  if (usingLeftNavbar === true) {
    if (isDnView()) {
      setDnLeftNavbarAria()
    } else {
      setMnAria()
    }
  }

  function initSelected() {
    const isDnSelectedSet = ($dnMenu.find('.selected').length > 0)
    const isMnSelectedSet = ($mnMenu.find('.selected').length > 0)

    if (isDnSelectedSet) {
      syncMnSelected()
    }

    if (isMnSelectedSet) {
      syncDnSelected()
    }

    // if .selected is not set on either nav, set selected to first nav link
    if (isDnSelectedSet === false && isMnSelectedSet === false) {
      $(Selector.DN_LEVEL1_ITEM).find('a:not([aria-haspopup])').eq(0).addClass('selected')
      $(Selector.MN_LEVEL1_ITEM).find('a:not([data-toggle])').eq(0).addClass('selected')
    }
  }

  /* ==============================================================================================
   ** SECTION Docushare Go
  ============================================================================================== */
  // define allowHideOnClick - Allow Collection item level 1 to collapse upon clicking it when inserting a new collection
  let allowHideOnClick = false

  // click on expansion item

  if (usingDsGo) {
    $(Selector.MN_LEVEL1)
      .on('click', '[data-toggle]', function (e) {
        const $this = $(this)

        if (allowHideOnClick === false) {
          if ($this.attr('aria-expanded') === 'true') {
            $this.next('.collapse').one('hide.bs.collapse', function (e) {
              e.preventDefault()
            })
          }
        }

      })
  }

  function getCollectionLabels() {
    let labelsArr = []
    $('.mn-level1-item > a > .mn-menu-item-label').each(function () {
      // console.log($(this).text())
      labelsArr.push($(this).text().toLowerCase())
    })
    return labelsArr
  }

  function alphaCollectionLabels(newLabel) {
    let alphlabelsArr = []
    newLabel = newLabel.toLowerCase()
    alphlabelsArr = getCollectionLabels().slice(4)
    alphlabelsArr.push(newLabel)
    alphlabelsArr.sort()
    return alphlabelsArr
  }

  function getNewLabelIndexInArr(newLabel) {
    const alphaArr = alphaCollectionLabels(newLabel)
    return alphaArr.findIndex((element) => element === newLabel.toLowerCase())
  }

  function collectionHtml(obj) {
    const html = `
      <a href="${obj.href}" data-toggle="collapse" data-target="#${obj.id}" aria-expanded="false" aria-controls="${obj.id}">
        <img src="${obj.svgPath}${obj.svg}" class="img-fluid custom-icon">
        <span class="mn-menu-item-label">${obj.label}</span>
      </a>`
    return html
  }

  function collectionSubItemHtml(obj) {
    const html = `
      <li class="mn-menu-item mn-level2-item">
        <a href="${obj.href}">
          <i class="mn-level2-item-glyph ${obj.xglGlyph} xglsize-24"></i>
          <span class="mn-menu-item-label">${obj.label}</span>
        </a>
      </li>`
    return html
  }

  function getNewCollectionHtml(arrayOfObjects) {
    let html = ''
    // let html = `<li class="mn-menu-item mn-level1-item">`
    let id

    arrayOfObjects.forEach((obj, index) => {

      if (index === 0) {
        id = obj.id
        html += collectionHtml(obj)
      } else {
        if (index === 1) {
          html += `
            <div id="${id}" class="collapse mn-collapse" data-parent="#mn-accordion0">
              <ul class="mn-menu mn-level2" id="mn-${index}">`
        }
        html += collectionSubItemHtml(obj)
      }

    })

    html += `
        </ul>
      </div>`

    // html += `</li>`

    return html

  }

  function getNewCollectionWrapperHtml() {
    return '<li class="mn-menu-item mn-level1-item new-collection"></li>'
  }

  function insertNewCollectionEnd(arrayOfObjects) {
    const collectionHtml = getNewCollectionHtml(arrayOfObjects)
    $(collectionHtml).insertAfter($('.mn-level1-item').last())
    mnSlideIndicator()
    navMinimize()
  }

  function insertNewCollectionAlpha(arrayOfObjects) {

    const newCollectionLabel = arrayOfObjects[0].label
    // get the position of the element to be inserted within the set of collection elements
    const alphaIndex = getNewLabelIndexInArr(newCollectionLabel)
    const headerSpaceOffset = 16
    const scrollDuration = 200

    const mnLevel1Item = $('.mn-level1-item')
    const itemIndex = $('.mn-level1-item').eq((alphaIndex) + 3).index()+1
    const level1ItemLength = $('.mn-level1-item').length

    if (level1ItemLength === itemIndex && $('.mn-level2').parent('.show').length === 0) {
      $('.mn-level1').append('<li class="mn-menu-item mn-level1-item mn-level1-item-placeholder" style="height: 56px;"></li>')
    }

    function scroll() {
      const scrollContainerHeight = $('.mn-container').height()
      const headerCloseBtnHeight = $('.mn-header-close-btn').outerHeight()
      const level1ItemHeight = $('.mn-level1-item').outerHeight()

      const positionBottom = $('.mn-level1-item').eq((alphaIndex) + 3).position().top + (level1ItemHeight * 3)

      const itemIsVisible = scrollContainerHeight > positionBottom + headerSpaceOffset

      // console.log(scrollContainerHeight)
      // console.log(positionBottom + headerSpaceOffset)

      // console.log(itemIsVisible)

      if (!itemIsVisible) {
        const offset = -(scrollContainerHeight - (level1ItemHeight * 2) - (headerCloseBtnHeight + headerSpaceOffset))
        ScrollAnimateUtil.scrollToElAnimate($('.mn-container'), $('.mn-level1-item').eq((alphaIndex - 1) + 4), offset, scrollDuration, 0)
      }

    }

    setTimeout(function () {

      $(getNewCollectionWrapperHtml()).insertAfter($('.mn-level1-item').eq((alphaIndex - 1) + 4))

      const $newCollection = $('.new-collection')

      // reflow browser layout in order to animate after inserting element
      Util.reflow($newCollection[0])

      $newCollection.addClass('animate-height')

      $newCollection.one(Util.TRANSITION_END, function () {
          // console.log('transition 1')

          $newCollection.append(getNewCollectionHtml(arrayOfObjects))

          // reflow browser layout in order to animate after inserting element
          Util.reflow($newCollection[0])

          $newCollection.addClass('fade-in')

          $('.new-collection.fade-in > a').one(Util.TRANSITION_END, function () {
              // console.log('transition 2')

              $newCollection.removeClass('new-collection animate-height fade-in')

              mnSlideIndicator()

              navMinimize()

              $('.mn-level1-item').eq((alphaIndex) + 4).find('> a').click()

              $('.mn-level1-item-placeholder').remove()

            })
            .emulateTransitionEnd(250)

        })
        .emulateTransitionEnd(250)

    }, scrollDuration)

    if ($('.mn-level2').parent('.show').length > 0) {

      $('.mn-level2').parent('.show').one('hidden.bs.collapse', function (e) {
        scroll()
      })

      allowHideOnClick = true
      $('.selected').next('.mn-collapse').collapse('hide')
      $('.mn-level2').parent('.show').prev('[data-toggle]').click()
      allowHideOnClick = false

    } else {
      scroll()
    }

  }

  /* ==============================================================================================
   ** SECTION Main -- Navbar component inialization
  ============================================================================================== */

  initSelected()
  dnSlideIndicator()
  mnSlideIndicator()
  swapGlyphIconClass()
  navMinimize()

  // on resize
  navbarResize()

  // export static functions
  const XrxNavbar = {
    dnSlideIndicator,
    mnSlideIndicator,
    insertNewCollectionEnd,
    insertNewCollectionAlpha
  }

  $.XrxNavbar = XrxNavbar

  return XrxNavbar
})($)

export default XrxNavbar
