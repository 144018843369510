/////////////////////////////////////////////////////////////////////////////////////////
// If you want to add more easing functions, go here:
// https://github.com/danro/jquery-easing/blob/master/jquery.easing.js
/////////////////////////////////////////////////////////////////////////////////////////

import $ from 'jquery'

var ScrollAnimateUtil = (function ($) {

  /**
   * easing function
   *
   */
  function _easeInOutCubic(t, b, c, d) {
    if ((t /= d / 2) < 1) return c / 2 * t * t * t + b;
    return c / 2 * ((t -= 2) * t * t + 2) + b;
  }

  /**
   * easing function
   *
   */
  function _easeInOutQuad(t, b, c, d) {
    if ((t /= d / 2) < 1) return c / 2 * t * t + b;
    return -c / 2 * ((--t) * (t - 2) - 1) + b;
  }

  /**
   * easing function
   *
   */
  function _easeOutElastic(t, b, c, d) {
    var s = 1.70158;
    var p = 0;
    var a = c;
    if (t == 0) return b;
    if ((t /= d) == 1) return b + c;
    if (!p) p = d * .3;
    if (a < Math.abs(c)) { a = c; var s = p / 4; } else var s = p / (2 * Math.PI) * Math.asin(c / a);
    return a * Math.pow(2, -10 * t) * Math.sin((t * d - s) * (2 * Math.PI) / p) + c + b;
  }

  /**
   * easing function
   *
   */
  function _linear(t, b, c, d) {
    return c * t / d + b;
  }

  /**
   * easing function
   *
   */
  function _easeOutBounce(t, b, c, d) {
    if ((t /= d) < (1 / 2.75)) {
      return c * (7.5625 * t * t) + b;
    } else if (t < (2 / 2.75)) {
      return c * (7.5625 * (t -= (1.5 / 2.75)) * t + .75) + b;
    } else if (t < (2.5 / 2.75)) {
      return c * (7.5625 * (t -= (2.25 / 2.75)) * t + .9375) + b;
    } else {
      return c * (7.5625 * (t -= (2.625 / 2.75)) * t + .984375) + b;
    }
  }

  var publicObj = {

    /**
     * scroll specified element to top, or specified position with the offset parameter
     *
     */
    scrollToElAnimate: function ($scrollContainer, $scrollToEl, offset, duration, delay) {
      setTimeout(function () {

        var
          startTime, startPos = $scrollContainer.scrollTop(),
          // if relative to screen use .offset() - if relative to element use .position()
          scrollEndValue = $scrollToEl.position().top,
          scrollEndValue = scrollEndValue - startPos,
          scrollEndValue = scrollEndValue - (-offset);

        if (scrollEndValue > -5 && scrollEndValue < 5) return;

        scroll = function (timestamp) {
          startTime = startTime || timestamp;
          var elapsed = timestamp - startTime;
          var progress = _linear(elapsed, startPos, scrollEndValue, duration);
          $scrollContainer.scrollTop(progress);
          elapsed < duration && requestAnimationFrame(scroll);
        }
        requestAnimationFrame(scroll);

      }, delay);
    }

  };

  return publicObj;

})($);

export default ScrollAnimateUtil
