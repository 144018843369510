import $ from 'jquery'
import Util from './xrx-util'

/**
 * --------------------------------------------------------------------------
 * Bootstrap (v4.1.1): modal.js
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 * --------------------------------------------------------------------------
 */

const Modal = (($) => {
  /**
   * ------------------------------------------------------------------------
   * Constants
   * ------------------------------------------------------------------------
   */

  const NAME = 'modal'
  const VERSION = '4.1.1'
  const DATA_KEY = 'xrx.modal'
  const EVENT_KEY = `.${DATA_KEY}`
  const DATA_API_KEY = '.data-api'
  const JQUERY_NO_CONFLICT = $.fn[NAME]
  const ESCAPE_KEYCODE = 27 // KeyboardEvent.which value for Escape (Esc) key

  const Default = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: true,
    fixed: true
  }

  const DefaultType = {
    backdrop: '(boolean|string)',
    keyboard: 'boolean',
    focus: 'boolean',
    show: 'boolean',
    fixed: 'boolean'
  }

  const Event = {
    HIDE: `hide${EVENT_KEY}`,
    HIDDEN: `hidden${EVENT_KEY}`,
    SHOW: `show${EVENT_KEY}`,
    SHOWN: `shown${EVENT_KEY}`,
    FOCUSIN: 'focusin.util-focusin', // focusin moved to util.js
    RESIZE: `resize${EVENT_KEY}`,
    CLICK_DISMISS: `click.dismiss${EVENT_KEY}`,
    KEYDOWN_DISMISS: `keydown.dismiss${EVENT_KEY}`,
    MOUSEUP_DISMISS: `mouseup.dismiss${EVENT_KEY}`,
    MOUSEDOWN_DISMISS: `mousedown.dismiss${EVENT_KEY}`,
    CLICK_DATA_API: `click${EVENT_KEY}${DATA_API_KEY}`
  }

  const ClassName = {
    SCROLLBAR_MEASURER: 'modal-scrollbar-measure',
    BACKDROP: 'modal-backdrop',
    OPEN: 'modal-open',
    FADE: 'fade',
    SHOW: 'show'
  }

  const Selector = {
    FULLSCREEN: '.modal-fullscreen',
    DIALOG: '.modal-dialog',
    DATA_TOGGLE: '[data-toggle="modal"]',
    DATA_DISMISS: '[data-dismiss="modal"]',
    FIXED_CONTENT: '.fixed-top, .fixed-bottom, .is-fixed, .sticky-top',
    STICKY_CONTENT: '.sticky-top',
    NAVBAR_TOGGLER: '.navbar-toggler'
  }

  /**
   * ------------------------------------------------------------------------
   * Class Definition
   * ------------------------------------------------------------------------
   */

  class Modal {
    constructor(element, config) {
      this._config = this._getConfig(config)
      this._element = element
      this._dialog = $(element).find(Selector.DIALOG)[0]
      this._backdrop = null
      this._isShown = false
      this._isBodyOverflowing = false
      this._ignoreBackdropClick = false
      this._scrollbarWidth = 0
      this._isFullScreen = this._hasFullScreenOnInit()
      this._fullScreen()
      // eslint-disable-next-line no-extra-parens, no-magic-numbers
      this._id = String(Math.round(new Date().getTime() + (Math.random() * 100))) // xwuikit
    }

    // Getters

    static get VERSION() {
      return VERSION
    }

    static get Default() {
      return Default
    }

    // Public

    toggle(relatedTarget) {
      return this._isShown ? this.hide() : this.show(relatedTarget)
    }

    show(relatedTarget) {
      if (this._isTransitioning || this._isShown) {
        return
      }

      if ($(this._element).hasClass(ClassName.FADE)) {
        this._isTransitioning = true
      }

      const showEvent = $.Event(Event.SHOW, {
        relatedTarget
      })

      $(this._element).trigger(showEvent)

      if (this._isShown || showEvent.isDefaultPrevented()) {
        return
      }

      if (this._config.fixed) { // xwuikit fixed height modal
        $(this._element).addClass('init-fixed')
        this._element.style.display = 'block'
        this.fixedHeight()
      } else {
        const $thisElement = $(this._element)
        $thisElement.find('.modal-content').css('overflow', 'scroll')
        $thisElement.find('.modal-dialog').css({ 'margin-top': '16px', 'margin-bottom': '16px' })
      }

      this._isShown = true

      $(document.body).addClass(ClassName.OPEN)

      this._setEscapeEvent()

      $(this._element).on(
        Event.CLICK_DISMISS,
        Selector.DATA_DISMISS,
        (event) => this.hide(event)
      )

      $(this._dialog).on(Event.MOUSEDOWN_DISMISS, () => {
        $(this._element).one(Event.MOUSEUP_DISMISS, (event) => {
          if ($(event.target).is(this._element)) {
            this._ignoreBackdropClick = true
          }
        })
      })

      this._showBackdrop(() => this._showElement(relatedTarget))
    }

    hide(event) {
      if (event) {
        event.preventDefault()
      }

      if (this._isTransitioning || !this._isShown) {
        return
      }

      const hideEvent = $.Event(Event.HIDE)

      $(this._element).trigger(hideEvent)

      if (!this._isShown || hideEvent.isDefaultPrevented()) {
        return
      }

      this._isShown = false
      const transition = $(this._element).hasClass(ClassName.FADE)

      if (transition) {
        this._isTransitioning = true
      }

      this._setEscapeEvent()

      $(document).off(Event.FOCUSIN)

      $(this._element).removeClass(ClassName.SHOW)

      if (this._config.fixed) { // xwuikit fixed height modal
        this._setFixedResizeEvent()
      }

      this._setFullScreenResizeEvent()

      $(this._element).off(Event.CLICK_DISMISS)
      $(this._dialog).off(Event.MOUSEDOWN_DISMISS)

      if (transition) {
        const transitionDuration = Util.getTransitionDurationFromElement(this._element)

        $(this._element)
          .one(Util.TRANSITION_END, (event) => this._hideModal(event))
          .emulateTransitionEnd(transitionDuration)
      } else {
        this._hideModal()
      }
    }

    dispose() {
      $.removeData(this._element, DATA_KEY)

      $(window, document, this._element, this._backdrop).off(EVENT_KEY)

      this._config = null
      this._element = null
      this._dialog = null
      this._backdrop = null
      this._isShown = null
      this._isBodyOverflowing = null
      this._ignoreBackdropClick = null
      this._scrollbarWidth = null
    }

    fixedHeight() { // xwuikit fixed height modal
      const $thisElement = $(this._element)
      if ($thisElement.hasClass('show') || $thisElement.hasClass('init-fixed')) {
        $thisElement.removeClass('init-fixed')
        const $thisModalBody = $thisElement.find('.modal-body')
        const $thisModalDialog = $thisElement.find('.modal-dialog')

        $thisModalDialog
          .find('.modal-header')
          .addClass('modal-header-border')

        $thisModalDialog
          .find('.modal-footer')
          .addClass('modal-footer-border')

        if ($(this._element).find(Selector.FULLSCREEN).length === 0) {
          $thisModalDialog.css({
            'margin-top': '16px',
            'margin-bottom': '16px'
          })
        }

        $thisModalBody.removeAttr('style')

        const $thisModalHeader = $thisElement.find('.modal-header')
        const $thisModalFooter = $thisElement.find('.modal-footer')
        const thisModalHeaderHeight = $thisModalHeader.outerHeight()
        const thisModalFooterHeight = $thisModalFooter.outerHeight()
        // eslint-disable-next-line no-magic-numbers
        const marginTopOffset = Number($thisModalDialog.css('margin-top').slice(0, -2))

        // eslint-disable-next-line no-magic-numbers
        const offset = marginTopOffset * 2 + thisModalHeaderHeight + thisModalFooterHeight
        const windowHeight = $(window).height()
        const offsetHeight = windowHeight - offset
        const thisModalBodyHeight = $thisModalBody.outerHeight()
        if (thisModalBodyHeight > offsetHeight) {
          $thisModalBody.css({
            height: `${offsetHeight}px`,
            'overflow-y': 'scroll'
          })
        } else {
          $thisModalDialog.css({
            'margin-top': '0',
            'margin-bottom': '0'
          })

          $thisModalDialog
            .find('.modal-header')
            .removeClass('modal-header-border')

          $thisModalDialog
            .find('.modal-footer')
            .removeClass('modal-footer-border')
        }
      }
    }

    // Private

    _getConfig(config) {
      config = {
        ...Default,
        ...config
      }
      Util.typeCheckConfig(NAME, config, DefaultType)
      return config
    }

    _showElement(relatedTarget) {
      const transition = $(this._element).hasClass(ClassName.FADE)

      if (!this._element.parentNode ||
        this._element.parentNode.nodeType !== Node.ELEMENT_NODE) {
        // Don't move modal's DOM position
        document.body.appendChild(this._element)
      }

      // if (this._isModalTallerThanWindow) {
      //   this._element.style.display = 'block'
      // } else {
      //   this._element.style.display = 'flex'
      // }

      this._element.style.display = 'block'

      this._element.removeAttribute('aria-hidden')
      this._element.scrollTop = 0

      if (transition) {
        Util.reflow(this._element)
      }

      $(this._element).addClass(ClassName.SHOW)

      if (this._config.fixed) { // xwuikit fixed height modal
        this._setFixedResizeEvent()
      }

      this._setFullScreenResizeEvent()

      if (this._config.focus) {
        Util.enforceFocus(this._element)
      }

      const shownEvent = $.Event(Event.SHOWN, {
        relatedTarget
      })

      const transitionComplete = () => {
        if (this._config.focus) {
          this._element.focus()
        }
        this._isTransitioning = false
        $(this._element).trigger(shownEvent)

        const $modalsShowing = $('.xrx-modal.show')
        if ($modalsShowing.length > 1) { // xwuikit nested modal
          $modalsShowing.not(':last').addClass('modal-demoted')
        }
      }

      if (transition) {
        const transitionDuration = Util.getTransitionDurationFromElement(this._element)

        $(this._dialog)
          .one(Util.TRANSITION_END, transitionComplete)
          .emulateTransitionEnd(transitionDuration)
      } else {
        transitionComplete()
      }
    }

    _setEscapeEvent() {
      if (this._isShown && this._config.keyboard) {
        $(this._element).on(Event.KEYDOWN_DISMISS, (event) => {
          if (event.which === ESCAPE_KEYCODE) {
            event.preventDefault()
            this.hide()
          }
        })
      } else if (!this._isShown) {
        $(this._element).off(Event.KEYDOWN_DISMISS)
      }
    }

    _setFixedResizeEvent() { // xwuikit fixed height modal
      if (this._isShown) {
        $(window).on(`resize.${this._id}`, $.proxy(this.fixedHeight, this))
      } else {
        $(window).off(`.${this._id}`)
      }
    }

    _setFullScreenResizeEvent() { // xwuikit
      $(window).on(`resize.${this._id}`, $.proxy(this._fullScreen, this))
      // $(window).off(`.${this._id}`)
    }

    _hasFullScreenOnInit() { // xwuikit set fullscreen at xs breakpoint
      if ($(this._element).find('.modal-dialog').hasClass('modal-fullscreen')) {
        return true
      } else {
        return false
      }
    }

    _fullScreen() { // xwuikit set fullscreen at xs breakpoint
      const $thisModalDialog = $(this._element).find('.modal-dialog')
      if (this._isFullScreen === false) {
        if (matchMedia(`(max-width: 575px)`).matches) {
          $(this._backdrop).addClass('no-backdrop')
          $thisModalDialog.addClass('modal-fullscreen')
        } else {
          $(this._backdrop).removeClass('no-backdrop')
          $thisModalDialog.removeClass('modal-fullscreen')
        }
      }
    }

    _isModalTallerThanWindow() { // xwuikit set fullscreen at xs breakpoint
      if ($(this._element).find('.modal-dialog').outerHeight() > $(window).height()) {
        return true
      } else {
        return false
      }
    }

    _hideModal() {
      this._element.style.display = 'none'
      this._element.setAttribute('aria-hidden', true)
      this._isTransitioning = false
      this._showBackdrop(() => {
        $('.modal-demoted').last().removeClass('modal-demoted') // xwuikit nested modal
        setTimeout(() => {
          $('.xrx-modal.show').not('.modal-demoted').focus()
        }, 200) // eslint-disable-line no-magic-numbers
        if ($('.xrx-modal.show').length === 0) { // xwuikit nested modal
          $(document.body).removeClass(ClassName.OPEN)
        }
        $(this._element).trigger(Event.HIDDEN)
      })
    }

    _removeBackdrop() {
      if (this._backdrop) {
        $(this._backdrop).remove()
        this._backdrop = null
      }
    }

    _showBackdrop(callback) {

      const animate = $(this._element).hasClass(ClassName.FADE) ?
        ClassName.FADE : ''

      if (this._isShown && this._config.backdrop) {
        this._backdrop = document.createElement('div')
        this._backdrop.className = ClassName.BACKDROP
        // xwuikit - if fullscreen, hide the backdrop
        if ($(this._element).find(Selector.FULLSCREEN).length > 0) {
          $(this._backdrop).addClass('no-backdrop')
        } else {
          $(this._backdrop).removeClass('no-backdrop')
        }

        if (animate) {
          $(this._backdrop).addClass(animate)
        }

        $(this._backdrop).appendTo(document.body)

        $(this._element).on(Event.CLICK_DISMISS, (event) => {
          if (this._ignoreBackdropClick) {
            this._ignoreBackdropClick = false
            return
          }
          if (event.target !== event.currentTarget) {
            return
          }
          if (this._config.backdrop === 'static') {
            this._element.focus()
          } else {
            this.hide()
          }
        })

        if (animate) {
          Util.reflow(this._backdrop)
        }

        if ($('.modal-backdrop').length === 1) { // xwuikit nested modal
          $(this._backdrop).addClass(ClassName.SHOW)
        }

        if (!callback) {
          return
        }

        if (!animate) {
          callback()
          return
        }

        const backdropTransitionDuration = Util.getTransitionDurationFromElement(this._backdrop)

        $(this._backdrop)
          .one(Util.TRANSITION_END, callback)
          .emulateTransitionEnd(backdropTransitionDuration)
      } else if (!this._isShown && this._backdrop) {
        $(this._backdrop).removeClass(ClassName.SHOW)

        const callbackRemove = () => {
          this._removeBackdrop()
          if (callback) {
            callback()
          }
        }

        if ($(this._element).hasClass(ClassName.FADE)) {
          const backdropTransitionDuration = Util.getTransitionDurationFromElement(this._backdrop)

          $(this._backdrop)
            .one(Util.TRANSITION_END, callbackRemove)
            .emulateTransitionEnd(backdropTransitionDuration)
        } else {
          callbackRemove()
        }
      } else if (callback) {
        callback()
      }
    }

    // Static

    static _jQueryInterface(config, relatedTarget) {
      return this.each(function () {
        let data = $(this).data(DATA_KEY)
        const _config = {
          ...Default,
          ...$(this).data(),
          ...typeof config === 'object' && config ? config : {}
        }

        if (!data) {
          data = new Modal(this, _config)
          $(this).data(DATA_KEY, data)
        }

        if (typeof config === 'string') {
          if (typeof data[config] === 'undefined') {
            throw new TypeError(`No method named "${config}"`)
          }
          data[config](relatedTarget)
        } else if (_config.show) {
          data.show(relatedTarget)
        }
      })
    }
  }

  /**
   * ------------------------------------------------------------------------
   * Data Api implementation
   * ------------------------------------------------------------------------
   */

  $(document).on(Event.CLICK_DATA_API, Selector.DATA_TOGGLE, function (event) {
    let target
    const selector = Util.getSelectorFromElement(this)

    if (selector) {
      target = $(selector)[0]
    }

    const config = $(target).data(DATA_KEY) ?
      'toggle' : {
        ...$(target).data(),
        ...$(this).data()
      }

    if (this.tagName === 'A' || this.tagName === 'AREA') {
      event.preventDefault()
    }

    const $target = $(target).one(Event.SHOW, (showEvent) => {
      if (showEvent.isDefaultPrevented()) {
        // Only register focus restorer if modal will actually get shown
        return
      }

      $target.one(Event.HIDDEN, () => {
        if ($(this).is(':visible')) {
          this.focus()
        }
      })
    })

    Modal._jQueryInterface.call($(target), config, this)
  })

  /**
   * ------------------------------------------------------------------------
   * jQuery
   * ------------------------------------------------------------------------
   */

  $.fn[NAME] = Modal._jQueryInterface
  $.fn[NAME].Constructor = Modal
  $.fn[NAME].noConflict = function () {
    $.fn[NAME] = JQUERY_NO_CONFLICT
    return Modal._jQueryInterface
  }

  /**
   * ------------------------------------------------------------------------
   * focusin for nested modals -- xwuikit
   * ------------------------------------------------------------------------
   */

  $('.xrx-modal').on('hidden.bs.modal', () => {
    const $modalShowing = $('.xrx-modal.show').not('.modal-demoted')
    if ($modalShowing.length === 1) {
      Util.enforceFocus($modalShowing[0])
    }
  })

  return Modal
})($)

export default Modal
