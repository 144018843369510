import $ from 'jquery'
// import TabFocusUtil from './xrx9x-tab-focus-util'

const Checkbox = (($) => {
  /**
   * ------------------------------------------------------------------------
   * Constants
   * ------------------------------------------------------------------------
   */

  const NAME = 'checkbox'
  const DATA_KEY = 'xrx.checkbox'
  const EVENT_KEY = `.${DATA_KEY}`
  const JQUERY_NO_CONFLICT = $.fn[NAME]

  const Event = {
    CLICK: `click${EVENT_KEY}`,
    CHANGE: `change${EVENT_KEY}`,
  }

  const ClassName = {
    CHECKED: 'checked',
    INDETERMINATE: 'indeterminate',
    XRX_CHECKBOX: 'xrx-checkbox',
    CHECKBOX_SM: 'checkbox-sm',
  }

  const Selector = {
    XRX_CHECKBOX: '.xrx-checkbox',
    LABEL: 'label',
    TYPE_CHECKBOX: '[type="checkbox"]',
    SVG: 'svg',
    BODY: 'body',
    DEFS_CHECKBOX: '#defs-checkbox',
    DEFS_CHECKBOX_SM: '#defs-checkbox-sm',
  }

  const InputProp = {
    DISABLED: 'disabled',
    CHECKED: 'checked',
  }

  const checkboxSvgDefaultDef = `
      <svg id="defs-checkbox" class="svg-defs">
        <defs>
          <symbol id="def-checkbox-outer-focus-ring">
            <path d="M22.5,0 C26.6421356,0 30,3.35786438 30,7.5 L30,22.5 C30,26.6421356 26.6421356,30 22.5,30 L7.5,30 C3.35786438,30 0,26.6421356 0,22.5 L0,7.5 C0,3.35786438 3.35786438,0 7.5,0 L22.5,0 Z M23,7 L7,7 L7,23 L23,23 L23,7 Z"></path>
          </symbol>
          <symbol id="def-checkbox-inner-focus-ring">
            <path d="M23,3 C25.209139,3 27,4.790861 27,7 L27,23 C27,25.209139 25.209139,27 23,27 L7,27 C4.790861,27 3,25.209139 3,23 L3,7 C3,4.790861 4.790861,3 7,3 L23,3 Z M23,7 L7,7 L7,23 L23,23 L23,7 Z"></path>
          </symbol>
          <symbol id="def-checkbox-background">
            <path d="M16.5,0 L1.5,0 C0.671572879,0 0,0.671572879 0,1.5 L0,16.5 C0,17.3284271 0.671572879,18 1.5,18 L16.5,18 C17.3284271,18 18,17.3284271 18,16.5 L18,1.5 C18,0.671572879 17.3284271,0 16.5,0 Z"></path>
          </symbol>
          <symbol id="def-checkbox">
            <path d="M16.5,-1.87500007e-08 L1.49999998,-1.87500007e-08 C0.67157286,-1.87500007e-08 -1.87499154e-08,0.67157286 -1.87499154e-08,1.49999998 L-1.87499154e-08,16.5 C-1.87499154e-08,17.3284271 0.67157286,18 1.49999998,18 L16.5,18 C17.3284271,18 18,17.3284271 18,16.5 L18,1.49999998 C18,0.67157286 17.3284271,-1.87500007e-08 16.5,-1.87500007e-08 Z M16.5,16.5 L1.49999999,16.5 L1.49999999,1.49999999 L16.5,1.49999999 L16.5,16.5 Z"></path>
          </symbol>
          <symbol id="def-checkbox-checkmark">
            <polygon points="11.65 1.275 10.6 0.21 4.125 6.67 1.415 3.96 0.35 5.015 4.125 8.79"></polygon>
          </symbol>
          <symbol id="def-checkbox-indeterminate">
            <polygon points="10 1.75 -1.04166684e-08 1.75 -1.04166684e-08 0.249999998 10 0.249999998"></polygon>
          </symbol>
        </defs>
      </svg>`

  const checkboxSvgDefault = `
      <svg width="48px" height="48px" viewBox="0 0 48 48">
        <g class="svg-checkbox-focus" transform="translate(9.000000, 9.000000)">
          <use class="svg-checkbox-outer-focus-ring" xlink:href="#def-checkbox-outer-focus-ring"></use>
          <use xlink:href="#def-checkbox-inner-focus-ring"></use>
        </g>
        <use class="svg-checkbox-background" transform="translate(15.000000, 15.000000)" xlink:href="#def-checkbox-background"></use>
        <use class="svg-checkbox" transform="translate(15.000000, 15.000000)" xlink:href="#def-checkbox"></use>
        <use class="svg-checkbox-checkmark" transform="translate(18.000000, 19.500000)" xlink:href="#def-checkbox-checkmark"></use>
        <use class="svg-checkbox-indeterminate" transform="translate(19.000000, 23.000000)" xlink:href="#def-checkbox-indeterminate"></use>
      </svg>`

  const checkboxSvgSmallDef = `
      <svg id="defs-checkbox-sm" class="svg-defs">
        <defs>
          <symbol id="def-checkbox-outer-focus-ring-sm">
            <path d="M19.5,0 C23.6421356,0 27,3.35786438 27,7.5 L27,19.5 C27,23.6421356 23.6421356,27 19.5,27 L7.5,27 C3.35786437,27 0,23.6421356 0,19.5 L0,7.5 C0,3.35786438 3.35786437,0 7.5,0 L19.5,0 Z M20,7 L7,7 L7,20 L20,20 L20,7 Z"></path>
          </symbol>
          <symbol id="def-checkbox-inner-focus-ring-sm">
            <path d="M20,3 C22.209139,3 24,4.790861 24,7 L24,20 C24,22.209139 22.209139,24 20,24 L7,24 C4.790861,24 3,22.209139 3,20 L3,7 C3,4.790861 4.790861,3 7,3 L20,3 Z M20,7 L7,7 L7,20 L20,20 L20,7 Z"></path>
          </symbol>
          <symbol id="def-checkbox-background-sm">
            <path d="M13.75,0 L1.25,0 C0.559644064,0 0,0.559644064 0,1.25 L0,13.75 C0,14.440356 0.559644064,15 1.25,15 L13.75,15 C14.440356,15 15,14.440356 15,13.75 L15,1.25 C15,0.559644064 14.440356,0 13.75,0 Z"></path>
          </symbol>
          <symbol id="def-checkbox-sm">
            <path d="M13.75,2.39808173e-14 L1.25,2.39808173e-14 C0.559644064,2.39808173e-14 2.39808173e-14,0.559644064 2.39808173e-14,1.25 L2.39808173e-14,13.75 C2.39808173e-14,14.440356 0.559644064,15 1.25,15 L13.75,15 C14.440356,15 15,14.440356 15,13.75 L15,1.25 C15,0.559644064 14.440356,2.39808173e-14 13.75,2.39808173e-14 Z M13.75,13.75 L1.25,13.75 L1.25,1.25 L13.75,1.25 L13.75,13.75 Z"></path>
          </symbol>
          <symbol id="def-checkbox-checkmark-sm">
            <polygon points="9.41666669 0.887500002 8.54166668 4.4408921e-16 3.14583334 5.38333334 0.887500002 3.12500001 -3.55271368e-15 4.00416668 3.14583334 7.15000001"></polygon>
          </symbol>
          <symbol id="def-checkbox-indeterminate-sm">
            <polygon points="9.16666668 1.625 0.833333325 1.625 0.833333325 0.374999999 9.16666668 0.374999999"></polygon>
          </symbol>
        </defs>
      </svg>`

  const checkboxSvgSmall = `
      <svg width="32px" height="32px" viewBox="0 0 32 32">
        <g class="svg-checkbox-focus" transform="translate(2.500000, 2.500000)">
          <use class="svg-checkbox-outer-focus-ring" xlink:href="#def-checkbox-outer-focus-ring-sm"></use>
          <use xlink:href="#def-checkbox-inner-focus-ring-sm"></use>
        </g>
        <use class="svg-checkbox-background" transform="translate(8.500000, 8.500000)" xlink:href="#def-checkbox-background-sm"></use>
        <use class="svg-checkbox" transform="translate(8.500000, 8.500000)" xlink:href="#def-checkbox-sm"></use>
        <use class="svg-checkbox-checkmark" transform="translate(11.291667, 12.425000)" xlink:href="#def-checkbox-checkmark-sm"></use>
        <use class="svg-checkbox-indeterminate" transform="translate(11.000000, 15.000000)" xlink:href="#def-checkbox-indeterminate-sm"></use>
      </svg>`

  /**
   * ------------------------------------------------------------------------
   * Class Definition
   * ------------------------------------------------------------------------
   */

  class Checkbox {
    constructor(element, config) {
      this._element = element
      this._checkbox = $(element).find(Selector.TYPE_CHECKBOX)[0]
      this._createCheckbox()
      this._svg = $(element).find(Selector.SVG)[0]

      this._addEventListeners()
      this._initState()
    }

    // Public

    setChecked() {
      this.setEnabled()
      this._checkbox.indeterminate = false;
      $(this._checkbox).prop(InputProp.CHECKED, true)
      this._setViewChecked()
    }

    setCheckedChange() {
      this.setChecked()
      $(this._checkbox).change()
    }

    setUnchecked() {
      this.setEnabled()
      this._checkbox.indeterminate = false;
      $(this._checkbox).prop(InputProp.CHECKED, false)
      this._setViewUnchecked()
    }

    setUncheckedChange() {
      this.setUnchecked()
      $(this._checkbox).change()
    }

    setIndeterminate() {
      this.setEnabled()
      this._setViewUnchecked()
      this._checkbox.indeterminate = true;
      $(this._element).addClass(ClassName.INDETERMINATE)
    }

    setDisabled() {
      $(this._checkbox).prop(InputProp.DISABLED, true)
    }

    setEnabled() {
      $(this._checkbox).prop(InputProp.DISABLED, false)
    }

    getDisabled() {
      return $(this._checkbox).prop(InputProp.DISABLED)
    }

    getChecked() {
      return $(this._checkbox).prop(InputProp.CHECKED)
    }

    getIndeterminate() {
      return this._checkbox.indeterminate
    }

    dispose() {
      $.removeData(this._element, DATA_KEY)
      $(this._element).off(EVENT_KEY)
      $(this._checkbox).off(EVENT_KEY)
      $(this._element).find(Selector.LABEL).off(EVENT_KEY)
      $(this._svg).remove()
      this._element = null
      this._checkbox = null
      this._svg = null
    }

    // Private

    _createCheckbox() {
      const $xrxCheckbox = $(this._element)
      if ($xrxCheckbox.hasClass(ClassName.CHECKBOX_SM)) {
        if ($(Selector.DEFS_CHECKBOX_SM).length === 0) {
          $(Selector.BODY).prepend(checkboxSvgSmallDef)
        }
        $xrxCheckbox.append(checkboxSvgSmall)
      } else {
        if ($(Selector.DEFS_CHECKBOX).length === 0) {
          $(Selector.BODY).prepend(checkboxSvgDefaultDef)
        }
        $xrxCheckbox.append(checkboxSvgDefault)
      }
    }

    _initState() {
      if (this.getChecked() === true) {
        this._setViewChecked()
      }
    }

    _addEventListeners() {
      const that = this

      $(that._element).on(Event.CLICK, function () {
        $(this).find(Selector.TYPE_CHECKBOX).click().focus()
      })

      $(that._element).find(Selector.LABEL).on(Event.CLICK, function (e) {
        e.stopPropagation()
        $(this).closest(that._element).find(Selector.TYPE_CHECKBOX).focus()
      })

      $(that._checkbox).on(Event.CLICK, function (e) {
          e.stopPropagation()
        })
        .on(Event.CHANGE, function (e) {
          const $this = $(this);
          const $xrxCheckbox = $this.closest(Selector.XRX_CHECKBOX);
          if (that.getDisabled() !== true) {
            $xrxCheckbox.removeClass(ClassName.INDETERMINATE)
            if (that.getChecked() === true) {
              $xrxCheckbox.addClass(ClassName.CHECKED)
            } else {
              $xrxCheckbox.removeClass(ClassName.CHECKED)
            }
          }
        })
    }

    _setViewUnchecked() {
      $(this._element)
        .removeClass(ClassName.INDETERMINATE)
        .removeClass(ClassName.CHECKED)
    }

    _setViewChecked() {
      $(this._element)
        .removeClass(ClassName.INDETERMINATE)
        .addClass(ClassName.CHECKED)
    }

    // Static

    static _jQueryInterface(config) {
      // methods with return values
      if (typeof config === 'string' && config.substring(0, 3) === 'get') {
        let data = $(this).data(DATA_KEY)
        if (data) {
          return data[config]()
        }
      }
      return this.each(function () {
        let data = $(this).data(DATA_KEY)
        const _config = typeof config === 'object' ? config : null

        if (!data) {
          data = new Checkbox(this, _config)
          $(this).data(DATA_KEY, data)
        }

        if (typeof config === 'string') {
          if (typeof data[config] === 'undefined') {
            throw new TypeError(`No method named "${config}"`)
          }
          data[config]()
        }
      })
    }

  }

  /**
   * ------------------------------------------------------------------------
   * jQuery
   * ------------------------------------------------------------------------
   */

  $.fn[NAME] = Checkbox._jQueryInterface
  $.fn[NAME].Constructor = Checkbox
  $.fn[NAME].noConflict = function () {
    $.fn[NAME] = JQUERY_NO_CONFLICT
    return Checkbox._jQueryInterface
  }

  return Checkbox
})($)

export default Checkbox
