import $ from 'jquery'
// import Xrx9xDropdown from './xrx9x-dropdown'
// import TabFocusUtil from './xrx9x-tab-focus-util'
// import JqueryBaThrottleDebounce from './jquery.ba-throttle-debounce.js'
import XrxDetectMobile from './xrx-detect-mobile.js'

const Select = (($) => {
  /**
   * ------------------------------------------------------------------------
   * Constants
   * ------------------------------------------------------------------------
   */

  const NAME = 'select'
  const DATA_KEY = 'xrx.select'
  const EVENT_KEY = `.${DATA_KEY}`
  const JQUERY_NO_CONFLICT = $.fn[NAME]
  const RETURN_KEYCODE = 13 // KeyboardEvent.which value for return key

  const Event = {
    CLICK: `click${EVENT_KEY}`,
    KEYDOWN: `keydown${EVENT_KEY}`,
    SHOW_DROPDOWN: `show.xrx.dropdown${EVENT_KEY}`,
    HIDDEN_DROPDOWN: `hidden.xrx.dropdown${EVENT_KEY}`,
    FOCUS: `focus${EVENT_KEY}`,
    BLUR: `blur${EVENT_KEY}`,
    CHANGE: `change${EVENT_KEY}`,
    RESIZE: `resize${EVENT_KEY}`,
    TRANSITIONEND: `transitionend${EVENT_KEY}`,
  }

  const ClassName = {
    DISABLED: 'disabled',
    MENU_DOWN: 'menu-down',
    MENU_UP: 'menu-up',
    ACTIVE: 'active',
    VALIDATION_CLASSES: 'select-validation-success select-validation-danger select-validation-warning',
    SELECT_VALIDATION_SUCCESS: 'select-validation-success',
    SELECT_VALIDATION_DANGER: 'select-validation-danger',
    SELECT_VALIDATION_WARNING: 'select-validation-warning',
    SELECT_FOCUS: 'select-focus',
    LABEL_DISABLED: 'label-disabled'
  }

  const Selector = {
    SELECT: 'select',
    XRX_SELECT: '.xrx-select',
    DROPDOWN: '.xrx-dropdown',
    DROPDOWN_ITEMS: '.dropdown-item',
    DROPDOWN_TOGGLE: '.dropdown-toggle',
    DROPDOWN_MENU: '.dropdown-menu',
    LABEL: 'label',
    OPTGROUP: 'optgroup',
    LISTGROUP_ITEM: '.list-group-item',
    ACTIVE: '.active',
    BTN_LABEL: '.btn-label',
    OPTION: 'option',
    ROLE_GROUP: '[role="group"]',
    FORM_TEXT: '.form-text',
    SELECT_BTN_RIGHT_OBJECT: '.select-btn-right-object',
    XRX_STACKED_ICON: '.xrx-stacked-icon',
    DIVIDER: '.divider'
  }

  const Prop = {
    DISABLED: 'disabled',
  }

  const Css = {
    ZINDEX: 'z-index',
    WIDTH: 'width',
  }

  const Data = {
    INDEX: 'index',
    ICON: 'icon',
  }

  const Str = {
    CHOOSE: 'Choose ...',
    SUCCESS: 'success',
    DANGER: 'danger',
    WARNING: 'warning',
    NONE: 'None'
  }

  const Attr = {
    SCROLL: 'scroll',
    DATA_SCROLL: 'data-scroll',
    DATA_INDEX: 'data-index',
    ARIA_DISABLED: 'aria-disabled',
    ARIA_SELECTED: 'aria-selected',
    ARIA_LABELLEDBY: 'aria-labelledby',
    ARIA_HIDDEN: 'aria-hidden',
    TABINDEX: 'tabindex',
    FOR: 'for',
    ID: 'id',
    ARIA_ACTIVEDESCENDANT: 'aria-activedescendant',
    LABEL: 'label',
  }

  const Default = {
    boundary: 'clippingParents', // popper boundary element
  }

  const Popper = {
    TOP: 'top',
    TOP_START: 'top-start',
    TOP_END: 'top-end',
    BOTTOM: 'bottom',
    BOTTOM_START: 'bottom-start',
    BOTTOM_END: 'bottom-end',
  }

  const Html = {
    defaultDropdown: `
      <div class="xrx-dropdown">
        <button class="btn-block dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="listbox" aria-expanded="false">
          <span class="btn-label">${Str.CHOOSE}</span>
          <span class="select-btn-right-object">
            <i class="xgl-sort_down xglsize-24 caret"></i>
          </span>
        </button>
        <div role="listbox" class="dropdown-menu" aria-labelledby="SELECT_LABEL_FOR_ATTR">
        </div>
      </div>`,

    listGroupItem: function (obj) {
      const classes = (obj.classes !== null) ? obj.classes : ''
      const label = (obj.label !== null) ? obj.label : ''
      const icon = (obj.icon !== null) ? obj.icon : ''

      const iconHtml = `
        <div class="left-object">
          <i class="xgl-${icon} xglsize-24"></i>
        </div>`

      return `
        <a role="option" href="#fakelink" class="dropdown-item list-group-item list-group-item-action compact ${classes}">
          ${(obj.icon !== null) ? iconHtml : ''}
          <div class="content-container">
            <div class="left-label"><span>${label}</span></div>
          </div>
        </a>`
    },

    successIcon16: `
      <div class="xrx-stacked-icon stacked-icon-size-16">
        <i class="xgl-success_stack_mdgd xglsize-16"></i>
        <i class="xgl-success_stack_frgd xglsize-16"></i>
      </div>`,

    dangerIcon16: `
      <div class="xrx-stacked-icon stacked-icon-size-16">
        <i class="xgl-danger_stack_mdgd xglsize-16"></i>
        <i class="xgl-danger_stack_frgd xglsize-16"></i>
      </div>`,

    warningIcon16: `
      <div class="xrx-stacked-icon stacked-icon-size-16">
        <i class="xgl-warning_stack_mdgd xglsize-16"></i>
        <i class="xgl-warning_stack_frgd xglsize-16"></i>
      </div>`,

    successIcon24: `
      <div class="xrx-stacked-icon stacked-icon-size-24">
        <i class="xgl-success_stack_mdgd xglsize-24"></i>
        <i class="xgl-success_stack_frgd xglsize-24"></i>
      </div>`,

    dangerIcon24: `
      <div class="xrx-stacked-icon stacked-icon-size-24">
        <i class="xgl-danger_stack_mdgd xglsize-24"></i>
        <i class="xgl-danger_stack_frgd xglsize-24"></i>
      </div>`,

    warningIcon24: `
      <div class="xrx-stacked-icon stacked-icon-size-24">
        <i class="xgl-warning_stack_mdgd xglsize-24"></i>
        <i class="xgl-warning_stack_frgd xglsize-24"></i>
      </div>`,

    divider: `
      <div class="divider">
      </div>`

  }

  /**
   * ------------------------------------------------------------------------
   * Class Definition
   * ------------------------------------------------------------------------
   */

  class Select {
    constructor(element, config) {
      this._element = element
      this._select = $(element).find(Selector.SELECT)[0]
      this._ancestorModal = $(this._element).closest('.xrx-modal')[0]
      this._selectId = $(element).find(Selector.SELECT).attr(Attr.ID)
      // this._forAttrVal = $(element).find(Selector.LABEL).attr(Attr.FOR)
      this._forAttrVal = this._selectId
      this._config = this._getConfig(config)

      this._createSelect()

      this._setMenuScroll()

      this._initState()

      this._initDropdown()

      this._addEventListeners()

      this._setMobile() // _setMobile() also calls _setAriaVisibility()

    }

    // Public

    setInlineValidationSuccess(message) {
      const that = this
      that.setValidationClear()
      $(that._element)
        .addClass(ClassName.SELECT_VALIDATION_SUCCESS)
        .find(Selector.SELECT_BTN_RIGHT_OBJECT)
        .append(Html.divider)
        .append(Html.successIcon24)
      if (message) {
        that._insertHelperTextValidationMessage(message)
      }
    }

    setInlineValidationDanger(message) {
      const that = this
      that.setValidationClear()
      $(that._element)
        .addClass(ClassName.SELECT_VALIDATION_DANGER)
        .find(Selector.SELECT_BTN_RIGHT_OBJECT)
        .append(Html.divider)
        .append(Html.dangerIcon24)
      if (message) {
        that._insertHelperTextValidationMessage(message)
      }
    }

    setInlineValidationWarning(message) {
      const that = this
      that.setValidationClear()
      $(that._element)
        .addClass(ClassName.SELECT_VALIDATION_WARNING)
        .find(Selector.SELECT_BTN_RIGHT_OBJECT)
        .append(Html.divider)
        .append(Html.warningIcon24)
      if (message) {
        that._insertHelperTextValidationMessage(message)
      }
    }

    setHelperValidationSuccess(message) {
      const that = this
      that.setValidationClear()
      $(that._element)
        .addClass(ClassName.SELECT_VALIDATION_SUCCESS)
        .find(Selector.SELECT_BTN_RIGHT_OBJECT)
      that._insertHelperTextValidationMessage(message, Str.SUCCESS)
    }

    setHelperValidationDanger(message) {
      const that = this
      that.setValidationClear()
      $(that._element)
        .addClass(ClassName.SELECT_VALIDATION_DANGER)
        .find(Selector.SELECT_BTN_RIGHT_OBJECT)
      that._insertHelperTextValidationMessage(message, Str.DANGER)
    }

    setHelperValidationWarning(message) {
      const that = this
      that.setValidationClear()
      $(that._element)
        .addClass(ClassName.SELECT_VALIDATION_WARNING)
        .find(Selector.SELECT_BTN_RIGHT_OBJECT)
      that._insertHelperTextValidationMessage(message, Str.WARNING)
    }

    setValidationClear() {
      $(this._element)
        .removeClass(ClassName.VALIDATION_CLASSES)
        .find(`
          ${Selector.XRX_STACKED_ICON},
          ${Selector.DIVIDER},
          ${Selector.FORM_TEXT}`)
        .remove()
    }

    _insertHelperTextValidationMessage(message, type) {
      const formText = `
        <div class="form-text">
          <div class="message">${message}</div>
        </div>`
      this.setHelperTextClear()
      $(this._element).append(formText)
      const $formText = $(this._element).find(Selector.FORM_TEXT)

      if (type) {
        if (type === Str.SUCCESS) {
          $formText.prepend(Html.successIcon16)
        } else if (type === Str.DANGER) {
          $formText.prepend(Html.dangerIcon16)
        } else if (type === Str.WARNING) {
          $formText.prepend(Html.warningIcon16)
        }
      }
    }

    setHelperText(message) {
      this.setHelperTextClear()
      $(this._element)
        .addClass('form-helper-text')
        .after(`<div class="form-text">${message}</div>`)

        // maybe we need getDisabled method?
      if ($(this._select).prop('disabled')) {
        $(this._element).next('.form-text').addClass('form-text-disabled')
      }
    }

    setHelperTextClear() {
      $(this._element).next(Selector.FORM_TEXT).remove()
    }

    setSelected(optionIndex) {
      const listGroupItem = $(this._element).find(`[data-index="${optionIndex}"]`)[0]
      this._setViewSelectedItem.call(listGroupItem, null, this)
      this._setSelectSelectedItem.call(listGroupItem, null, this, false)
      this._setButtonLabel.call(listGroupItem, null, this)
    }

    setSelectedChange(optionIndex) {
      const listGroupItem = $(this._element).find(`[data-index="${optionIndex}"]`)[0]
      this._setViewSelectedItem.call(listGroupItem, null, this)
      this._setSelectSelectedItem.call(listGroupItem, null, this, true)
      this._setButtonLabel.call(listGroupItem, null, this)
    }

    setOptionDisabled(optionIndex) {
      const $listGroupItem = $(this._element).find(`[data-index="${optionIndex}"]`)
      $listGroupItem.addClass(ClassName.DISABLED).attr(Attr.ARIA_DISABLED, 'true')
      $(this._select).find(Selector.OPTION).eq(optionIndex).prop(Prop.DISABLED, true)
    }

    setOptionEnabled(optionIndex) {
      const $listGroupItem = $(this._element).find(`[data-index="${optionIndex}"]`)

      const isParentOptGroupDisabled = $(this._select)
        .find(Selector.OPTION)
        .eq(optionIndex)
        .closest(Selector.OPTGROUP)
        .prop(Prop.DISABLED)

      if (isParentOptGroupDisabled) return

      $listGroupItem.removeClass(ClassName.DISABLED).removeAttr(Attr.ARIA_DISABLED)
      $(this._select).find(Selector.OPTION).eq(optionIndex).prop(Prop.DISABLED, false)
    }

    setOptionGroupDisabled(optGroupIndex) {
      const that = this
      const $optGroup = $(this._element).find(Selector.ROLE_GROUP).eq(optGroupIndex)

      $(this._select).find(Selector.OPTGROUP).eq(optGroupIndex).prop(Prop.DISABLED, true)
      $optGroup.attr(Attr.ARIA_DISABLED, 'true')

      // set all options (.list-group-item) disabled within the option group
      $optGroup.find(Selector.LISTGROUP_ITEM).each(function () {
        const dataIndex = $(this).data(Data.INDEX)
        that.setOptionDisabled(dataIndex)
      })
    }

    setOptionGroupEnabled(optGroupIndex) {
      const that = this
      const $optGroup = $(this._element).find(Selector.ROLE_GROUP).eq(optGroupIndex)

      $(this._select).find(Selector.OPTGROUP).eq(optGroupIndex).prop(Prop.DISABLED, false)
      $optGroup.removeAttr(Attr.ARIA_DISABLED)

      // set all options (.list-group-item) enabled within the option group
      $optGroup.find(Selector.LISTGROUP_ITEM).each(function () {
        const dataIndex = $(this).data(Data.INDEX)
        that.setOptionEnabled(dataIndex)
      })
    }

    setDisabled() {
      const $xrxSelect = $(this._element)
      $xrxSelect.addClass(ClassName.DISABLED)
      $xrxSelect.find(Selector.DROPDOWN_TOGGLE).prop(Prop.DISABLED, true)
      $(this._select).prop(Prop.DISABLED, true)
      $(`[for="${this._forAttrVal}"]`).addClass(ClassName.LABEL_DISABLED)
      $(`[id="label-${this._forAttrVal}"]`).addClass(ClassName.LABEL_DISABLED)
      $xrxSelect.next('.form-text').addClass('form-text-disabled')
    }

    setEnabled() {
      const $xrxSelect = $(this._element)
      $xrxSelect.removeClass(ClassName.DISABLED)
      $xrxSelect.find(Selector.DROPDOWN_TOGGLE).prop(Prop.DISABLED, false)
      $(this._select).prop(Prop.DISABLED, false)
      $(`[for="${this._forAttrVal}"]`).removeClass(ClassName.LABEL_DISABLED)
      $(`[id="label-${this._forAttrVal}"]`).removeClass(ClassName.LABEL_DISABLED)
      $xrxSelect.next('.form-text').removeClass('form-text-disabled')
    }

    getSelected() {
      return this._select.options[this._select.selectedIndex]
    }

    getDisabled() {
      return $(this._select).prop(Prop.DISABLED)
    }

    getOptionDisabled(optionIndex) {
      return $(this._select).find(Selector.OPTION).eq(optionIndex).prop(Prop.DISABLED)
    }

    getOptionGroupDisabled(optGroupIndex) {
      return $(this._select).find(Selector.OPTGROUP).eq(optGroupIndex).prop(Prop.DISABLED)
    }

    update() {
      const $xrxSelect = $(this._element)
      // teardown
      $(window).off(`.mobile.${this._selectId}`)
      $xrxSelect.find(Selector.DROPDOWN_ITEMS).off(EVENT_KEY)
      $xrxSelect.find(Selector.DROPDOWN_MENU).off(EVENT_KEY)
      $xrxSelect.find(Selector.DROPDOWN).off(EVENT_KEY)
      $(this._select).off(EVENT_KEY)
      $xrxSelect.find(Selector.DROPDOWN_TOGGLE).dropdown('dispose')
      $xrxSelect.find(Selector.DROPDOWN).remove()
      // re-build
      this._createSelect()
      this._setMenuScroll()
      this._initState()
      this._initDropdown()
      this._addEventListeners()
      this._setMobile()
    }

    dispose() {
      const $xrxSelect = $(this._element)

      $.removeData(this._element, DATA_KEY)

      $(this._ancestorModal).off('shown.xrx.modal.select-update')
      $(window).off(`.mobile.${this._selectId}`)
      $xrxSelect.find(Selector.DROPDOWN_ITEMS).off(EVENT_KEY)
      $xrxSelect.find(Selector.DROPDOWN_MENU).off(EVENT_KEY)
      $xrxSelect.find(Selector.DROPDOWN).off(EVENT_KEY)
      $(this._select).off(EVENT_KEY)
      $xrxSelect.find(Selector.DROPDOWN_TOGGLE).dropdown('dispose')
      $xrxSelect.find(Selector.DROPDOWN).remove()
      $xrxSelect.find(Selector.FORM_TEXT).remove()
      $xrxSelect.removeClass(ClassName.VALIDATION_CLASSES)
      this._element = null
      this._select = null
      this._selectId = null
      this._forAttrVal = null
      this._config = null

    }

    // Private

    _dectectOptGroup() {
      return $(this._select).find(Selector.OPTGROUP).length > 0
    }

    _createSelect() {
      const that = this;
      const $xrxSelect = $(this._element)

      // $(Html.defaultDropdown).insertAfter($xrxSelect.find(Selector.LABEL))
      $xrxSelect.prepend(Html.defaultDropdown)
      const $dropdownMenu = $xrxSelect.find(Selector.DROPDOWN_MENU)
      $dropdownMenu.append(this._buildListGroupItems())

      // set data-index on .list-group-item
      $dropdownMenu.find(Selector.LISTGROUP_ITEM).each(function (index) {
        const $this = $(this)
        $this.attr(Attr.DATA_INDEX, index)
        $this.attr(Attr.ID, `option-${that._selectId}-${index}`)
      })

    }

    _initDropdown() {
      const that = this

      const popperConfig = {
        placement: Popper.BOTTOM_START,
        modifiers: [{
          name: 'offset',
          options: {
            offset: [0, 0],
          },
        }, {
          name: 'preventOverflow',
          options: {
            boundary: this._config.boundary,
            padding: 8,
          },
        }, {
          name: 'arrow',
          options: {
            padding: 8, // 5px from the edges of the popper
          },
        }, {
          name: 'getMenuPosition',
          enabled: true,
          phase: 'main',
          fn({ state }) {
            if (state.modifiersData.hide.isReferenceHidden === false) {
              const sp = state.placement
              const cl = that._element.querySelectorAll(Selector.DROPDOWN)[0].classList
              if (sp === Popper.TOP || sp === Popper.TOP_START || sp === Popper.TOP_END) {
                cl.remove(ClassName.MENU_DOWN)
                cl.add(ClassName.MENU_UP)
              } else if (sp === Popper.BOTTOM || sp === Popper.BOTTOM_START || sp === Popper.BOTTOM_END) {
                cl.remove(ClassName.MENU_UP)
                cl.add(ClassName.MENU_DOWN)
              }
            }
          },
        }],
      }

      // init dropdown
      $(this._element).find(Selector.DROPDOWN_TOGGLE).dropdown({ popperConfig: popperConfig })

      // const data = $(this._element).find(Selector.DROPDOWN_TOGGLE).data('xrx.dropdown')
      // console.log(data)

    }

    _buildListGroupItems() {
      const that = this
      const $select = $(this._select)

      function buildOptions($el) {
        let html = ''
        $el.find(Selector.OPTION).each(function (index) {
          const $this = $(this)

          html += Html.listGroupItem({
            classes: null,
            label: $this.text(),
            icon: ($this.data(Data.ICON)) ? $this.data(Data.ICON) : null
          })
        })

        return html
      }

      // build the list group items from the select options

      const OPTION = 'OPTION'
      const OPTGROUP = 'OPTGROUP'
      let html = ''
      let prevEl = ''

      html += `<div class="multi-list-group">`

      $select.children().each(function (index) {
        const $this = $(this)
        if ($this[0].tagName === OPTION) {

          if (prevEl === '' || prevEl === OPTGROUP) html += `<div class="xrx-list-group list-group-flush">`

          html += Html.listGroupItem({
            classes: null,
            label: $this.text(),
            icon: ($this.data(Data.ICON)) ? $this.data(Data.ICON) : null
          })

        } else if ($this[0].tagName === OPTGROUP) {

          if (prevEl === OPTION) html += `</div>`

          const l = $(this).attr(Attr.LABEL)
          const optgroupLabel = (l === '' || l === undefined) ? `Group ${index+1}` : l
          html += `<div class="xrx-list-group list-group-flush" role="group" aria-labelledby="optgroup-${that._selectId}-${optgroupLabel.toLowerCase()}">`
          html += `<div id="optgroup-${that._selectId}-${optgroupLabel.toLowerCase()}" class="list-group-header"><span class="label" role="presentation">${optgroupLabel}</span></div>`
          html += buildOptions($(this))
          html += `</div>`

        }

        prevEl = $this[0].tagName

      })

      if (prevEl === OPTION) html += `</div>`

      html += `</div>`

      return html

    }

    _setMenuScroll() {
      const $xrxSelect = $(this._element)
      const $menu = $(this._element).find(Selector.DROPDOWN_MENU)
      let itemsToScroll = 4

      if ($xrxSelect.data(Attr.SCROLL)) {
        itemsToScroll = $xrxSelect.data(Attr.SCROLL)
      } else {
        if ($menu.closest('.xrx-modal').length > 0) {
          itemsToScroll = 3
        }
      }

      $menu.attr(Attr.DATA_SCROLL, itemsToScroll)

    }

    _addEventListeners() {
      const that = this;

      // if the select is in a modal, this will update it properly
      if (this._ancestorModal) {
        $(this._ancestorModal)
          .off('shown.xrx.modal.select-update')
          .on('shown.xrx.modal.select-update', function (e) {
            that.update()
            $(this).find('.xrx-dropdown .dropdown-toggle').dropdown('update')
          })
      }

      const $dropdownItems = $(this._element).find(Selector.DROPDOWN_ITEMS)
      $dropdownItems.on(Event.CLICK, function (e) {
        if ($(this).hasClass(ClassName.DISABLED) === false) {
          that._setViewSelectedItem.call(this, e, that)
          that._setSelectSelectedItem.call(this, e, that, true)
          that._setButtonLabel.call(this, e, that)
        }
        $(that._element).find(Selector.DROPDOWN_TOGGLE).focus()
      })

      this._setMenuWidth()

    }

    _initState() {
      const $xrxSelect = $(this._element)
      if ($xrxSelect.hasClass(ClassName.DISABLED) || $(this._select).prop(Prop.DISABLED)) {
        this.setDisabled()
      }
      this._setOptionDisabledOnInit()
      this._setOptionGroupDisabledOnInit()
      this._setOptionSelectedOnInit()

    }

    _setOptionDisabledOnInit() {
      const that = this;
      $(this._select).find(Selector.OPTION).each(function (index) {
        if ($(this).prop(Prop.DISABLED)) {
          that.setOptionDisabled(index)
        }
      })
    }

    _setOptionGroupDisabledOnInit() {
      const that = this;
      $(this._select).find(Selector.OPTGROUP).each(function (index) {
        if ($(this).prop(Prop.DISABLED)) {
          // $(that._element).find(Selector.ROLE_GROUP).eq(index).addClass(ClassName.DISABLED).attr(Attr.ARIA_DISABLED, 'true')
          that.setOptionGroupDisabled(index)
        }
      })
    }

    _setOptionSelectedOnInit() {
      const selectedIndex = this._select.selectedIndex
      const $listGroupItem = $(this._element).find(Selector.LISTGROUP_ITEM)[selectedIndex]
      this._setViewSelectedItem.call($listGroupItem, null, this)
      this._setButtonLabel.call($listGroupItem, null, this)
    }

    _setViewSelectedItem(e, that) {
      const $this = $(this)
      const $dropdownMenu = $this.closest(Selector.DROPDOWN_MENU)
      $dropdownMenu.find(Selector.LISTGROUP_ITEM).removeClass(ClassName.ACTIVE).removeAttr(Attr.ARIA_SELECTED)
      $this.addClass(ClassName.ACTIVE).attr(Attr.ARIA_SELECTED, 'true')
      const activeOptionId = $this.attr(Attr.ID)
      $dropdownMenu.attr(Attr.ARIA_ACTIVEDESCENDANT, `${activeOptionId}`)
    }

    _setSelectSelectedItem(e, that, fireChange) {
      const dataIndex = $(this).closest(Selector.DROPDOWN_MENU).find(Selector.ACTIVE).data(Data.INDEX)
      const optionsLength = that._select.options.length
      let selectedIndex = that._select.selectedIndex

      // set the select selected option
      for (let i = 0; i < optionsLength; i++) {
        if (that._select.options[i].index === dataIndex) {
          that._select.options[i].selected = true
          // console.log('previous selected', selectedIndex)
          // console.log('now selected', that._select.selectedIndex)
          if (selectedIndex !== that._select.selectedIndex) {
            if (fireChange) {
              $(that._select).change()
            }
          }
          break
        }
      }

    }

    _setButtonLabel(e, that) {
      const $btnLabel = $(that._element).find(Selector.BTN_LABEL)
      let selectedOptionIcon = ''
      let selectedOptionLabel = ''

      // gather the info
      if (that._select.options[that._select.selectedIndex].value === '') {
        $btnLabel.find('[class*="xgl-"]').remove()
        selectedOptionLabel = Str.CHOOSE
        $btnLabel.addClass('placeholder-style')
      } else {
        selectedOptionIcon = $(that._select.options[that._select.selectedIndex]).data(Data.ICON)
        selectedOptionLabel = that._select.options[that._select.selectedIndex].innerHTML
        $btnLabel.removeClass('placeholder-style')
      }

      // insert the info
      if (selectedOptionIcon) {
        const html = `<i class="xgl-${selectedOptionIcon} xglsize-24"></i><span>${selectedOptionLabel}</span>`
        $btnLabel.html(html)
      } else {
        $btnLabel.text('').text(selectedOptionLabel)
      }

    }

    _setMenuWidth() {
      const $xrxSelect = $(this._element)
      $xrxSelect.find(Selector.DROPDOWN)
        .on(Event.SHOW_DROPDOWN, function () {
          const $dropdownToggle = $xrxSelect.find(Selector.DROPDOWN_TOGGLE)
          const buttonWidth = $dropdownToggle.outerWidth()
          const menuWidth = $xrxSelect.find(Selector.DROPDOWN_MENU).outerWidth()
          if (menuWidth <= buttonWidth) {
            $(this).find(Selector.DROPDOWN_MENU).css(Css.WIDTH, buttonWidth)
          }
          // $xrxSelect.css(Css.ZINDEX, '9999999')
          // $dropdownToggle.css(Css.ZINDEX, '9999')
        })
      // .on(Event.HIDDEN_DROPDOWN, function () {
      //   $xrxSelect.find(Selector.DROPDOWN_MENU).one(Event.TRANSITIONEND, function () {
      //     $xrxSelect.css(Css.ZINDEX, '1')
      //   })
      // })

    }

    _setAriaVisibility() {
      // when in mobile mode screen reader will read the <select>
      // when in desktop mode screen reader will read the .dropdown-menu

      const $xrxSelect = $(this._element)
      const $select = $(this._select)
      const $label = $(`[for="${this._forAttrVal}"]`)
      const $dropdownToggle = $xrxSelect.find(Selector.DROPDOWN_TOGGLE)

      // reset
      $xrxSelect.find(Selector.DROPDOWN_MENU).removeAttr(Attr.ARIA_LABELLEDBY)
      $dropdownToggle.removeAttr(Attr.TABINDEX)
      $xrxSelect.find(Selector.DROPDOWN).removeAttr(Attr.ARIA_HIDDEN)
      // $label.attr(Attr.FOR, this._forAttrVal)
      $select.removeAttr(Attr.TABINDEX)
      $select.removeAttr(Attr.ARIA_HIDDEN)

      if (XrxDetectMobile()) { // mobile mode

        // set tabindex="-1" on .dropdown-toggle
        $dropdownToggle.attr(Attr.TABINDEX, '-1')

        // set aria-hidden on .dropdown
        $xrxSelect.find(Selector.DROPDOWN).attr(Attr.ARIA_HIDDEN, 'true')

      } else { // desktop mode
        // set aria-labelledby for dropdown - set id on <label> using "for" attr val
        $label.attr(Attr.ID, `label-${this._forAttrVal}`)
        $xrxSelect.find(Selector.DROPDOWN_MENU).attr(Attr.ARIA_LABELLEDBY, `label-${this._forAttrVal}`)

        // remove "for" attribute
        $label.removeAttr(Attr.FOR)

        // set tabindex="-1" on <select>
        $select.attr(Attr.TABINDEX, '-1')
        $dropdownToggle.removeAttr(Attr.TABINDEX)

        // set aria-hidden on <select>
        $select.attr(Attr.ARIA_HIDDEN, 'true')
      }

    }

    _setMobile() {
      const that = this
      const DEBOUNCE_MS = 300;

      function mobile(thisSelectEl) {

        that._setAriaVisibility()

        const $xrxSelect = $(that._element)
        const $select = $(that._select)
        const $dropdownToggle = $xrxSelect.find(Selector.DROPDOWN_TOGGLE)

        // reset - make select unclickable
        $select.css({
          'visibility': 'hidden',
        })

        // reset focus, blur, change
        $select.off('.mobile')

        // reset - get dropdown instance and scroll the menu
        $dropdownToggle.data('xrx.dropdown').updateMenuScroll()

        if (XrxDetectMobile()) { // if mobile, then use navtive select

          // set opacity on the navtive select and set it above/on top of the .dropdown-toggle (select button)

          $dropdownToggle.css('z-index', '1')

          const topOffset = $dropdownToggle.offset().top - $(that._element).offset().top

          $select.css({
            'visibility': 'visible',
            'width': $dropdownToggle.outerWidth(),
            'height': $dropdownToggle.outerHeight(),
            'top': topOffset,
            'z-index': '1',
            // 'opacity': '1' // for testing
          })

          // set focus state - map to focused button (.dropdown-toggle) visual
          $select.on(`${Event.FOCUS}.mobile`, function () {
              $xrxSelect.addClass(ClassName.SELECT_FOCUS)
            })
            .on(`${Event.BLUR}.mobile`, function () {
              $xrxSelect.removeClass(ClassName.SELECT_FOCUS)
            })
            .on(`${Event.CHANGE}.mobile`, function (e) {
              const listGroupItem = $(that._element).find(`[data-index="${that._select.selectedIndex}"]`)[0]
              that._setViewSelectedItem.call(listGroupItem, null, that)
              that._setButtonLabel.call(this, e, that)
            })

        }

      }

      // on document load
      mobile(this._element)

      // on resize
      $(window).on(`${Event.RESIZE}.mobile.${this._selectId}`, $.debounce(DEBOUNCE_MS, () => {
        mobile(this._element)
      }))

    }

    _getConfig(config) {
      config = {
        ...this.constructor.Default,
        ...$(this._element).data(),
        ...config
      }

      return config
    }

    // Static

    static _jQueryInterface(config, param) {
      // methods with return values
      if (typeof config === 'string' && config.substring(0, 3) === 'get') {
        let data = $(this).data(DATA_KEY)
        if (data) {
          if (config === 'getOptionDisabled' ||
            'getOptionGroupDisabled') {
            return data[config](param)
          } else {
            return data[config]()
          }
        }
      }
      return this.each(function () {
        let data = $(this).data(DATA_KEY)
        const _config = typeof config === 'object' ? config : null

        if (!data) {
          data = new Select(this, _config)
          $(this).data(DATA_KEY, data)
        }

        if (typeof config === 'string') {
          if (typeof data[config] === 'undefined') {
            throw new TypeError(`No method named "${config}"`)
          }

          if (param !== undefined) {
            data[config](param)
          } else {
            data[config]()
          }
        }
      })
    }

  }

  /**
   * ------------------------------------------------------------------------
   * jQuery
   * ------------------------------------------------------------------------
   */

  $.fn[NAME] = Select._jQueryInterface
  $.fn[NAME].Constructor = Select
  $.fn[NAME].noConflict = function () {
    $.fn[NAME] = JQUERY_NO_CONFLICT
    return Select._jQueryInterface
  }

  return Select
})($)

export default Select
