const XrxPxToRem = (() => {
  const publicObj = {

    calRem: (pxVal, pxBase) => {
      const remVal = pxVal / pxBase
      return remVal
    },

    remIt: (rmVal) => {
      const remStr = `${rmVal}rem`
      return remStr
    }

  }

  return publicObj
})()

export default XrxPxToRem
