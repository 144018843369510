import $ from 'jquery'

const AlphaIndex = (($) => {
  /**
   * ------------------------------------------------------------------------
   * Constants
   * ------------------------------------------------------------------------
   */

  const NAME = 'alphaindex'
  const VERSION = '1.0.0'
  const DATA_KEY = 'xrx.alphaindex'
  const EVENT_KEY = `.${DATA_KEY}`
  const DATA_API_KEY = '.data-api'
  const JQUERY_NO_CONFLICT = $.fn[NAME]

  const Default = {
    alpha_index_offset_top: 0,
    numbers_index: false
  }

  /**
   * ------------------------------------------------------------------------
   * Class Definition
   * ------------------------------------------------------------------------
   */

  class AlphaIndex {
    constructor(element, config) {
      this._element = element
      this.$notifier = null
      this.$alpha_index = null
      this.$alpha_index_ul = null
      this.$index_container = null
      this.$scroll_container = null
      this.replaced = ['c', 'g', 'k', 'o', 's', 'w']
      this._config = this._getConfig(config)

      this._init()
      this._addEventListeners()
    }

    // Getters

    // static get VERSION() {
    //   return VERSION
    // }

    // Public

    dispose() {
      $.removeData(this._element, DATA_KEY)
      $(window, this._element).off(EVENT_KEY)

      this.$notifier.remove()
      this.$alpha_index.remove()
      this.$index_container.remove()

      this._element = null
      this.$notifier = null
      this.$alpha_index = null
      this.$alpha_index_ul = null
      this.$index_container = null
      this.$scroll_container = null
    }

    // Private

    _getConfig(config) {
      config = {
        ...Default,
        ...config
      }
      return config
    }

    _init() {
      const that = this;

      const $element = $(that._element)

      $element.css('padding-top', that._config.alpha_index_offset_top + 'px');

      that._buildAlphaIndex();
      that._buildFeedbackNotifier();

      that.$notifier = $element.find('#centered-index-notifier')
      that.$alpha_index = $element.find('#alpha-index')
      that.$alpha_index_ul = that.$alpha_index.find('ul')
      that.$index_container = $element.find('#alpha-index-position-container')
      that.$scroll_container = $element.find('.scroll-container')

      that._responsive()

      that._positionAlphaIndex();
      that._positionIndexNotifier();

    }

    _addEventListeners() {
      const that = this

      // bind touch events to alpha-index
      that.$alpha_index.on(`touchstart${EVENT_KEY}`, function () {

        that.$alpha_index.on(`touchmove${EVENT_KEY}`, function (e) {

            // show alpha-index background
            that.$alpha_index_ul.addClass('active-index');

            e.preventDefault();

            var touch = e.originalEvent.touches[0] || e.originalEvent.changedTouches[0];

            // scroll to index
            that._scrollIndex(touch.pageY);
          })
          .on(`touchend${EVENT_KEY}`, function (e) {

            // hide alpha-index background
            that.$alpha_index_ul.removeClass('active-index');

            // hide the index notifier after touchend & set amount of time
            that._hideFeedbackNotifier(500);

            that.$alpha_index.off(`touchmove${EVENT_KEY}`);
            that.$alpha_index.off(`touchend${EVENT_KEY}`);

          });

      });

      // bind mouse events to alpha-index
      that.$alpha_index.on(`mousedown${EVENT_KEY}`, function (e) {

        that._scrollIndex(e.pageY);

        // attach the mousemove event
        $(this).on(`mousemove${EVENT_KEY}`, function (e) {
          // scroll to id position on mousemove
          that._scrollIndex(e.pageY);
        });

        // return page to normal functioning after mouseup
        $('body').on(`mouseup${EVENT_KEY}`, function () {

          // that._scrollIndex(e.pageY);

          // hide the index notifier after mouseup & set amount of time
          that._hideFeedbackNotifier(500);

          // detach the mousemove event
          that.$alpha_index.off(`mousemove${EVENT_KEY}`);
          $('body').off(`mouseup${EVENT_KEY}`);

        });

      });

      that.$alpha_index.on(`mouseleave${EVENT_KEY}`, function () {
        // hide the index notifier after mouseup & set amount of time
        that._hideFeedbackNotifier(500);

        // detach the mousemove event
        that.$alpha_index.off(`mousemove${EVENT_KEY}`);
        $('body').off(`mouseup${EVENT_KEY}`);
      });

      that.$alpha_index.hover(
        function () {
          $('body').addClass('no-text-select');
        },
        function () {
          $('body').removeClass('no-text-select');
        }
      );

    }

    // build the alpha-index postion container on init
    _buildAlphaIndexPositionContainer() {

      var position_container = '<div id="alpha-index-position-container"><div class="inner"></div></div>';

      // insert the html into the document
      $('.alpha-index').append(position_container);

    }

    // build the alpha-index on init
    _buildAlphaIndex() {
      const that = this

      var wrapper_html = '<div id="alpha-index"><ul></ul></div>';

      var hash_html = '<li><div id="index-#H">&#x23;</div></li>';

      var index_letters = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];

      // build index html to be inserted
      var uc_letter = '';
      var index_html = '';
      $.each(index_letters, function (index, value) {
        uc_letter = value.toUpperCase();
        index_html = index_html + '<li><div id="index-' + uc_letter + value + '">' + uc_letter + '</div></li>';
      });

      // insert the html into the document
      // $('body').append(wrapper_html);
      $(that._element).append(wrapper_html);
      if (that._config.numbers_index) {
        $('#alpha-index ul').append(hash_html, index_html);
      } else {
        $('#alpha-index ul').append(index_html);
      }

    }

    // build the feedback notifier
    _buildFeedbackNotifier() {
      const that = this

      var feedback_notifier_html = '<div id="centered-index-notifier"></div>';

      // insert the html into the document
      // $('body').append(feedback_notifier_html);
      $(that._element).append(feedback_notifier_html);

    }

    _positionIndexNotifier() {
      // center the notifier
      const $in = $('#centered-index-notifier')
      const alphaIndexContainerHeightHalf = $('.alpha-index').outerHeight() / 2
      const alphaIndexContainerWidthHalf = $('.alpha-index').outerWidth() / 2
      const indexNotifierHeightHalf = $in.outerHeight() / 2
      const indexNotifierWidthHalf = $in.outerWidth() / 2

      const indexNotifierTop = alphaIndexContainerHeightHalf - indexNotifierHeightHalf
      const indexNotifierLeft = alphaIndexContainerWidthHalf - indexNotifierWidthHalf

      $in.css({
        'top': indexNotifierTop,
        'left': indexNotifierLeft,
      })
    }

    _positionAlphaIndex() {
      const $ai = $('#alpha-index')
      const alphaIndexContainerHeightHalf = $('.alpha-index').outerHeight() / 2
      const alphaIndexHeightHalf = $ai.outerHeight() / 2

      // console.log(alphaIndexContainerHeightHalf)
      // console.log(alphaIndexHeightHalf)

      const alphaIndexTop = alphaIndexContainerHeightHalf - alphaIndexHeightHalf

      $ai.css({ 'top': alphaIndexTop })

    }

    // set the alpha-index to small condensed version
    _smDeviceAlphaIndex() {
      const that = this

      var excluded = ['b', 'd', 'f', 'h', 'j', 'l', 'n', 'p', 'r', 't', 'u', 'x', 'y'];

      // letters to hide
      $.each(excluded, function (index, value) {
        $('#index-' + value.toUpperCase() + value).addClass('hide-index-letter');
      });

      // replace these letters with a dot
      $.each(that.replaced, function (index, value) {
        $('#index-' + value.toUpperCase() + value).html('&#x2022;').addClass('dot-size');
      });

    }

    // reset the alpha-index to full version
    _restoreLgDeviceAlphaIndex() {
      const that = this

      $('[id^="index-"]').removeClass('hide-index-letter');

      // replace these letters with a dot
      var uc_letter = '';
      $.each(that.replaced, function (index, value) {
        uc_letter = value.toUpperCase();
        $('#index-' + uc_letter + value).html(uc_letter).removeClass('dot-size');
      });

    }

    // hide the feedback notifier (letter picked indicator) after a delay of milliseconds
    // @parameter -- int -- milliseconds
    _hideFeedbackNotifier(time_ms) {
      const that = this
      setTimeout(function () {
        that.$notifier.removeClass('show-notifier');
      }, time_ms);
    }

    // scroll the index
    // @parameter -- int -- mouse y-position
    _scrollIndex(y) {
      const that = this

      // search the alpha-index to find the current position of the mouse
      $('[id^="index-"]').each(function () {

        var $this = $(this),
          top = $this.offset().top;
        // top = $this.position().top;

        if (!(y <= top || y >= top + $this.outerHeight())) {

          var index_letter = $this.attr('id').slice(-1),
            label = $this.attr('id').slice(6, -1);

          var elementToScroll = $('#' + index_letter + '-index')[0]

          if (elementToScroll) {

            // show the index letter notifier
            that.$notifier.text(label)
            that.$notifier.addClass('show-notifier')

            // scroll to the id position
            const scrollTop = that.$scroll_container.scrollTop()
            that.$scroll_container.scrollTop($('#' + index_letter + '-index').position().top + scrollTop);
          }

          // once found, discontinue the search
          return false;

        }

      });

    }

    _responsive() {
      const that = this

      // responsive alpha-index -- when window height is less than 480px show smaller alpha-index
      if (that.$scroll_container.outerHeight() < 480) {
        that._smDeviceAlphaIndex();
      }

      // on viewport resize restyle the alpha-index
      $(window)
        .off(`resize${EVENT_KEY}`)
        .on(`resize${EVENT_KEY}`, function () {
          if (that.$scroll_container.outerHeight() < 480) {
            that._smDeviceAlphaIndex();
          } else {
            that._restoreLgDeviceAlphaIndex();
          }
          that._positionAlphaIndex();
          that._positionIndexNotifier();
        });
    }

    // Static

    static _jQueryInterface(config, param2) {
      // methods with return values
      // if (typeof config === 'string' && config.substring(0, 3) === 'get') {
      //   let data = $(this).data(DATA_KEY)
      //   if (data) {
      //     return data[config]()
      //   }
      // }
      return this.each(function () {
        const $this = $(this)
        const _config = typeof config === 'object' ? config : null
        let data = $this.data(DATA_KEY)

        if (!data) {
          data = new AlphaIndex(this, _config)
          $this.data(DATA_KEY, data)
        }

        if (typeof config === 'string') {
          if (typeof data[config] === 'undefined') {
            throw new TypeError(`No method named "${config}"`)
          }
          if (config === 'setDisabledTab' ||
            config === 'setEnabledTab' ||
            config === 'setActiveTab') {
            data[config](param2)
          } else {
            data[config]()
          }
        }
      })
    }
  }

  /**
   * ------------------------------------------------------------------------
   * jQuery
   * ------------------------------------------------------------------------
   */

  $.fn[NAME] = AlphaIndex._jQueryInterface
  $.fn[NAME].Constructor = AlphaIndex
  $.fn[NAME].noConflict = function () {
    $.fn[NAME] = JQUERY_NO_CONFLICT
    return AlphaIndex._jQueryInterface
  }

  return AlphaIndex
})($)

export default AlphaIndex
