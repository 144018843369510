import $ from 'jquery'
// import TabFocusUtil from './xrx9x-tab-focus-util'

const Radio = (($) => {
  /**
   * ------------------------------------------------------------------------
   * Constants
   * ------------------------------------------------------------------------
   */

  const NAME = 'radio'
  const DATA_KEY = 'xrx.radio'
  const EVENT_KEY = `.${DATA_KEY}`
  const JQUERY_NO_CONFLICT = $.fn[NAME]

  const Event = {
    CLICK: `click${EVENT_KEY}`,
    CHANGE: `change${EVENT_KEY}`,
  }

  const ClassName = {
    CHECKED: 'checked',
    XRX_RADIO: 'xrx-radio',
    RADIO_SM: 'radio-sm',
  }

  const Selector = {
    XRX_RADIO: '.xrx-radio',
    LABEL: 'label',
    TYPE_RADIO: '[type="radio"]',
    SVG: 'svg',
    BODY: 'body',
    DEFS_RADIO: '#defs-radio',
    DEFS_RADIO_SM: '#defs-radio-sm',
  }

  const InputProp = {
    DISABLED: 'disabled',
    CHECKED: 'checked',
  }

  const radioSvgDefaultDef = `
      <svg id="defs-radio" class="svg-defs">
        <defs>
          <symbol id="def-radio-outer-focus-ring">
            <path d="M16,0 C24.836556,0 32,7.163444 32,16 C32,24.836556 24.836556,32 16,32 C7.163444,32 0,24.836556 0,16 C0,7.163444 7.163444,0 16,0 Z M16,7 C11.0294373,7 7,11.0294373 7,16 C7,20.9705627 11.0294373,25 16,25 C20.9705627,25 25,20.9705627 25,16 C25,13.6130516 24.0517884,11.3238664 22.363961,9.63603897 C20.6761336,7.94821156 18.3869484,7 16,7 Z"></path>
          </symbol>
          <symbol id="def-radio-inner-focus-ring">
            <path d="M16,3 C23.1797017,3 29,8.82029825 29,16 C29,23.1797017 23.1797017,29 16,29 C8.82029825,29 3,23.1797017 3,16 C3,8.82029825 8.82029825,3 16,3 Z M16,7 C11.0294373,7 7,11.0294373 7,16 C7,20.9705627 11.0294373,25 16,25 C20.9705627,25 25,20.9705627 25,16 C25,13.6130516 24.0517884,11.3238664 22.363961,9.63603897 C20.6761336,7.94821156 18.3869484,7 16,7 Z"></path>
          </symbol>
          <symbol id="def-radio">
            <path d="M10,0 C4.4771525,0 0,4.4771525 0,10 C0,15.5228475 4.4771525,20 10,20 C15.5228475,20 20,15.5228475 20,10 C20,7.3478351 18.9464316,4.80429597 17.0710678,2.92893219 C15.195704,1.0535684 12.6521649,0 10,0 Z M10,18.5 C5.30557963,18.5 1.5,14.6944204 1.5,10 C1.5,5.30557963 5.30557963,1.5 10,1.5 C14.6944204,1.5 18.5,5.30557963 18.5,10 C18.5,12.2543402 17.6044669,14.4163484 16.0104076,16.0104076 C14.4163484,17.6044669 12.2543402,18.5 10,18.5 Z" id="Path"></path>
          </symbol>
          <symbol id="def-radio-background">
            <path d="M10,0 C4.4771525,0 0,4.4771525 0,10 C0,15.5228475 4.4771525,20 10,20 C15.5228475,20 20,15.5228475 20,10 C20,7.3478351 18.9464316,4.80429597 17.0710678,2.92893219 C15.195704,1.0535684 12.6521649,0 10,0 Z" id="Path"></path>
          </symbol>
          <symbol id="def-radio-checkmark">
            <circle id="Path" cx="5" cy="5" r="4.5"></circle>
          </symbol>
        </defs>
      </svg>`

  const radioSvgDefault = `
      <svg width="48px" height="48px" viewBox="0 0 48 48">
        <g class="svg-checkbox-focus" transform="translate(8.000000, 8.000000)">
          <use class="svg-checkbox-outer-focus-ring" xlink:href="#def-radio-outer-focus-ring"></use>
          <use xlink:href="#def-radio-inner-focus-ring"></use>
        </g>
        <use class="svg-checkbox" transform="translate(14.000000, 14.000000)" xlink:href="#def-radio"></use>
        <use class="svg-checkbox-background" transform="translate(14.000000, 14.000000)" xlink:href="#def-radio-background"></use>
        <use class="svg-checkbox-checkmark" transform="translate(19.000000, 19.000000)" xlink:href="#def-radio-checkmark"></use>
      </svg>`

  const radioSvgSmallDef = `
      <svg id="defs-radio-sm" class="svg-defs">
        <defs>
          <symbol id="def-radio-outer-focus-ring-sm">
            <path d="M14.5,0.165 C18.3018784,0.165 21.9480417,1.67529031 24.6363757,4.36362429 C27.3247097,7.05195828 28.835,10.6981216 28.835,14.5 C28.835,22.4170019 22.4170019,28.835 14.5,28.835 C6.58299811,28.835 0.165,22.4170019 0.165,14.5 C0.165,6.58299811 6.58299811,0.165 14.5,0.165 Z M14.5,7 C10.3578644,7 7,10.3578644 7,14.5 C7,18.6421356 10.3578644,22 14.5,22 C18.6421356,22 22,18.6421356 22,14.5 C22,12.5108763 21.2098237,10.603222 19.8033009,9.19669914 C18.396778,7.7901763 16.4891237,7 14.5,7 Z"></path>
          </symbol>
          <symbol id="def-radio-inner-focus-ring-sm">
            <path d="M14.5,3.165 C17.5062289,3.165 20.3893305,4.35921979 22.5150554,6.48494464 C24.6407802,8.61066948 25.835,11.4937711 25.835,14.5 C25.835,20.7601476 20.7601476,25.835 14.5,25.835 C8.23985236,25.835 3.165,20.7601476 3.165,14.5 C3.165,8.23985236 8.23985236,3.165 14.5,3.165 Z M14.5,7 C10.3578644,7 7,10.3578644 7,14.5 C7,18.6421356 10.3578644,22 14.5,22 C18.6421356,22 22,18.6421356 22,14.5 C22,12.5108763 21.2098237,10.603222 19.8033009,9.19669914 C18.396778,7.7901763 16.4891237,7 14.5,7 Z"></path>
          </symbol>
          <symbol id="def-radio-sm">
            <path d="M8.49999998,0.16666665 C3.89762707,0.16666665 0.16666665,3.89762707 0.16666665,8.49999998 C0.16666665,13.1023729 3.89762707,16.8333334 8.49999998,16.8333334 C13.1023729,16.8333334 16.8333334,13.1023729 16.8333334,8.49999998 C16.8333334,6.28986257 15.9553597,4.17024663 14.3925564,2.60744347 C12.8297533,1.04464032 10.7101373,0.16666665 8.49999998,0.16666665 Z M8.49999998,15.5833334 C4.58798301,15.5833334 1.41666665,12.4120169 1.41666665,8.49999998 C1.41666665,4.58798301 4.58798301,1.41666665 8.49999998,1.41666665 C12.4120169,1.41666665 15.5833333,4.58798301 15.5833333,8.49999998 C15.5833333,10.3786167 14.8370556,12.1802903 13.508673,13.5086731 C12.1802903,14.8370556 10.3786167,15.5833334 8.49999998,15.5833334 Z"></path>
          </symbol>
          <symbol id="def-radio-background-sm">
            <path d="M8.49999998,0.16666665 C3.89762707,0.16666665 0.16666665,3.89762707 0.16666665,8.49999998 C0.16666665,13.1023729 3.89762707,16.8333334 8.49999998,16.8333334 C13.1023729,16.8333334 16.8333334,13.1023729 16.8333334,8.49999998 C16.8333334,6.28986257 15.9553597,4.17024663 14.3925564,2.60744347 C12.8297534,1.04464032 10.7101374,0.16666665 8.49999998,0.16666665 Z"></path>
          </symbol>
          <symbol id="def-radio-checkmark-sm">
            <circle id="Path" cx="4" cy="4" r="3.75"></circle>
          </symbol>
        </defs>
      </svg>`

  const radioSvgSmall = `
      <svg width="32px" height="32px" viewBox="0 0 32 32">
        <g class="svg-checkbox-focus" transform="translate(1.500000, 1.500000)">
          <use class="svg-checkbox-outer-focus-ring" xlink:href="#def-radio-outer-focus-ring-sm"></use>
          <use xlink:href="#def-radio-inner-focus-ring-sm"></use>
        </g>
        <use class="svg-checkbox" transform="translate(7.500000, 7.500000)" xlink:href="#def-radio-sm"></use>
        <use class="svg-checkbox-background" transform="translate(7.500000, 7.500000)" xlink:href="#def-radio-background-sm"></use>
        <use class="svg-checkbox-checkmark" transform="translate(12.000000, 12.000000)" xlink:href="#def-radio-checkmark-sm"></use>
      </svg>`

  /**
   * ------------------------------------------------------------------------
   * Class Definition
   * ------------------------------------------------------------------------
   */

  class Radio {
    constructor(element, config) {
      this._element = element
      this._radio = $(element).find(Selector.TYPE_RADIO)[0]
      this._radioGroupName = $(this._radio).attr('name')
      this._createRadio()
      this._svg = $(element).find(Selector.SVG)[0]

      this._addEventListeners()
      this._initState()
    }

    // Public

    setChecked() {
      this.setEnabled()
      $(this._radio).prop(InputProp.CHECKED, true)
      this._setViewChecked()
    }

    setCheckedChange() {
      this.setChecked()
      $(this._radio).change()
    }

    setDisabled() {
      $(this._radio).prop(InputProp.DISABLED, true)
    }

    setEnabled() {
      $(this._radio).prop(InputProp.DISABLED, false)
    }

    getDisabled() {
      return $(this._radio).prop(InputProp.DISABLED)
    }

    getChecked() {
      return $(this._radio).prop(InputProp.CHECKED)
    }

    dispose() {
      $.removeData(this._element, DATA_KEY)
      $(this._element).off(EVENT_KEY)
      $(this._radio).off(EVENT_KEY)
      $(this._element).find(Selector.LABEL).off(EVENT_KEY)
      $(this._svg).remove()
      this._element = null
      this._radio = null
      this._svg = null
      this._radioGroupName = null
    }

    // Private

    _createRadio() {
      const $xrxRadio = $(this._element)
      if ($xrxRadio.hasClass(ClassName.RADIO_SM)) {
        if ($(Selector.DEFS_RADIO_SM).length === 0) {
          $(Selector.BODY).prepend(radioSvgSmallDef)
        }
        $xrxRadio.append(radioSvgSmall)
      } else {
        if ($(Selector.DEFS_RADIO).length === 0) {
          $(Selector.BODY).prepend(radioSvgDefaultDef)
        }
        $xrxRadio.append(radioSvgDefault)
      }
    }

    _initState() {
      if (this.getChecked() === true) {
        this._setViewChecked()
      }
    }

    _addEventListeners() {
      const that = this

      $(that._element).on(Event.CLICK, function () {
        $(this).find(Selector.TYPE_RADIO).click().focus()
      })

      $(that._element).find(Selector.LABEL).on(Event.CLICK, function (e) {
        e.stopPropagation()
        $(this).closest(that._element).find(Selector.TYPE_RADIO).focus()
      })

      $(that._radio).on(Event.CLICK, function (e) {
        e.stopPropagation()
      })

      const $radioGroup = $(`[name="${that._radioGroupName}"]`)

      $radioGroup
        .off(Event.CHANGE)
        .on(Event.CHANGE, function (e) {
          $radioGroup
            .closest(Selector.XRX_RADIO)
            .removeClass(ClassName.CHECKED)
          $(this).closest(Selector.XRX_RADIO).addClass(ClassName.CHECKED)
        })
    }

    _setViewChecked() {
      $(`[name="${this._radioGroupName}"]`)
        .closest(Selector.XRX_RADIO)
        .removeClass(ClassName.CHECKED)
      $(this._element).addClass(ClassName.CHECKED)
    }

    // Static

    static _jQueryInterface(config) {
      // methods with return values
      if (typeof config === 'string' && config.substring(0, 3) === 'get') {
        let data = $(this).data(DATA_KEY)
        if (data) {
          return data[config]()
        }
      }
      return this.each(function () {
        let data = $(this).data(DATA_KEY)
        const _config = typeof config === 'object' ? config : null

        if (!data) {
          data = new Radio(this, _config)
          $(this).data(DATA_KEY, data)
        }

        if (typeof config === 'string') {
          if (typeof data[config] === 'undefined') {
            throw new TypeError(`No method named "${config}"`)
          }
          data[config]()
        }
      })
    }

  }

  /**
   * ------------------------------------------------------------------------
   * jQuery
   * ------------------------------------------------------------------------
   */

  $.fn[NAME] = Radio._jQueryInterface
  $.fn[NAME].Constructor = Radio
  $.fn[NAME].noConflict = function () {
    $.fn[NAME] = JQUERY_NO_CONFLICT
    return Radio._jQueryInterface
  }

  return Radio
})($)

export default Radio
