import $ from 'jquery'
import Util from './xrx-util'
// import TabFocusUtil from './xrx9x-tab-focus-util'

/**
 * --------------------------------------------------------------------------
 * Bootstrap (v4.1.1): tab.js
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 * --------------------------------------------------------------------------
 */

const Tab = (($) => {
  /**
   * ------------------------------------------------------------------------
   * Constants
   * ------------------------------------------------------------------------
   */

  const NAME = 'tab'
  const VERSION = '4.1.1'
  const DATA_KEY = 'xrx.tab'
  const EVENT_KEY = `.${DATA_KEY}`
  const DATA_API_KEY = '.data-api'
  const JQUERY_NO_CONFLICT = $.fn[NAME]

  const Event = {
    SHOW: `show${EVENT_KEY}`,
    CLICK: `click${EVENT_KEY}`,
  }

  const ClassName = {
    ACTIVE: 'active',
    DISABLED: 'disabled',
    SHOW: 'show'
  }

  const Selector = {
    NAV: '.xrx-nav',
    NAV_LINK: '.nav-link',
    NAV_TABS: '.nav-tabs',
    ACTIVE: '.active',
    LEFT_ICON: '.left-icon',
    DROPDOWN_TOGGLE: '.dropdown-toggle'
  }

  const Default = {
    breakpoint: 768
  }

  /**
   * ------------------------------------------------------------------------
   * Class Definition
   * ------------------------------------------------------------------------
   */

  class Tab {
    constructor(element, config) {
      this._element = element
      this._navTabsId = $(element).attr('id')
      this._tabWidth = null
      this._xrxSelect = null
      this._select = null
      this._config = this._getConfig(config)
      this._mobile()
      this._addEventListeners()
      this._setEqualLayout()
      this._initActiveTab()
      this._slideIndicator()
      this._swapGlyphIconClass()
    }

    // Getters

    // static get VERSION() {
    //   return VERSION
    // }

    // Public

    setDisabledTab(tabId) {
      $(`#${tabId}`).addClass('disabled').attr('tabindex', '-1')
      const index = $(this._element).find(Selector.NAV_LINK).index($(`#${tabId}`))
      $(this._xrxSelect).select('setOptionDisabled', index.toString())
    }

    setEnabledTab(tabId) {
      $(`#${tabId}`).removeClass('disabled').removeAttr('tabindex')
      const index = $(this._element).find(Selector.NAV_LINK).index($(`#${tabId}`))
      $(this._xrxSelect).select('setOptionEnabled', index.toString())
    }

    setActiveTab(tabId) {
      this.setEnabledTab(tabId)
      this._show(tabId)
    }

    getDisabledTab() {
      return $(this._element).find('.disabled')
    }

    getActiveTab() {
      return $(this._element).find('.active')
    }

    dispose() {
      $(window).off(`resize.nav-tab-initSlideIndicator.${this._navTabsId}`)
      $(window).off(`resize.nav-tab-mobile.${this._navTabsId}`)
      $.removeData(this._element, DATA_KEY)
      $(this._element).off(EVENT_KEY)
      $(this._element).find('.sliding-indicator').remove()
      $(this._select).off('change.nav-tab-mobile')
      $(this._xrxSelect).select('dispose').remove()
      this._element = null
      this._navTabsId = null
      this._tabWidth = null
      this._xrxSelect = null
      this._select = null
      this._config = null
    }

    // Private

    _getConfig(config) {
      config = {
        ...Default,
        ...config
      }
      return config
    }

    _initActiveTab() { // set first tab default on init
      const $firstTab = $(this._element).find(Selector.NAV_LINK).eq(0)
      this.setActiveTab($firstTab.attr('id'))
      $(this._element).addClass('init')
    }

    _addEventListeners() {
      const that = this
      $(this._element).on(Event.CLICK, Selector.NAV_LINK, function (e) {
        const $this = $(this)
        e.preventDefault()
        that._show($this.attr('id'))
      })
    }

    _show(tabId) {
      const $previous = $(this._element).find('.active')
      const $thisTab = $(`#${tabId}`)
      if ($thisTab.hasClass('active') ||
        $thisTab.hasClass('disabled')) {
        return
      }

      // console.log($thisTab[0])
      // console.log($previous[0])

      // events
      const showEvent = $.Event(Event.SHOW, {
        target: $thisTab[0],
        relatedTarget: $previous[0]
      })

      $(this._element).trigger(showEvent)

      if (showEvent.isDefaultPrevented()) {
        return
      }

      // change tab
      $previous.removeClass('active').attr('aria-selected', 'false')
      $thisTab.addClass('active').attr('aria-selected', 'true')
      this._slide($thisTab)

      // change mobile select
      const index = $(this._element).find(Selector.NAV_LINK).index($thisTab)
      $(this._xrxSelect).select('setSelected', index.toString())

      // change tab content
      const tabContentId = $thisTab.attr('aria-controls')
      const $thisTabContent = $(`#${tabContentId}`)
      const $tabContent = $thisTabContent.closest('.tab-content')
      $tabContent.find('.active').removeClass('active')
      $thisTabContent.addClass('active')
    }

    _setEqualLayout() {
      const $navTabs = $(this._element)
      const $navLinks = $navTabs.find(Selector.NAV_LINK)
      let temp = 0
      if ($navTabs.hasClass('nav-justified')) {
        return
      }
      $navLinks.css('width', 'auto')
      $navLinks.each(function () {
        const linkWidth = $(this).outerWidth()
        if (Number(linkWidth) > temp) {
          temp = Number(linkWidth)
        }
      })
      this._tabWidth = temp
      $navLinks.css('width', temp)
    }

    _slideIndicator() {
      const that = this
      const ms = 200
      const $navTabs = $(this._element)

      // on document load
      if ($navTabs.find('.sliding-indicator').length === 0) {
        $navTabs.append('<li class="sliding-indicator" aria-hidden="true"></li>')
      }
      $navTabs.find('.sliding-indicator').css({
        width: this._tabWidth
      })
      this._slide($navTabs.find(Selector.ACTIVE))
      setTimeout(() => {
        $navTabs.find('.sliding-indicator').addClass('animate')
      }, ms)

      // on window resize
      if ($navTabs.hasClass('nav-justified')) {
        // const $navJustified = $navTabs.find('.nav-justified')
        const $slidingIndicator = $navTabs.find('.sliding-indicator')
        $(window).on(`resize.nav-tab-initSlideIndicator.${this._navTabsId}`, () => {
          $slidingIndicator.removeClass('animate')
          that._slide($navTabs.find(Selector.ACTIVE)[0])
          setTimeout(() => {
            $slidingIndicator.addClass('animate')
          }, ms)
        })
      }
    }

    _slide(target) {
      const $target = $(target)

      $(this._element).find('.sliding-indicator').css({
        left: $target.position().left,
        width: $target.outerWidth()
      })
    }

    _buildMobileSelect() {
      const $navTabs = $(this._element)
      const navTabsId = this._navTabsId
      let html = ''

      html += `<div class="xrx-select xrx-nav-tab-select" style="width: 200px;">`
      html += `<label for="${navTabsId}-select" class="sr-only">${navTabsId}</label>`
      html += `<select name="${navTabsId}" id="${navTabsId}-select">`

      $navTabs.find(Selector.NAV_LINK).each(function () {
        const $this = $(this)
        const tabId = $this.attr('id')
        const tabLabel = $this.find('.nav-link-label').text()
        let iconClass
        let iconClassesArr = []
        if ($this.find('.left-icon').length > 0) {
          iconClassesArr = $this.find('.left-icon').attr('class').split(' ')
          if (iconClassesArr.length) {
            for (let i = 0; i < iconClassesArr.length; i++) {
              if (iconClassesArr[i].includes('xgl-')) {
                iconClass = iconClassesArr[i]
              }
            }
          }
        }
        const selected = ($this.hasClass('active')) ? 'selected' : ''
        iconClass = (iconClass) ? iconClass.slice(4) : ''
        html += `<option data-icon="${iconClass}" value="${tabId}" ${selected}>${tabLabel}</option>`
      })

      html += `</select>`
      html += `</div>`

      const $xrxSelect = $(html)
      $(`#${navTabsId}`).after($xrxSelect)
      $xrxSelect.select()
      this._xrxSelect = $xrxSelect[0]
      this._select = $xrxSelect.find('select')[0]
    }

    _mobile() {
      const that = this
      this._buildMobileSelect()
      $(this._select).on('change.nav-tab-mobile', function () {
        that._show($(this).val())
      })

      function swapTabsAndSelect() {
        console.log($(that._element).find(Selector.NAV_LINK))
        if (matchMedia('(min-width: ' + that._config.breakpoint + 'px)').matches) {
          $(that._xrxSelect).addClass('hide')
          $(that._xrxSelect).find(Selector.DROPDOWN_TOGGLE).prop('disabled', true)
          $(that._element).removeClass('hide')
          $(that._element).find(Selector.NAV_LINK).attr('tabindex', '0')
        } else {
          $(that._element).addClass('hide')
          $(that._xrxSelect).find(Selector.DROPDOWN_TOGGLE).prop('disabled', false)
          $(that._xrxSelect).removeClass('hide')
          $(that._element).find(Selector.NAV_LINK).attr('tabindex', '-1')
        }
      }

      // on document load
      swapTabsAndSelect()

      // on resize
      $(window)
        .on(`resize.nav-tab-mobile.${this._navTabsId}`, function () {
          // .on('resize.nav-tab-mobile', function () {
          swapTabsAndSelect()
        })
    }

    _getGlyphIconClass(classList) {
      let targetGlyphIconClass = ''
      classList.forEach((thisClass) => {
        if (thisClass.includes('xgl-')) {
          targetGlyphIconClass = thisClass
        }
      })
      return targetGlyphIconClass
    }

    _swapGlyphIconClass() {
      const that = this
      const $navTabs = $(this._element)

      function swap(target, relatedTarget) {
        const $targetIcon = $(target).find(Selector.LEFT_ICON)
        const $relatedTargetIcon = $(relatedTarget).find(Selector.LEFT_ICON)

        if ($targetIcon.length > 0) {
          const targetGlyphIconClass = that._getGlyphIconClass($targetIcon[0].classList)
          const newTargetGlyphIconClass = targetGlyphIconClass.replace('_two_tone', '_alt')
          $targetIcon.removeClass(targetGlyphIconClass).addClass(newTargetGlyphIconClass)
        }

        if ($relatedTargetIcon.length > 0) {
          const relatedTargetGlyphIconClass = that._getGlyphIconClass($relatedTargetIcon[0].classList)
          const newRelatedTargetGlyphIconClass = relatedTargetGlyphIconClass.replace('_alt', '_two_tone')
          $relatedTargetIcon.removeClass(relatedTargetGlyphIconClass).addClass(newRelatedTargetGlyphIconClass)
        }
      }

      // on document load
      swap($navTabs.find(Selector.ACTIVE))
      // on tab show
      $navTabs.on(Event.SHOW, Selector.NAV_LINK, (e) => {
        swap(e.target, e.relatedTarget)
      })
    }

    // Static

    static _jQueryInterface(config, param2) {
      // methods with return values
      if (typeof config === 'string' && config.substring(0, 3) === 'get') {
        let data = $(this).data(DATA_KEY)
        if (data) {
          return data[config]()
        }
      }
      return this.each(function () {
        const $this = $(this)
        const _config = typeof config === 'object' ? config : null
        let data = $this.data(DATA_KEY)

        if (!data) {
          data = new Tab(this, _config)
          $this.data(DATA_KEY, data)
        }

        if (typeof config === 'string') {
          if (typeof data[config] === 'undefined') {
            throw new TypeError(`No method named "${config}"`)
          }
          if (config === 'setDisabledTab' ||
            config === 'setEnabledTab' ||
            config === 'setActiveTab') {
            data[config](param2)
          } else {
            data[config]()
          }
        }
      })
    }
  }

  /**
   * ------------------------------------------------------------------------
   * jQuery
   * ------------------------------------------------------------------------
   */

  $.fn[NAME] = Tab._jQueryInterface
  $.fn[NAME].Constructor = Tab
  $.fn[NAME].noConflict = function () {
    $.fn[NAME] = JQUERY_NO_CONFLICT
    return Tab._jQueryInterface
  }

  return Tab
})($)

export default Tab
