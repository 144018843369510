import $ from 'jquery'
import Util from './xrx-util'

/**
 * --------------------------------------------------------------------------
 * Bootstrap (v4.1.1)
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 * --------------------------------------------------------------------------
 */

const Input = (($) => {
  /**
   * ------------------------------------------------------------------------
   * Constants
   * ------------------------------------------------------------------------
   */

  const NAME = 'input'
  // const VERSION = '4.1.1'
  const DATA_KEY = 'xrx.input'
  const EVENT_KEY = `.${DATA_KEY}`
  const DATA_API_KEY = '.data-api'
  const JQUERY_NO_CONFLICT = $.fn[NAME]

  // const Default = {
  //   backdrop: true,
  //   keyboard: true,
  //   focus: true,
  //   show: true,
  //   fixed: true
  // }

  // const DefaultType = {
  //   backdrop: '(boolean|string)',
  //   keyboard: 'boolean',
  //   focus: 'boolean',
  //   show: 'boolean',
  //   fixed: 'boolean'
  // }

  const Event = {
    FOCUS: `focus${EVENT_KEY}`,
    BLUR: `blur${EVENT_KEY}`,
    CLICK: `click${EVENT_KEY}`,
    MOUSEENTER: `mouseenter${EVENT_KEY}`,
    MOUSELEAVE: `mouseleave${EVENT_KEY}`,
    MOUSEDOWN: `mousedown${EVENT_KEY}`,
    MOUSEUP: `mouseup${EVENT_KEY}`
  }

  const ClassName = {
    INPUT_FOCUS: 'input-focus',
    INPUT_HOVER: 'input-hover',
    INPUT_ACTIVE: 'input-active',
    VALIDATION_CLASSES: 'input-validation-success input-validation-danger input-validation-warning input-helper-validation',
    INPUT_VALIDATION_SUCCESS: 'input-validation-success',
    INPUT_VALIDATION_DANGER: 'input-validation-danger',
    INPUT_VALIDATION_WARNING: 'input-validation-warning'
  }

  const Selector = {
    XRX_INPUT: '.xrx-input',
    INPUT: '.xrx-input input',
    XRX_STACKED_ICON: '.xrx-stacked-icon',
    INPUT_GROUP: '.input-group',
    INPUT_GROUP_APPEND: '.input-group-append',
    INPUT_GROUP_DIVIDER: '.input-group-divider'
  }

  const Str = {
    FOCUS_ON: 'focus-on',
    FOCUS_OFF: 'focus-off',
    HOVER_ON: 'hover-on',
    HOVER_OFF: 'hover-off',
    ACTIVE_ON: 'active-on',
    SUCCESS: 'success',
    DANGER: 'danger',
    WARNING: 'warning'
  }

  const Html = {

    helperText: `
      <div class="form-text">
        <div class="message"></div>
      </div>`,

    successIcon: `
      <div class="xrx-stacked-icon stacked-icon-size-24 success_2stack">
        <i class="xgl-success_stack_mdgd xglsize-24"></i>
        <i class="xgl-success_stack_frgd xglsize-24"></i>
      </div>`,

    dangerIcon: `
      <div class="xrx-stacked-icon stacked-icon-size-24 danger_2stack">
        <i class="xgl-danger_stack_mdgd xglsize-24"></i>
        <i class="xgl-danger_stack_frgd xglsize-24"></i>
      </div>`,

    warningIcon: `
      <div class="xrx-stacked-icon stacked-icon-size-24 warning_2stack">
        <i class="xgl-warning_stack_mdgd xglsize-24"></i>
        <i class="xgl-warning_stack_frgd xglsize-24"></i>
      </div>`,

    inputGroupAppend: `
      <div class="input-group-append">
      </div>`,

    inputGroupDivider: `
      <div class="input-group-divider">
      </div>`

  }

  /**
   * ------------------------------------------------------------------------
   * Class Definition
   * ------------------------------------------------------------------------
   */

  class Input {
    constructor(element, config) {
      this._config = this._getConfig(config)
      this._$element = $(element)
      this._$htmlInput = this._$element.find('input')
      this._init()
    }

    // Getters

    // static get VERSION() {
    //   return VERSION
    // }

    // static get Default() {
    //   return Default
    // }

    // Public

    dispose() {
      // $.removeData(this._$element, DATA_KEY)

      // $(window, document, this._$element, this._backdrop).off(EVENT_KEY)

      // this._config = null
      // this._$element = null
      // this._dialog = null
      // this._backdrop = null
      // this._isShown = null
      // this._isBodyOverflowing = null
      // this._ignoreBackdropClick = null
      // this._scrollbarWidth = null
    }

    setHelperValidationSuccess(message) {
      const that = this
      this.setValidationClear()

      // if message is an object, then do multiline validation messages
      that._helperValidation(Str.SUCCESS)
      if (Array.isArray(message)) {
        that._helperMultilineValidation(message)
      } else {
        that._helperValidationMessage(Str.SUCCESS, message)
      }
    }

    setHelperValidationDanger(message) {
      const that = this
      this.setValidationClear()

      // if message is an object, then do multiline validation messages
      that._helperValidation(Str.DANGER)
      if (Array.isArray(message)) {
        that._helperMultilineValidation(message)
      } else {
        that._helperValidationMessage(Str.DANGER, message)
      }
    }

    setHelperValidationWarning(message) {
      const that = this
      this.setValidationClear()

      // if message is an object, then do multiline validation messages
      that._helperValidation(Str.WARNING)
      if (Array.isArray(message)) {
        that._helperMultilineValidation(message)
      } else {
        that._helperValidationMessage(Str.WARNING, message)
      }
    }

    setInlineValidationSuccess() {
      this.setValidationClear()
      this._addInputGroupDividerHtml()
      this._$element
        .addClass(ClassName.INPUT_VALIDATION_SUCCESS)
        .find(Selector.INPUT_GROUP_APPEND)
        .append(Html.successIcon)
    }

    setInlineValidationDanger() {
      this.setValidationClear()
      this._addInputGroupDividerHtml()
      this._$element
        .addClass(ClassName.INPUT_VALIDATION_DANGER)
        .find(Selector.INPUT_GROUP_APPEND)
        .append(Html.dangerIcon)
    }

    setInlineValidationWarning() {
      this.setValidationClear()
      this._addInputGroupDividerHtml()
      this._$element
        .addClass(ClassName.INPUT_VALIDATION_WARNING)
        .find(Selector.INPUT_GROUP_APPEND)
        .append(Html.warningIcon)
    }

    setValidationClear() {
      this._$element
        .removeClass(ClassName.VALIDATION_CLASSES)

        // remove helper text validation
        .next('.form-text').remove()

      // remove inline validation
      this._$element
        .find(Selector.XRX_STACKED_ICON)
        .remove()
        .end()
        .find(Selector.INPUT_GROUP_DIVIDER)
        .remove()
    }

    setDisabled() {
      const that = this
      that._$htmlInput.prop('disabled', true)
      that._$element.addClass('input-disabled')

      // Disable the input label
      const id = that._$htmlInput.attr('id')
      $(`[for="${id}"]`).addClass('input-label-disabled')
    }

    setReadonly() {
      const that = this
      that._$htmlInput.prop('readonly', true)
      that._$element.addClass('input-readonly')
    }

    setEnabled() { // remove disabled and readonly attributes
      const that = this
      that._$htmlInput.prop('disabled', false)
      that._$htmlInput.prop('readonly', false)
      that._$element
        .removeClass('input-disabled')
        .removeClass('input-readonly')

      // enable the input label
      const id = that._$htmlInput.attr('id')
      $(`[for="${id}"]`).removeClass('input-label-disabled')
    }

    // Private

    _init() {
      const that = this

      if (that._$htmlInput.attr('disabled') ||
        that._$element.hasClass('input-disabled')) {
        that.setDisabled()
      }

      if (that._$htmlInput.attr('readonly') ||
        that._$element.hasClass('input-readonly')) {
        that.setReadonly()
      }

      that._addInputGroupAppendHtml()

      that._addEventListeners()
    }

    _addInputGroupAppendHtml() {
      const that = this
      if (that._$element.find(Selector.INPUT_GROUP_APPEND).length === 0) {
        that._$element.find(Selector.INPUT_GROUP).append(Html.inputGroupAppend)
      }
    }

    _addInputGroupDividerHtml() {
      const that = this
      const $inputGroupAppend = that._$element.find(Selector.INPUT_GROUP_APPEND)
      if ($inputGroupAppend.children().length > 0) {
        $inputGroupAppend.append(Html.inputGroupDivider)
      }
    }

    _addEventListeners() {
      const that = this

      that._$htmlInput
        .on(Event.FOCUS, function () {
          that._focus(Str.FOCUS_ON)
        })
        .on(Event.BLUR, function () {
          that._focus(Str.FOCUS_OFF)
        })

      that._$htmlInput
        .on(Event.MOUSEENTER, function () {
          that._hover(Str.HOVER_ON)
        })
        .on(Event.MOUSELEAVE, function () {
          that._hover(Str.HOVER_OFF)
        })

      that._$htmlInput.on(Event.MOUSEDOWN, function () {
        that._active(Str.ACTIVE_ON)
      })
      $(document).on(Event.MOUSEUP, function () {
        that._active(Str.ACTIVE_OFF)
      })

    }

    _focus(param) {
      const that = this
      if (param === Str.FOCUS_ON) {
        that._$element.addClass(ClassName.INPUT_FOCUS)
      } else {
        that._$element.removeClass(ClassName.INPUT_FOCUS)
      }
    }

    _hover(param) {
      const that = this
      if (param === Str.HOVER_ON) {
        that._$element.addClass(ClassName.INPUT_HOVER)
      } else {
        that._$element.removeClass(ClassName.INPUT_HOVER)
      }
    }

    _active(param) {
      const that = this
      if (param === Str.ACTIVE_ON) {
        that._$element.addClass(ClassName.INPUT_ACTIVE)
      } else {
        that._$element.removeClass(ClassName.INPUT_ACTIVE)
      }
    }

    _helperMultilineValidation(messageArray) {
      const that = this
      let html = ''

      const $formText = that._$element.next('.form-text')
      $formText.addClass('multiline')

      messageArray.forEach(function (obj) {

        html += '<div class="message-container">'

        if (obj.type === Str.SUCCESS) {
          html += `${Html.successIcon}<div class="success-message message">${obj.message}</div>`
        } else if (obj.type === Str.DANGER) {
          html += `${Html.dangerIcon}<div class="danger-message message">${obj.message}</div>`
        } else if (obj.type === Str.WARNING) {
          html += `${Html.warningIcon}<div class="warning-message message">${obj.message}</div>`
        }

        html += '</div>'
      })

      $formText.append(html)

    }

    _helperValidation(type) {
      if (type === Str.SUCCESS) {
        this._$element.addClass(ClassName.INPUT_VALIDATION_SUCCESS)
      } else if (type === Str.DANGER) {
        this._$element.addClass(ClassName.INPUT_VALIDATION_DANGER)
      } else if (type === Str.WARNING) {
        this._$element.addClass(ClassName.INPUT_VALIDATION_WARNING)
      }
      this._$element
        .addClass('input-helper-validation')
        .after(Html.helperText)
    }

    _helperValidationMessage(type, message) {
      const $formText = this._$element.next('.form-text')

      if (type === Str.SUCCESS) {
        $formText.prepend(Html.successIcon)
      } else if (type === Str.DANGER) {
        $formText.prepend(Html.dangerIcon)
      } else if (type === Str.WARNING) {
        $formText.prepend(Html.warningIcon)
      }

      $formText.find('.message').append(message)
    }

    _getConfig(config) {
      config = {
        // ...Default,
        ...config
      }
      // Util.typeCheckConfig(NAME, config, DefaultType)
      return config
    }

    // Static

    static _jQueryInterface(config, param) {
      return this.each(function () {
        let data = $(this).data(DATA_KEY)
        const _config = {
          // ...Default,
          ...$(this).data(),
          ...typeof config === 'object' && config ? config : {}
        }

        if (!data) {
          data = new Input(this, _config)
          $(this).data(DATA_KEY, data)
        }

        if (typeof config === 'string') {
          if (typeof data[config] === 'undefined') {
            throw new TypeError(`No method named "${config}"`)
          }
          data[config]()
          if (typeof param === 'string' || Array.isArray(param)) {
            data[config](param)
          }
        }
      })
    }
  }

  /**
   * ------------------------------------------------------------------------
   * Data Api implementation
   * ------------------------------------------------------------------------
   */

  // $(document).on(Event.MOUSEENTER, Selector.INPUT, function (event) {

  //   const $target = $(this).closest(Selector.XRX_INPUT)

  //   if (!$(this).data(DATA_KEY)) {
  //     $target.addClass(ClassName.INPUT_HOVER)
  //   }

  //   Input._jQueryInterface.call($target)

  // })

  // $(document).on(`${Event.CLICK} ${Event.FOCUS}`, Selector.INPUT, function (event) {

  //   const $target = $(this).closest(Selector.XRX_INPUT)

  //   if (!$(this).data(DATA_KEY)) {
  //     $target.addClass(ClassName.INPUT_FOCUS)
  //   }

  //   // Input._jQueryInterface.call($(target), config, this)
  //   Input._jQueryInterface.call($target)
  // })

  // on document load
  $('.xrx-input').each(function () {
    Input._jQueryInterface.call($(this))
  })

  /**
   * ------------------------------------------------------------------------
   * jQuery
   * ------------------------------------------------------------------------
   */

  $.fn[NAME] = Input._jQueryInterface
  $.fn[NAME].Constructor = Input
  $.fn[NAME].noConflict = function () {
    $.fn[NAME] = JQUERY_NO_CONFLICT
    return Input._jQueryInterface
  }

  return Input
})($)

export default Input
